import { useMotionValue } from "framer-motion"

import { Provider } from "contexts/QuickViews"
import { useLocale } from "contexts/Locale"
import { useEnv } from "contexts/Env"
import { useUser } from "contexts/User"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { getPriceDatas } from "components/price/utils"

export const QuickViewsProvider = ({ children }) => {
  const locale = useLocale()
  const env = useEnv()
  const { countryCode } = useUser()

  const qvContent = useMotionValue("")

  async function track({ event, index, product, techCode, step }) {
    const { price, currency } = await getPriceDatas({ rmc: product.productID, countryCode, locale, env })

    waTriggerEvent({
      eventType: event,
      eventName: ["storyViews"],
      configurator: {
        step: step,
      },
      storyInfo: {
        slideName: techCode,
        slidePosition: index + 1,
      },
      products: [{ ...product, productPrice: price, currency }],
    })
  }

  return <Provider value={{ qvContent, track }}>{children}</Provider>
}
