import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { surtitle45, legend110, bold } from "css/text"

export const A = styled.a`
  display: block;
  position: relative;
  width: 100%;
  text-decoration: none;

  & * {
    pointer-events: none;
  }

  &.current {
    @media (hover: hover) and (pointer: fine) {
      cursor: default;
    }
    & figcaption {
      color: rgb(var(--light-green));
    }
  }

  @media (hover: hover) and (pointer: fine) {
    & img {
      transform: scale(1) translate3d(0, 0, 0);
      transition: transform 0.4s ease-out;
    }
    &:hover:not(.current) img {
      transform: scale(1.05) translate3d(0, 0, 0);
    }
  }
`

export const Selected = styled.span``

export const Figure = styled(motion.figure)`
  display: grid;
  grid-template-rows: [img] auto [capt] auto;
  width: 100%;
`

export const ImgContainer = styled.div`
  overflow: hidden;
  grid-row: img;
  grid-column: 1 / 1;
  & > img {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const Caption = styled.figcaption`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  color: white;

  width: calc(100% - 20px);

  margin-block-start: 15px;

  & > span.title {
    ${legend110}
    ${bold}
    grid-row-start: 2;
  }
  & > span.kicker,
  & > span.subtitle {
    ${surtitle45};
    grid-row-start: 1;
  }
`
