export const media = {
  portrait: "(max-aspect-ratio: 1/1)",
  landscape: "(min-aspect-ratio: 1/1)",
}

export const breakpoint = {
  s: "30rem", // 16px*30=480px
  m: "48rem", // 16px*48=768px
  l: "64.0625rem", // 16px*64.0625=1025px
  xl: "80rem", // 16px*80=1280px
  xxl: "90rem", // 16px*90=1440px
  xxll: "100rem", // 16px*100=1600px
  xxxl: "120rem", // 16px*120=1920px
}

const getMediaQuery = (size, { max = false } = {}) => `@media (${!max ? "min" : "max"}-width: ${breakpoint[size]})`

export const getIntervalMediaQuery = (min, max) => `@media (min-width: ${breakpoint[min]}) and (max-width: ${breakpoint[max]})`

export default getMediaQuery
