import { Suspense } from "react"

import * as components from "components/components"
import * as classnames from "css/classnames"
import * as fallbacks from "fallbacks"
import { useConsole } from "contexts/Console"
import camelCase from "utils/camelCase"

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

function Missing({ meta }) {
  const console = useConsole()
  console.debug("Missing(%o)", { type: meta.type })
  return null
}

function Placeholder({ meta }) {
  const console = useConsole()
  console.verbose("Placeholder(%o)", meta)
  return null
}

export default function generateModularBlocks(blocks, { console }) {
  blocks = Array.isArray(blocks) ? blocks : [blocks].filter(Boolean)

  console.verbose("generateModularBlocks(%o)", { blocks })
  return blocks
    .map((card, i) => {
      const k = Object.keys(card)[0]
      const type = capitalizeFirstLetter(camelCase(k ?? "undefined"))
      const key = card[k]?._metadata?.uid ?? Math.random().toString()
      const children = card.children ?? []
      const Component = components[type] || Missing
      const lazy = Component?.$$typeof === Symbol.for("react.lazy")
      const Fallback = lazy && (fallbacks[card.fallback] || Placeholder)
      const className = classnames[type]
      const meta = { type, lazy, key, Component, Fallback, index: i }
      const { ...props } = card[k]
      console.verbose("generateModularBlocks:component(%s, %s, %o)", key, type, { Component, Fallback, lazy, children })
      return { meta, className, props, children }
    })
    .map((component, i) => {
      return component.meta.lazy ? (
        <Suspense key={component.meta.key} fallback={<component.meta.Fallback {...component.props} />}>
          <component.meta.Component {...component.props} className={component.className} meta={component.meta}>
            {generateModularBlocks(component.children, { console })}
          </component.meta.Component>
        </Suspense>
      ) : (
        <component.meta.Component key={component.meta.key} className={component.className} {...component.props} meta={component.meta}>
          {generateModularBlocks(component.children, { console })}
        </component.meta.Component>
      )
    })
}
