import { css } from "@emotion/react"

import { fullGrid } from "css/grid"

export const sectionStyle = css`
  position: relative;
  justify-content: center;
  ${fullGrid};
  grid-template-rows: [fullh-start title] var(--row-h) [skyline cta] auto 15vh [fullh-end];
  z-index: 0;
`
