import { useEffect, useRef } from "react"
import { motion, animate } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import useConstant from "hooks/useConstant"

import { useConsole } from "contexts/Console"

import ImageCLD from "components/media/ImageCLD"

import { getSlugIndex } from "../utils"
import { useProgress } from "../progressContext"
import { usePlayer } from "../context"

import useSlugChange from "./useSlugChange"

export const mediaAnim = {
  zoomIn: `ma${hash("zoomIn")}`,
  zoomOut: `ma${hash("zoomOut")}`,
  leftToRight: `ma${hash("leftToRight")}`,
  rightToLeft: `ma${hash("rightToLeft")}`,
}

const Root = styled(motion.div)`
  width: 100%;
  height: 100%;

  grid-column: doc;
  grid-row: card;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.${mediaAnim.zoomIn} img {
    transform: scale(calc(1 + 0.2 * var(--prog)));
  }
  &.${mediaAnim.zoomOut} img {
    transform: scale(calc(1.2 - 0.2 * var(--prog)));
  }
  &.${mediaAnim.leftToRight} img {
    transform: translate3d(calc(-5% + 10% * var(--prog)), 0, 0) scale(1.1);
  }
  &.${mediaAnim.rightToLeft} img {
    transform: translate3d(calc(5% - 10% * var(--prog)), 0, 0) scale(1.1);
  }
`

export default function Image({ slugId, duration, media_anim, indexOfStory, ...data }) {
  const console = useConsole()

  const { slug, slugList, isHolding, currentStoryIndex, isAnimDisabled, shouldReduceMotion } = usePlayer()
  const { progressMap } = useProgress()

  const animProg = useConstant(() => progressMap.get(slugId))

  const { nextSlug } = useSlugChange({ slugId, indexOfStory, play, stop })

  const anim = useRef()

  function play() {
    const dur = (1 - animProg.get()) * duration
    anim.current = animate(animProg, 1, {
      duration: dur,
      ease: "linear",
      onComplete: () => nextSlug(),
    })
  }

  function stop() {
    !!anim.current && anim.current.stop()
  }

  function onCurStoIndexChange(i) {
    if (i !== indexOfStory) {
      animProg.set(0)
    }
  }
  useEffect(() => currentStoryIndex.onChange(onCurStoIndexChange))

  function onHoldingChange(bool) {
    if (!shouldReduceMotion) {
      if (bool) {
        stop()
      } else {
        if (slug.get() === slugId) {
          play()
        }
      }
    }
  }
  useEffect(() => isHolding.onChange(onHoldingChange))

  useEffect(function init() {
    if (slug.get() === slugId) {
      if (!isAnimDisabled.get()) {
        play()
      } else {
        animProg.set(1)
      }
    } else {
      if (getSlugIndex(slugList, slugId) < getSlugIndex(slugList, slug.get())) {
        animProg.set(1)
      }
    }

    return () => stop()
  }, [])

  return (
    <Root className={mediaAnim[media_anim]} style={{ "--prog": animProg }}>
      <ImageCLD {...data} sizes='100vw' alt='' />
    </Root>
  )
}
