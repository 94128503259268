import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"

const Header = styled.header`
  height: 100vh;
`

export default function FallBackModel(props) {
  const console = useConsole()

  console.verbose("FallBackModel(%o)", props)
  return <Header />
}
