import { motion } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"

import { surtitle50, headline50, legend110, bold } from "css/text"

import Image from "components/media/ImageCLD"
import Link from "components/link/Link"

const lastCardCL = `l${hash("last-card")}`

const A = styled.a`
  & * {
    pointer-events: none;
  }

  height: 100%;
  display: flex;

  transition: color 300ms;
`

const Figure = styled(motion.figure)`
  --dir: 1;
  [dir="rtl"] & {
    --dir: -1;
  }
  width: 100%;
  & button {
    all: unset;
    pointer-events: none;
  }

  & > img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 3/2;
  }

  & figcaption {
    margin-block-start: 10px;
  }
`

const ImgContainer = styled(motion.div)`
  overflow: hidden;
  & img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const commonStyle = css`
  &,
  & ${A} {
    text-decoration: none;
    color: white;
    & > ${A}, & > ${Figure} {
      @media (hover: hover) and (pointer: fine) {
        & img {
          transform: scale(1) translate3d(0, 0, 0);
          transition: transform 0.4s ease-out;
        }
        &:hover img {
          transform: scale(1.05) translate3d(0, 0, 0);
        }
      }
    }
  }
`

const Root = styled(motion.li)`
  ${commonStyle};
`

const LastRoot = styled(motion.li)`
  ${commonStyle};

  &:nth-of-type(odd):last-of-type {
    grid-column: span 2;
    & div {
      padding-block-start: 2rem;
      padding-inline: 0 0;
    }
  }
`

const Kicker = styled.span`
  display: block;
  margin-top: 15px;
  padding-inline-end: 1.125rem;
  ${surtitle50}
`

const LastContainer = styled(motion.div)`
  align-self: center;
  padding-inline: 20px 0;
  margin-block-end: 20px;
`

const Title = styled.span`
  ${legend110}
  ${bold}
`

const TitleLast = styled.div`
  ${headline50}
  margin-block-end: 0.5rem;
  &:lang(de) {
    font-size: 1rem;
  }
`

const LinkCTA = styled(Link)`
  padding-block: 0;

  a:hover & {
    color: rgb(var(--ocean-green) / 1) !important;
  }
`

const rootVariants = {
  hide: { opacity: 0, transition: { duration: 0.08 } },
  show: { opacity: 1, transition: { duration: 0.2 } },
}

export const cardsVariants = {
  mob: {
    hide: { transition: { duration: 0.1 } },
    show: { transition: { duration: 0.25, ease: "easeOut" } },
  },
  desk: {
    hide: { transition: { duration: 0.1 } },
    show: { transition: { duration: 0.25, ease: "easeOut" } },
  },
}

export function LastCard({ link, title, cardsVariants }) {
  const console = useConsole()

  const navigation = useNavigation()

  const navigate = e => {
    e.preventDefault()
    navigation.navigate(e.target.getAttribute("href"))
  }
  const active = link.href === navigation.slug

  return (
    <LastRoot variants={rootVariants}>
      <A className={active && "current"} href={navigation.localize(link.href)} onClick={navigate} aria-current={active ? "page" : null}>
        <LastContainer>
          <TitleLast>{title}</TitleLast>
          <LinkCTA className='inline'>{link.label}</LinkCTA>
        </LastContainer>
      </A>
    </LastRoot>
  )
}

export default function Card({ image_cld: image, kicker, link, title, cardsVariants }) {
  const console = useConsole()

  const navigation = useNavigation()

  const navigate = e => {
    e.preventDefault()
    navigation.navigate(e.target.getAttribute("href"))
  }
  const active = link.href === navigation.slug

  console.verbose("Section:Card(%o)", { title, link })
  return (
    <Root variants={rootVariants}>
      <A className={active && "current"} href={navigation.localize(link.href)} onClick={navigate} aria-current={active ? "page" : null}>
        <Figure variants={cardsVariants}>
          <ImgContainer>
            <Image {...image} quality='auto:best' sizes='35vw' alt='' /> {/*Demand to be forced to quality best*/}
          </ImgContainer>
          <motion.figcaption>
            {kicker ? <Kicker>{kicker}</Kicker> : null}
            {title ? <Title>{title}</Title> : null}
          </motion.figcaption>
        </Figure>
      </A>
    </Root>
  )
}
