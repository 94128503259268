import { createContext, useContext } from "react"
import { useMotionValue } from "framer-motion"

export const Context = createContext()

export const PanelModalProvider = ({ children }) => {
  const panelModalState = useMotionValue(false)

  return <Context.Provider value={{ panelModalState }}>{children}</Context.Provider>
}

export const usePanelModal = () => useContext(Context)
