import { useMemo } from "react"
import styled from "@emotion/styled"
import { motion, useTransform, transform } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import getMediaQuery from "css/breakpoints"
import { contrastMode } from "css/a11y"

import generateModularBlocks from "utils/generateModularBlocks"

import { modularTexts } from "components/articles/common"

const CTABlock = styled(motion.div)`
  position: relative;
  grid-column: col 1 / span 5;
  grid-row: cta;

  &.align-center {
    grid-column: main;
  }

  color: white;

  ${getMediaQuery("m")} {
    grid-column: col 1 / span 7;

    &.align-center {
      grid-column: col 4 / span 6;
    }
  }
  ${getMediaQuery("l")} {
    grid-column: col 1 / span 4;
  }

  ${modularTexts}

  ${contrastMode}
`

export default function BlockCTA({ items, align, prog, doubled }) {
  const console = useConsole()
  const { height } = useViewport()

  const modularBlocks = useMemo(() => generateModularBlocks(items, { console }), [])

  const opacity = useTransform([prog, height], ([p, h]) => transform(p, doubled ? [h * 0.9, h * 1.1] : [h * 0.4, h * 0.6], [0, 1]))

  return (
    <CTABlock className={align ? `align-${align}` : ""} style={{ opacity }}>
      {modularBlocks}
    </CTABlock>
  )
}
