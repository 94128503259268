import { useDictionary } from "contexts/Dictionary"
import { useWishlist } from "contexts/Wishlist"
import { memo, useEffect, useState } from "react"

import { AriaHelper, Button, WishlistIcon, NewModel, Root } from "./style"

const WishlistButton = props => {
  const { rmc, family, icon = "wishlist", withLabel, modelName, className, newmodelselection, aria_describedby } = props

  const { toggleWishlist, isInWishlist, wishlistUpdateIndex, wishlist, notificationRMC } = useWishlist()
  const dictionary = useDictionary()

  const [heart, setHeart] = useState(null)
  const [ariaLive, setAriaLive] = useState(null)

  useEffect(() => {
    if (wishlist === null) return
    setHeart(isInWishlist(rmc))
  }, [wishlistUpdateIndex, rmc, family, wishlist])

  const resolveIcon = () => {
    switch (icon) {
      case "wishlist":
        // console.log("icon", heart ? "heartFull" : "heart")
        return heart ? "heartFull" : "heart"
      default:
        return icon
    }
  }

  const handleButtonClick = () => {
    if (!isInWishlist(rmc)) {
      setAriaLive(`${dictionary.headerWishlistWatchAddedTitle()} - ${modelName}`)
    } else {
      setAriaLive(`${dictionary.headerWishlistWatchRemovedTitle()} - ${modelName}`)
    }
    setTimeout(() => {
      setAriaLive(null)
    }, 3000)
    toggleWishlist(rmc, family)
  }

  return (
    <Root className='wishlist-button-root'>
      {newmodelselection && <NewModel className='newmodel'>{dictionary.newModel()}</NewModel>}
      <Button
        className={className || "wishlist-button"}
        heart={heart}
        onClick={() => handleButtonClick()}
        aria-describedby={aria_describedby}
        aria-label={!heart ? `${modelName || ""} - ${dictionary.wishlistAriaLabelAdd()}` : `${modelName || ""} - ${dictionary.wishlistAriaLabelRemove()}`}
      >
        <WishlistIcon type={resolveIcon()} style={{ fill: icon === "close" ? "rgb(var(--dark-grey))" : null }} />

        <AriaHelper>
          <div aria-live='polite'>{ariaLive}</div>
        </AriaHelper>
        {withLabel && <span>{!heart ? dictionary.wishlistAdd() : dictionary.wishlistRemove()}</span>}
      </Button>
    </Root>
  )
}

export default WishlistButton
