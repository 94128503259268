import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"
import { cssVarExpansion, cssVarTransitionDuration, cssVarTransitionDelay } from "contexts/Header"
export const SearchRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: ${({ isStoreLoc }) => isStoreLoc && `calc(var(${cssVarExpansion}) * 40px)`};
  transition: margin var(${cssVarTransitionDuration}) var(${cssVarTransitionDelay});
  width: 100%;
`

export const SearchContainer = styled.div`
  position: relative;
  z-index: 3;
  grid-column: main;
  width: 86vw;
  display: flex;
  align-items: center;

  ${getMediaQuery("m")} {
    min-width: 576px;
    width: 56vw;
  }

  ${getMediaQuery("l")} {
    width: 38vw;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`

export const SearchInput = styled.input`
  font-family: var(--primary-font-family);
  height: 40px;
  box-sizing: border-box;
  position: relative;
  transition: opacity 0.2s;
  width: 100%;
  padding: 0;
  padding-inline-start: 35px;
  padding-inline-end: 70px;
  position: relative;
  border-radius: 30px;
  border: none;
  color: rgb(var(--pure-white));
  background: rgb(var(--pure-white) / 0.1);
  &:focus {
    outline-width: 0px;
    outline: none;
  }

  ${getMediaQuery("m", { max: true })} {
    padding-inline-start: 20px;
  }
  ${getMediaQuery("l")} {
    padding-inline-end: 100px;
  }

  ::placeholder {
    color: rgb(var(--pure-white) / 0.8);
    opacity: 0.4;
    font-size: 18px;
  }

  ${getMediaQuery("m", { max: true })} {
    width: 100%;
    border: none;
    :placeholder-shown {
      width: 100%;
      text-overflow: ellipsis;
    }
  }
`

export const List = styled.ul`
  list-style: none;
`
export const SearchButton = styled.button`
  display: block;
  position: absolute;
  line-height: unset;
  border: none;
  background: none;
  padding: 5px 10px;
  inset-inline-end: 7px;

  cursor: pointer;
  opacity: ${({ searchButtonActive }) => (searchButtonActive ? 1 : 0.3)};
  svg {
    margin-top: 5px;
    fill: rgb(var(--pure-white));
  }

  ${getMediaQuery("m")} {
    inset-inline-end: 21px;
  }
  ${getMediaQuery("m")} {
    ${({ searchButtonActive }) =>
      searchButtonActive &&
      `
    &:hover {
      svg {
        fill: rgb(var(--ocean-green) / 1) !important;
      }
    }
    `};
  }
`
export const EraseButton = styled.button`
  ${buttonIcon};
  ${translucentDark};
  position: absolute;
  width: 20px;
  height: 20px;
  inset-inline-end: calc(30px + 5px + 10px);

  --container: rgb(20, 95, 60);
  ${getMediaQuery("m")} {
    &:hover {
      --container: rgb(var(--ocean-green) / 1) !important;
    }
  }

  ${getMediaQuery("m")} {
    inset-inline-end: calc(30px + 18px + 16px);

    &:hover {
      --container: rgb(20, 95, 60);
    }
  }

  span {
    width: 24px;
    height: 24px;
    ${getMediaQuery("m", { max: true })} {
      width: 14px;
      height: 14px;
    }
  }

  svg {
    fill: white;
    height: 6px;
    width: 6px;
  }
`
