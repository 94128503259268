import { createContext, useContext, useState, useEffect } from "react"
import { useMotionValue, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"

import useConstant from "hooks/useConstant"

export const Context = createContext()

export const StateProvider = ({ children }) => {
  const console = useConsole()

  const [pagesCount, setPagesCount] = useState(0)

  const slides = useConstant(() => new Set())

  const currentIndex = useMotionValue(0)
  const targetSlide = useMotionValue(0)
  const prog = useMotionValue(0)
  const isReturnVisitor = useMotionValue(false)

  const num = useMotionValue(0)

  const unit = useTransform(num, n => 1 / n)

  const input = useTransform([num, unit], ([n, u]) => Array.from(Array(n + 1)).map((_, i) => u * i - u * 0.5))
  const inStopVid = useTransform([num, unit], ([n, u]) => Array.from(Array(n)).map((_, i) => u * (i + 1) - u * 0.5))

  function updatePageNum(uid) {
    slides.add(uid)
    setPagesCount(slides.size)
  }
  function removePage(uid) {
    slides.delete(uid)
    setPagesCount(slides.size)
  }

  useEffect(() => {
    num.set(pagesCount)
  }, [pagesCount, num])

  return (
    <Context.Provider value={{ currentIndex, targetSlide, input, inStopVid, prog, pagesCount, updatePageNum, removePage, num, isReturnVisitor }}>
      {children}
    </Context.Provider>
  )
}

export const useValue = () => useContext(Context)
