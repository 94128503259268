import { createContext } from "react"
import { FrameAnimationProvider } from "./FrameAnimation"
import { CfgContents } from "./contents"
import { CfgModal } from "./modal"

export default function Configurator({ children, className, ...props }) {
  const cfgCtx = createContext()

  return (
    <CfgModal className={className}>
      <FrameAnimationProvider Ctx={cfgCtx} {...props}>
        <CfgContents Ctx={cfgCtx} intro={props.intro} onClose={props.onClose}>
          {children}
        </CfgContents>
      </FrameAnimationProvider>
    </CfgModal>
  )
}
