import { css } from "@emotion/react"

export const theme = css`
  .dark-theme {
    color: rgb(var(--pure-white));
  }
  .light-theme {
    color: rgb(var(--light-black));
  }
`
