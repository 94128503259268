import { useConsole } from "contexts/Console"

import Link from "components/link/Link"

export default function CtaLink(props) {
  const console = useConsole()

  console.verbose("CtaLink(%o)", props)
  return <Link {...props}>{props.label}</Link>
}
