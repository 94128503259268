import { useConsole } from "contexts/Console"

import styled from "@emotion/styled"
import { Para } from "./LegalParagraph"

const List = styled.ul`
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 2.5rem;
  list-style-type: disc;

  & > li > ul {
    list-style-type: circle;

    & > li > ul {
      list-style-type: square;
    }
  }
`

const Li = styled.li``

export default function LegalList({ list_first_level }) {
  const console = useConsole()
  return (
    list_first_level.length > 0 && (
      <List>
        {list_first_level.map(({ paragraph, list_second_level }, i) => (
          <Li key={`list_first_level-el-${i}`}>
            {paragraph.map((p, i) => (
              <Para key={`parafirst-${i}`} dangerouslySetInnerHTML={{ __html: p }} />
            ))}
            {list_second_level.length > 0 && (
              <List>
                {list_second_level.map(({ paragraph, list_third_level }, i) => (
                  <Li key={`list_sec_level-el-${i}`}>
                    {paragraph.map((p, i) => (
                      <Para key={`parasec-${i}`} dangerouslySetInnerHTML={{ __html: p }} />
                    ))}
                    {list_third_level.length > 0 && (
                      <List>
                        {list_third_level.map(({ paragraph }, i) => (
                          <Li key={`list_third_level-el-${i}`}>
                            {paragraph.map((p, i) => (
                              <Para key={`parathird-${i}`} dangerouslySetInnerHTML={{ __html: p }} />
                            ))}
                          </Li>
                        ))}
                      </List>
                    )}
                  </Li>
                ))}
              </List>
            )}
          </Li>
        ))}
      </List>
    )
  )
}
