import styled from "@emotion/styled"
import { useSeparator } from "react-aria"
import { Fragment, Suspense } from "react"
import { Route, StaticRouter, Switch } from "react-router-dom"

import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Footer"
import { useNavigation } from "contexts/Navigation"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import { Crown } from "components/footer/Icons"
import Category from "components/footer/Category"
import Share from "components/share/Share"
import Breadcrumb from "components/breadcrumb/Breadcrumb"
import ControlPanel from "components/footer/ControlPanel"
import Underfooter from "components/footer/Underfooter"
import Push from "components/push/PushOrg"

const Root = styled.footer`
  width: 100vw;
`

const Wrapper = styled.div`
  background: rgb(var(--light-grey));
  position: relative;
  z-index: 1;
`

const Main = styled.div`
  ${fullGrid}
  padding-block: 2.5rem 1.5rem;

  ${getMediaQuery("m")} {
    padding-block: 3.75rem 2.75rem;
  }
`

const Brand = styled.section`
  grid-column: col 1 / col -1;
  padding-block-end: 2.5rem;

  ${getMediaQuery("m")} {
    grid-column: col 1 / span 4;
    padding-block-end: 3.75rem;
  }

  ${getMediaQuery("l")} {
    grid-column: col 1 / span 3;
  }
`

const Navigation = styled.nav`
  display: contents;
`

const Section = styled.ul`
  column-count: 2;
  column-gap: var(--grid-gap);
  grid-column: main;
  --baseline-font-size: clamp(0.875rem, 0.8125rem + 0.1563vw, 1rem);
  --baseline: calc(1.79 * var(--baseline-font-size));
  /* uncomment below to debug baseline-grid */
  /* background-image: repeating-linear-gradient(
    to bottom,
    transparent 0px,
    transparent calc(var(--baseline) - 1px),
    red calc(var(--baseline) - 1px),
    red var(--baseline)
  ); */

  ${getMediaQuery("m")} {
    grid-column: col 5 / span 8;
  }

  ${getMediaQuery("l")} {
    column-count: 3;
    grid-column: col 4 / span 9;
  }

  > li {
    break-inside: avoid;
    display: block;
    margin-block-end: var(--baseline);
    padding-inline-end: calc(var(--grid-gap));
    line-height: var(--baseline);

    // Safari
    box-sizing: border-box;
    width: 100%;

    .reverseIcon {
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
`

function Separator(props) {
  const { separatorProps } = useSeparator(props)

  return (
    <div
      {...separatorProps}
      style={{
        background: "rgb(var(--grey))",
        height: "1px",
        marginInline: "var(--outer-margin)",
      }}
    />
  )
}

export default function Footer(props) {
  const { categories, social, share, languages, breadcrumb = [], slugForBreadcrumb, accessibility, wechat, firstlinkicp, secondlinkicp, listPush } = props
  const console = useConsole()
  const navigation = useNavigation()

  console.verbose("Footer(%o)", listPush, navigation.slug)
  //TODO:{...children?.[0]?.props?.children?.props?.children?.props} to more readable workaround

  return (
    <Provider value={{}}>
      <Root role='contentinfo'>
        <Breadcrumb items={breadcrumb} slug={slugForBreadcrumb} />
        <Share {...share} />
        <Wrapper>
          <Main>
            <Brand>
              <Crown />
            </Brand>
            <Navigation>
              <Section>
                {categories.map((item, index) => (
                  // Loop over categories and insert "Accessibility" in second to last position
                  <Fragment key={index}>
                    {index === categories.length - 1 && <Category accessibility {...accessibility} />}
                    <Category {...item} />
                  </Fragment>
                ))}
                <Category social {...social} wechat={wechat} />
              </Section>
            </Navigation>
          </Main>
          <Separator />
          <ControlPanel {...languages} firstlinkicp={firstlinkicp} secondlinkicp={secondlinkicp} />
        </Wrapper>
        
        <Underfooter>
          <StaticRouter location={navigation.slug}>
            <Switch>
              {listPush.map((item, i) => {
                return (
                  <Route path={item.url} exact={false}>
                    <Suspense>
                      <Push {...item.reference[0]} />
                    </Suspense>
                  </Route>
                )
              })}
            </Switch>
          </StaticRouter>
        </Underfooter>
      </Root>
    </Provider>
  )
}
