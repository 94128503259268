import styled from "@emotion/styled"
import { cssVarHeight } from "contexts/Header"
import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import { headline70 } from "../../css/text"

export const Root = styled.section`
  ${fullGrid}

  a {
    text-decoration: none;
    color: rgb(var(--green));
  }

  table {
    border-collapse: collapse;

    td {
      border: 1px solid black;
      padding: 15px;
    }
  }
`

export const Heading = styled.h1`
  ${headline70}
  margin-top: 10vh;
  grid-column: main;

  & > em {
    font-style: normal;
    color: rgb(var(--green));
  }

  ${getMediaQuery("m")} {
    grid-column: col 2 / span 6;
  }
`

export const ContentRoot = styled.section`
  /* margin-top: var(--m-h-space); */
  font-size: 1em;
  grid-column: main;
  ${getMediaQuery("m")} {
    grid-column: col 2 / span 10;
  }

  > p:nth-child(1) {
    margin-block-start: 5rem;

    ${getMediaQuery("m")} {
      margin-block-start: 6.25rem;
    }
  }

  ul + ul {
    margin-block: 2rem;
  }
`
