import { css } from "@emotion/react"
// import { webFonts } from "css/webFonts"

/* ${webFonts} */
export const fonts = css`
  // ========================
  // Default
  // ------------------------

  :root {
    --primary-font-family: "Helvetica Now Text", Helvetica, Arial, sans-serif;
    --primary-font-weight: 300;
    --secondary-font-family: RolexFont-S, sans-serif;
    --secondary-letter-spacing: 0.125em;
    --leading: normal;
    --quote-font-family: SangBleuKingdom, "Times New Roman", Times, serif;
    --quote-font-weight: 300;
    --quote-font-style: normal;
    --quote-opening: "“";
    --quote-closing: "”";

    color: rgb(var(--light-black));
  }

  // ------------------------
  // Arabic
  // ------------------------

  :root:lang(ar) {
    --primary-font-family: Arial, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: RolexFont-XL, sans-serif;
    --secondary-letter-spacing: 0;
    --quote-font-weight: normal;
    --quote-font-style: normal;
    // Requested by DTW
    --quote-opening: '"';
    --quote-closing: '"';
  }

  :lang(ar) * {
    font-style: normal !important;
    letter-spacing: 0 !important;
  }

  // ------------------------
  // Azerbaijani
  // ------------------------

  :root:lang(az) {
    --primary-font-family: sans-serif;
  }

  // ------------------------
  // German
  // ------------------------

  :root:lang(de) {
    --quote-opening: "„";
    --quote-closing: "“";
  }

  // ------------------------
  // Greek
  // ------------------------

  :root:lang(el) {
    --primary-font-family: "Helvetica Neue", "Arial Nova", Helvetica, Arial, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: "Helvetica Neue", "Arial Nova", Helvetica, Arial, sans-serif;
    --secondary-text-transform: uppercase;
  }

  // ------------------------
  // Spanish
  // ------------------------

  :root:lang(es) {
    // V7-685, DTW will contribute quotation marks for this lang
    --quote-opening: "";
    --quote-closing: "";
  }

  // ------------------------
  // Farsi
  // ------------------------

  :root:lang(fa) {
    --primary-font-family: Arial, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: RolexFont-XL, sans-serif;
    --secondary-letter-spacing: 0;
    --quote-font-weight: normal;
    --quote-font-style: normal;
    // Requested by DTW
    --quote-opening: '"';
    --quote-closing: '"';
  }

  :lang(fa) * {
    font-style: normal !important;
    letter-spacing: 0 !important;
  }

  // ------------------------
  // French
  // ------------------------

  :root:lang(fr) {
    --quote-opening: "«\u202f";
    --quote-closing: "\u202f»";
  }

  // ------------------------
  // Hebrew
  // ------------------------

  :root:lang(he) {
    --primary-font-family: Arial, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: Arial, sans-serif;
    --secondary-letter-spacing: 0;
    --quote-font-weight: normal;
    --quote-font-style: normal;
    // Requested by DTW
    --quote-opening: '"';
    --quote-closing: '"';
  }

  :lang(he) * {
    font-style: normal !important;
    letter-spacing: 0 !important;
  }

  // ------------------------
  // Hindi
  // ------------------------

  :root:lang(hi) {
    --primary-font-family: Kokila, "Nirmala UI", "Devanagari Sangam MN", sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: Kokila, "Nirmala UI", "Devanagari Sangam MN", sans-serif;
    --secondary-letter-spacing: 0;
    --quote-font-family: Kokila, "Nirmala UI", "Devanagari Sangam MN", sans-serif;
    --quote-font-weight: normal;
    --quote-font-style: normal;
  }

  :lang(hi) * {
    font-style: normal !important;
    letter-spacing: 0 !important;
  }

  // ------------------------
  // Japanese
  // ------------------------

  :root:lang(ja) {
    --primary-font-family: "Hiragino Sans", "Yu Gothic", sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: "YuGothic", "Yu Gothic", sans-serif;
    --secondary-letter-spacing: 0;
    --leading: 1.4;
    --quote-font-family: "Hiragino Mincho Pro N", "Yu Mincho", serif;
    --quote-font-weight: normal;
    --quote-font-style: normal;
    --quote-opening: "「";
    --quote-closing: "」";
  }

  :lang(ja) * {
    font-style: normal !important;
  }

  // ------------------------
  // Korean
  // ------------------------

  :root:lang(ko) {
    --primary-font-family: "Malgun Gothic", Dotum, AppleSDGothicNeo-Medium, "Apple SD Gothic Neo Medium", "Helvetica", sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: "Malgun Gothic", Dotum, AppleSDGothicNeo-Medium, "Apple SD Gothic Neo Medium", "Helvetica", sans-serif;
    --secondary-letter-spacing: 0;
    --quote-font-family: "Malgun Gothic", Dotum, AppleSDGothicNeo-Medium, "Apple SD Gothic Neo Medium", "Helvetica", sans-serif;
    --quote-font-weight: normal;
    --quote-font-style: normal;
  }

  :lang(ko) * {
    font-style: normal !important;
  }

  // ------------------------
  // Polish
  // ------------------------

  :root:lang(pl) {
    // V7-685, DTW will contribute quotation marks for this lang
    --quote-opening: "";
    --quote-closing: "";
  }

  // ------------------------
  // Russian
  // ------------------------

  :root:lang(ru) {
    --primary-font-family: "Helvetica Neue", "Arial Nova", Helvetica, Arial, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: RolexFont-XL, sans-serif;
    // V7-685, DTW will contribute quotation marks for this lang
    --quote-opening: "";
    --quote-closing: "";
  }

  // ------------------------
  // Thai
  // ------------------------

  :root:lang(th) {
    --primary-font-family: "Helvetica Thai", sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: "Avenir Next Thai Modern", sans-serif;
    --quote-font-family: "Sukothai LT", serif;
    --quote-font-weight: normal;
    --quote-font-style: normal;
  }

  :lang(th) * {
    font-style: normal !important;
  }

  // ------------------------
  // Turkish
  // ------------------------

  :root:lang(tr) {
    --secondary-font-family: RolexFont-XL, sans-serif;
  }

  // ------------------------
  // Vietnamese
  // ------------------------

  :root:lang(vi) {
    --primary-font-family: "Helvetica Neue", Arial, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: Verdana, sans-serif;
    --quote-font-family: "Times New Roman", serif;
  }

  // ------------------------
  // Simplified Chinese
  // ------------------------

  :root:lang(zh-Hans) {
    --primary-font-family: "Microsoft YaHei", "Heiti SC Light", "ST Heiti SC Light", "STHeiti SC Light", STHeitiSC-Light, "STHeiti Light", STXihei, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: "Microsoft YaHei", "Heiti SC Light", "ST Heiti SC Light", "STHeiti SC Light", STHeitiSC-Light, "STHeiti Light", STXihei,
      "Helvetica", sans-serif;
    --quote-font-family: DFPSongStd, STSong, "Heiti SC Light", "ST Heiti SC Light", "STHeiti SC Light", STHeitiSC-Light, "STHeiti Light", STXihei, serif;
    --quote-font-weight: normal;
    --quote-font-style: normal;
  }

  :lang(zh-Hans) * {
    font-style: normal !important;
  }

  // ------------------------
  // Traditional Chinese
  // ------------------------

  :root:lang(zh-Hant) {
    --primary-font-family: "Microsoft YaHei", "Heiti TC Light", "ST Heiti TC Light", "STHeiti TC Light", STHeitiTC-Light, "STHeiti Light", STXihei, sans-serif;
    --primary-font-weight: normal;
    --secondary-font-family: "Microsoft YaHei", "Heiti TC Light", "ST Heiti TC Light", "STHeiti TC Light", STHeitiTC-Light, "STHeiti Light", STXihei,
      "Helvetica", sans-serif;
    --quote-font-family: DFLiSongHK, LiSung, LisungLight, "Apple LiSung Light", "Heiti TC Light", "ST Heiti TC Light", "STHeiti TC Light", STHeitiTC-Light,
      "STHeiti Light", STXihei, serif;
    --quote-font-weight: normal;
    --quote-font-style: normal;
    --quote-opening: "「";
    --quote-closing: "」";
  }

  :lang(zh-Hant) * {
    font-style: normal !important;
  }

  // ------------------------
  // End languages
  // ========================

  html {
    font-family: var(--primary-font-family);
    font-weight: var(--primary-font-weight);
    line-height: var(--leading);
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
