import { useEffect, useContext } from "react"
import { useMotionValue, useTransform } from "framer-motion"

export function RollerAnimationProvider({ children, Ctx }) {
  const focus = useMotionValue(-1)
  const rmfocus = useMotionValue(null)
  const x = useMotionValue(0)
  const W = useMotionValue(0)
  const N = useMotionValue(1)
  const R = useMotionValue(1)
  const ready = useMotionValue(0)
  const count = useMotionValue(0)
  const readySL = useMotionValue(0)
  const readyWR = useMotionValue(0)
  const readyALL = useTransform([readySL, readyWR], ([readySL, readyWR]) => readySL && readyWR)

  function kill() {
    ready.set(0)
    x.set(0)
    W.set(0)
    R.set(1)
    N.set(1)
    focus.set(-1)
    rmfocus.set(null)
  }
  useEffect(() => kill, [])

  return <Ctx.Provider value={{ focus, rmfocus, ready, x, W, N, R, count, readySL, readyWR, readyALL }}>{children}</Ctx.Provider>
}

export function useRollerAnimation(ctx) {
  return useContext(ctx)
}
