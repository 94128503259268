import { Cloudinary } from "@cloudinary/url-gen"
import { limitFit } from "@cloudinary/url-gen/actions/resize"
import { auto } from "@cloudinary/url-gen/qualifiers/format"
import { set } from "@cloudinary/url-gen/actions/variable"
import { expression } from "@cloudinary/url-gen/qualifiers/expression"

export default function imageLoader({ src, policy, path }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: {
      cloudName: cloudName,
    },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })

  return ({ width, quality = "auto:eco" }) => {
    const image = cld.image(src.public_id)
    image.setVersion(src.version)

    image.quality(quality)
    image.format(auto())

    if (path) {
      image.addVariable(set("path", expression("!" + path + "!")))
    }

    if (policy) {
      image.namedTransformation(policy)
    }

    image.resize(limitFit().width(width))

    try {
      const url = new URL(image.toURL())
      url.searchParams.delete("_a") //necessary for hydration
      return url
    } catch (error) {
      return null
    }
  }
}

export function imageLoaderOneSrc({ src, policy, path }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: {
      cloudName: cloudName,
    },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })

  return ({ quality = "auto:best" }) => {
    const image = cld.image(src.public_id)

    image.setVersion(src.version)

    image.quality(quality)
    //    image.format(auto())

    if (path) {
      image.addVariable(set("path", expression("!" + path + "!")))
    }

    if (policy) {
      image.namedTransformation(policy)
    }

    //    image.resize(limitFit().width(width))

    try {
      const url = new URL(image.toURL())
      url.searchParams.delete("_a") //necessary for hydration
      return url
    } catch (error) {
      return null
    }
  }
}
