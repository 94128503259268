import { css } from "@emotion/react"
import hash from "@emotion/hash"

export const pageGradientVar = `--${hash("page:color-set:gradient")}`
export const fontColorVar = `--${hash("page:color-set:font")}`

export const colors = css`
  .pcs${hash("page-color-set-rolex-v7-color-001")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(54 32 25), rgb(156 102 83));
    ${fontColorVar}: rgb(231 195 182);
  }

  .pcs${hash("page-color-set-rolex-v7-color-002")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(93 69 69), rgb(230 189 193));
    ${fontColorVar}: rgb(255 224 227);
  }

  .pcs${hash("page-color-set-rolex-v7-color-003")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(93 69 69), rgb(205 166 170));
    ${fontColorVar}: rgb(228 198 201);
  }

  .pcs${hash("page-color-set-rolex-v7-color-004")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(60 51 35), rgb(156 126 85));
    ${fontColorVar}: rgb(247 230 195);
  }

  .pcs${hash("page-color-set-rolex-v7-color-005")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(85 80 69), rgb(197 185 155));
    ${fontColorVar}: rgb(241 230 203);
  }

  .pcs${hash("page-color-set-rolex-v7-color-006")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(67 66 61), rgb(198 195 187));
    ${fontColorVar}: rgb(247 231 216);
  }

  .pcs${hash("page-color-set-rolex-v7-color-007")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(24 31 41), rgb(81 90 95));
    ${fontColorVar}: rgb(157 163 165);
  }

  .pcs${hash("page-color-set-rolex-v7-color-008")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(54 55 54), rgb(162 164 163));
    ${fontColorVar}: rgb(204 204 204);
  }

  .pcs${hash("page-color-set-rolex-v7-color-009")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(54 54 54), rgb(162 164 163));
    ${fontColorVar}: rgb(204 204 204);
  }

  .pcs${hash("page-color-set-rolex-v7-color-010")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(54 46 42), rgb(147 130 120));
    ${fontColorVar}: rgb(218 198 188);
  }

  .pcs${hash("page-color-set-rolex-v7-color-011")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(35 29 47), rgb(133 118 172));
    ${fontColorVar}: rgb(203 193 228);
  }

  .pcs${hash("page-color-set-rolex-v7-color-012")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(26 37 27), rgb(66 105 75));
    ${fontColorVar}: rgb(164 204 174);
  }

  .pcs${hash("page-color-set-rolex-v7-color-013")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(5 22 58), rgb(33 60 95));
    ${fontColorVar}: rgb(142 172 223);
  }

  .pcs${hash("page-color-set-rolex-v7-color-014")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(28 48 53), rgb(99 137 157));
    ${fontColorVar}: rgb(210 233 254);
  }

  .pcs${hash("page-color-set-rolex-v7-color-015")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(10 10 10), rgb(61 61 64));
    ${fontColorVar}: rgb(164 164 177);
  }

  .pcs${hash("page-color-set-rolex-v7-color-016")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(21 18 10), rgb(90 72 43));
    ${fontColorVar}: rgb(208 191 163);
  }

  .pcs${hash("page-color-set-rolex-v7-color-017")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(34 21 15), rgb(87 51 37));
    ${fontColorVar}: rgb(197 154 139);
  }

  .pcs${hash("page-color-set-rolex-v7-color-018")} {
    ${pageGradientVar}: linear-gradient(55deg, rgb(127, 49, 17), rgb(191, 130, 71));
    ${fontColorVar}: rgb(208, 162, 100);
  }
`
