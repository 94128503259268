import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { Provider } from "contexts/Dictionary"
import camelCase from "utils/camelCase"

export default function Dictionary({ children, initialState }) {
  const console = useConsole()
  const locale = useLocale()

  const entry = initialState[locale.current.codes.www]?.dictionary?.[0] ?? {}
  const parts = Object.keys(entry).filter(k => k.startsWith("modular_blocks")).map(k => entry[k])
  const translations = parts.flat()
  const ctx = new Proxy(
    translations
      .reduce((acc, { translation: { key, value } }) => {
        acc[camelCase(key)] = (...variables) => {
          let translatedAs = value
          for (const [i, variable] of variables.entries()) translatedAs = translatedAs.replaceAll(`{{${i + 1}}}`, variable)
          return translatedAs
        }

        return acc
      }, {}),
    {
      get: (t, k) => {
        if (t.hasOwnProperty(k)) return t[k]
        else return () => `%MISSING(${k})%`
      },
    }
  )

  console.verbose("Dictionary(%o)", { ctx })
  return <Provider value={ctx}>{children}</Provider>
}
