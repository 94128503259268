import styled from "@emotion/styled"

import { useLocale } from "contexts/Locale"
import { useNavigation } from "contexts/Navigation"
import { useQuickViews } from "contexts/QuickViews"
import { useDictionary } from "contexts/Dictionary"

import { buttonReset } from "css/buttons"

import { Icon } from "components/icon/Icon"
import { load, mapFeature } from "./utils"

const Btn = styled.button`
  ${buttonReset};
  width: 24px;
  height: 24px;
  border: solid 1px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    display: contents;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: white;
      svg {
        fill: black;
      }
    }
  }
`

export default function IButton({ feature, cur, step, listObj, ariaDescribedby }) {
  const navigation = useNavigation()
  const locale = useLocale()
  const dictionary = useDictionary()

  const { qvContent, track } = useQuickViews()

  const f = mapFeature(feature)
  const path = navigation.localize(`/term/${f}.extra.json`, locale.current.codes.www)
  const r = listObj.reduce((acc, o) => acc.concat([o[f]]), [])
  const wa = listObj.reduce((acc, o) => acc.concat([o.waProd]), [])

  async function onOpenQuickViews() {
    const data = await load(path).catch(err => console.log("••• err", err))

    const cleaned = r.map(({ techCode }) => data[1].find(x => x.tech_code === `${f}-${techCode}`))
    const start = cur?.get() ? cur.get() : 0
    const str = JSON.stringify({ items: cleaned, start: start, apply: !!step?.get(), obj: r, wa, feature: f })

    const techCode = listObj[start][f].techCode
    const product = listObj[start].waProd

    track({ event: "Click", product, techCode, step: f, index: start })

    qvContent.set(str)
  }

  return (
    <Btn type='button' className='info-btn' onClick={onOpenQuickViews} aria-describedby={ariaDescribedby} aria-label={dictionary.moreInformation()}>
      <span>
        <Icon type='infoRound' />
      </span>
    </Btn>
  )
}
