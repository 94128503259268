import { startTransition, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"
import { useHeader } from "contexts/Header"
import { useDictionary } from "contexts/Dictionary"
import { legend100, normal, visuallyHidden } from "css/text"
import { useNavigation } from "contexts/Navigation"
import getMediaQuery from "css/breakpoints"
import useSSR from "hooks/useSSR"

const cssOn = `css-${hash("search:toggle:on")}`
const cssOff = `css-${hash("search:toggle:off")}`
const cssAlt = `css-${hash("search:toggle:alt")}`

const Root = styled.div`
  display: grid;
`

const Button = styled.button`
  all: unset;
  cursor: pointer;
  user-select: none;
  grid-area: 1/1;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(15px, auto) 1fr;
  padding: var(--touch-padding);
  position: relative;
  left: var(--touch-padding);
  color: rgb(var(--pure-white));

  ${getMediaQuery("xxl", { min: true })} {
    grid-gap: 1ex;
  }

  [dir="rtl"] & {
    left: calc(-1 * var(--touch-padding));
    right: auto;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--ocean-green));
      fill: rgb(var(--ocean-green));
    }
  }

  & > span {
    ${legend100}
    ${normal}

    /* ${getMediaQuery("xxl", { max: true })} {
      ${visuallyHidden}
    } */

    & > span {
      font-size: 0%;
      opacity: 0;

      ${getMediaQuery("xxl", { min: true })} {
        font-size: min(var(--m, 0) * 100%, 100%);
        opacity: min(var(--m, 0), 1);
      }

      transition: font-size 100ms, opacity 300ms;
    }
  }

  transition: opacity 300ms, color 300ms, fill 300ms;
  will-change: opacity, color, fill;

  & > svg {
    height: 19px;
    width: auto;
    fill: currentColor;
    [dir="rtl"] & {
      transform: scaleX(-1);
    }
  }

  .${cssAlt} & {
    left: calc(-1 * var(--touch-padding));

    [dir="rtl"] & {
      left: 0;
      right: calc(-1 * var(--touch-padding));
    }
  }
`

const On = styled(Button)`
  z-index: 1;
  opacity: 1;
  pointer-events: auto;
  margin-inline: 0.825rem;

  ${getMediaQuery("m")} {
    margin-inline: 0;
  }

  .${cssOff} & {
    opacity: 0;
    pointer-events: none;
  }
`

export default function MenuToggle(props) {
  const console = useConsole()
  const [active, setActive] = useState(true)
  const rroot = useRef()
  const rbutton = useRef()
  const header = useHeader()
  const dictionary = useDictionary()
  const navigation = useNavigation()
  const ssr = useSSR()
  const href = navigation.localize("/store-locator")

  const navigate = e => {
    e.preventDefault()
    navigation.push(href)
  }

  useLayoutEffect(() => header.on(header.events.lock, () => startTransition(() => setActive(false))))
  useLayoutEffect(() => header.on(header.events.unlock, () => startTransition(() => setActive(true))))

  console.verbose("Search:Toggle(%o)", { active, alt: props.alt })
  return (
    <Root ref={rroot} className={active ? cssOn : cssOff}>
      <On
        as='a'
        href={href}
        ref={rbutton}
        type='button'
        onClick={navigate}
        disabled={!active || navigation.slug.startsWith("/store-locator")}
        aria-hidden={!active ? "true" : "false"}
        tabIndex={!active ? -1 : 0}
        style={{ "--m": ssr ? 0 : 1 }}
      >
        <svg viewBox='0 0 15 15'>
          <path d='M7.5,0.5c-3,0-5.4,2.4-5.4,5.4c0,1.1,0.7,2.6,1.7,4c1.7,2.3,3.7,4.6,3.7,4.6s2-2.4,3.7-4.6  c0.9-1.4,1.7-2.9,1.7-4C12.9,2.9,10.5,0.5,7.5,0.5z M7.5,8.4C6.1,8.4,5,7.2,5,5.9c0-1.4,1.1-2.5,2.5-2.5S10,4.5,10,5.9  S8.9,8.4,7.5,8.4z' />
        </svg>
        <span>
          <span>{dictionary.storeLocator()}</span>
        </span>
      </On>
    </Root>
  )
}
