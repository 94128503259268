import { createContext, useContext, useLayoutEffect, useRef } from "react"
import { useMotionValue, useTransform } from "framer-motion"

import useConstant from "hooks/useConstant"

export const Context = createContext()

export const StoryProvider = ({ children }) => {
  const root = useRef()

  const storyGroups = useConstant(() => new Map())

  const storyOpener = useMotionValue("")

  const currentId = useTransform(storyOpener, str => str.split(":")?.[0] || "")
  const initiator = useMotionValue("")
  const wasKeyDown = useMotionValue(false)

  useLayoutEffect(() => {
    root.current = document.createElement("div")
    document.body.appendChild(root.current)
    return () => document.body.removeChild(root.current)
  }, [])

  return <Context.Provider value={{ storyGroups, storyOpener, currentId, root, initiator, wasKeyDown }}>{children}</Context.Provider>
}

export const useStory = () => useContext(Context)
