import { motion, useMotionValue, transform, useTransform, useAnimationControls } from "framer-motion"
import styled from "@emotion/styled"

import { useProgress } from "./progressContext"
import { usePlayer } from "./context"

const Nav = styled.nav`
  position: relative;
  z-index: 10;
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
`

const List = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 2px;
  column-gap: 5px;
  padding: 0px;
  margin: 0px;
  width: 100%;
`

const Li = styled.li`
  display: block;
`

const Link = styled.a`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  transition: background-color 0.3s ease 0s;
  pointer-events: all;

  .dark-theme & {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .light-theme & {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &::after {
    content: "";
    width: 100%;
    height: 400%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;
  }
`

const Bar = styled(motion.div)`
  height: 100%;
  border-radius: 10px;
  background-color: white;

  .dark-theme & {
    background-color: white;
  }
  .light-theme & {
    background-color: rgb(var(--light-black));
  }

  .contrast-active & {
    background-color: black;
  }
`

function ProgBar({ slugId }) {
  const { slug } = usePlayer()
  const { progressMap } = useProgress()

  progressMap.set(slugId, useMotionValue(0))

  const width = useTransform(progressMap.get(slugId), p => `${p * 100}%`)

  function onClick() {
    slug.set(slugId)
  }

  return (
    <Li id={slugId}>
      <Link onClick={onClick}>
        <Bar style={{ width }} />
      </Link>
    </Li>
  )
}

function ProgNoBar({ slugId }) {
  const { progressMap } = useProgress()
  progressMap.set(slugId, useMotionValue(0))

  return null
}

export default function Progress({}) {
  const { progressMap, showProgress } = useProgress()

  return showProgress ? (
    <Nav>
      <List>
        {[...progressMap].map(([slugId]) => (
          <ProgBar key={slugId} slugId={slugId} />
        ))}
      </List>
    </Nav>
  ) : (
    [...progressMap].map(([slugId]) => <ProgNoBar key={slugId} slugId={slugId} />)
  )
}
