import { createContext, useContext } from "react"
import { useMotionValue, useTransform } from "framer-motion"

export const Context = createContext()

export const RollerProvider = ({ count, start = 0, themes, children }) => {
  const currentIndex = useMotionValue(start)
  const targetIndex = useMotionValue(start)
  const waIndex = useMotionValue(start)
  const theme = useTransform(currentIndex, i => themes[i])

  return <Context.Provider value={{ currentIndex, waIndex, targetIndex, theme, count }}>{children}</Context.Provider>
}

export const useRoller = () => useContext(Context)
