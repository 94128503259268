import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"

const Container = styled.div``

export default function TextBlock({ children }) {
  const console = useConsole()

  return <Container className='text-block' dangerouslySetInnerHTML={{ __html: children }} />
}
