import { createContext, useContext } from "react"

import useConstant from "hooks/useConstant"

export const Context = createContext()

export const ProgressProvider = ({ story_id, cards, storyNumber, children }) => {
  const progressMap = useConstant(() => new Map(cards.map((c, i) => [`${story_id}-${i}`, null])))

  const showProgress = storyNumber > 1 || (storyNumber === 1 && cards.length > 1)

  return <Context.Provider value={{ progressMap, showProgress }}>{children}</Context.Provider>
}

export const useProgress = () => useContext(Context)
