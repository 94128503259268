import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Locale"
import { match } from "path-to-regexp"
import { useLocation } from "react-router-dom"

export default function Locale({ children, schema = "/:locale", initialState /*: { default_locale: defaultLocale, locale, locales }*/ }) {
  const console = useConsole()
  const location = useLocation()
  const codes = initialState.locales.map(({ codes: { www } }) => www)
  const externalCodes = (initialState.external_locales ?? []).map(({ codes: { www } }) => www)
  schema = schema.replace(":locale", `:locale(${codes.join("|")})?`)
  const extendedSchema = schema.replace(":locale", `:locale(${[...codes, ...externalCodes].join("|")})?`)
  const urlCode = match(`${schema}/:slug(.*)?`)(location.pathname)?.params?.locale ?? initialState.default_locale.codes.www
  const locale = initialState?.[urlCode]?.locale
  const dir = locale?.dir

  const ctx = {
    codes,
    externalCodes,
    current: locale,
    defaultLocale: initialState.default_locale,
    dir,
    locale,
    locales: initialState.locales,
    externals: initialState.external_locales,
    schema,
    extendedSchema,
  }

  console.verbose("Locale:ctx(%o)", ctx)
  return <Provider value={ctx}>{children}</Provider>
}
