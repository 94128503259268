import { createContext, useContext, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { useConsole } from "contexts/Console"

import Image from "components/media/ImageCLD"
import FeatureProvider from "./context"
import { useScroll, useMotionValue, useTransform, motion, AnimatePresence } from "framer-motion"
import { mvSubscribe, observeResize, rafDelay, setProperty, isTouchEnabled } from "./../utils"
import Arrows from "./Arrows"
import Pagination from "./Pagination"
import { fullGrid } from "css/grid"
import { headline50, legend110 } from "css/text"
import Button from "components/button/Button"
import { CARDS } from "./mock"
import { useDictionary } from "contexts/Dictionary"
import { useFrameAnimation } from "../FrameAnimation"
import useScrollLock from "hooks/useScrollLock"

const Modal = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: min(100%, 100dvh);
  overflow-y: auto;
  transform: translateZ(0);
  overscroll-behavior: none;

  &.wv_straight {
    opacity: 1;
  }

  &:focus-within {
    nav {
      visibility: visible;
    }
  }
`

const Main = styled(motion.section)`
  position: relative;
  height: 100%;
  ${fullGrid}
  user-select: none;
  background-color: white;
//  will-change: opacity, transform;
`

const List = styled.ul`
  grid-row: 1/-1;
  grid-column: doc;
  display: flex;
  height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overscroll-behavior-x: none;
  scroll-behavior: smooth;

  &.cfgf-straight {
    scroll-behavior: auto;
  }

  transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1) 0s;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Item = styled.li`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  flex: 0 0 100%;
  //  transform: translateZ(0);

  &:last-of-type {
    scroll-snap-align: end;
  }
`

const Card = styled(motion.section)`
  position: relative;
  height: 100%;
  ${fullGrid}
  align-items: end;

  & picture {
    display: contents;
  }
  & img {
    //      -webkit-user-drag: none;
    pointer-events: none;
    display: block;
    position: absolute;
    grid-column: doc;
    grid-row: 1/-1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: center;
  }

  & h2 {
    ${headline50}
  }

  & p {
    ${legend110}
    padding-block: .25rem 10rem;

    ${getMediaQuery("m")} {
      padding-block: 1rem 10rem;
    }
  }

  html.prefers-contrast & {
    & h2,
    & p {
      background: white;
      color: black !important;
    }
  }

  & input {
    opacity: 0;
    position: absolute;

    &.focus-visible + label button {
      outline: 3px solid rgb(var(--focus, 0 255 255) / 1);
      outline-offset: 0px !important;
    }
  }
`

const CardContent = styled.div`
  grid-column: main;
  grid-row: 2 / -1;
  color: rgb(var(--pure-white));
  .dark-theme & {
    color: rgb(var(--pure-white));
  }
  .light-theme & {
    color: rgb(var(--light-black));
  }

  position: relative;

  ${getMediaQuery("m")} {
    grid-column: col 1 / span 5;

    [dir="rtl"] & {
      grid-column: col -6 / span 5;
    }
  }
`

const ApplyButton = styled(Button)`
  position: relative;
  grid-row: 1/-1;
  grid-column: main;
  align-self: end;
  justify-self: center;
  margin-block-end: 5rem;
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`

export function LauncherCard(props) {
  const { ctx, image_cld, heading, paragraph, tech_code, param, index, theme } = props
  const { count, focus, W, dir } = useContext(ctx)
  const rfmain = useRef(null)

  function onFocus(v) {
    rfmain.current.setAttribute("aria-current", index === v)
  }
  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])

  const [ready, setReady] = useState(false)

  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.32, 0.94, 0.6, 1],
      },
    },
  }

  useLayoutEffect(() => {
    const img = rfmain.current.querySelector("img")
    if (!img || img?.complete) return onLoad()
    img.addEventListener("load", onLoad, { once: true })
    img.addEventListener("error", onLoad, { once: true })

    return () => {
      img.removeEventListener("load", onLoad, { once: true })
      img.removeEventListener("error", onLoad, { once: true })
    }

    function onLoad(e) {
      setReady(true)
    }
  }, [])

  return (
    <Card ref={rfmain} variants={variants} initial='hidden' animate={ready ? "visible" : "hidden"} className={theme?.theme ?? ""}>
      <Image {...image_cld} alt='' sizes='100vw' />
      <CardContent>
        <span>{tech_code}</span>
        <h2 id={`qv-content-${index}`}>{heading}</h2>
        <p dangerouslySetInnerHTML={{ __html: paragraph }} />
      </CardContent>
    </Card>
  )
}

const FREEZEDELAY = 200

function Apply(props) {
  const { ctx, Ctx } = props
  const { focus } = useContext(ctx)
  const { model, feature } = useContext(Ctx)
  const [active, setActive] = useState(0)
  //  const dictionary = useDictionary()

  function onClick(e) {
    // const rmcs = feature.get()?.split(",")
    model.set(feature.get()[focus.get()].rmc)
    feature.set(null)
  }

  function onFocus(v) {
    setActive(v)
  }
  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])

  return (
    <ApplyButton aria-describedby={`qv-content-${active}`} className='filled opaque-white-cfg' icon='none' onPress={onClick}>
      {/*dictionary.applyToWatch()*/ "Apply to watch"}
    </ApplyButton>
  )
}

function FeatureLauncherContents(props) {
  const { children, ctx, items, Ctx } = props
  const { count, focus, rmfocus, x, W, dir, frozen } = useContext(ctx)
  const rflist = useRef(null)
  const rffreezing = useRef(null)

  function onResize(isize) {
    W.set(isize)
  }
  useLayoutEffect(() => observeResize(rflist.current.parentNode, onResize), [])

  const { scrollX } = useScroll({
    container: rflist,
    axis: "x",
    offset: ["start end", "end end"],
  })

  const index = useTransform([scrollX, W], ([scrollX, W]) => {
    scrollX = Math.abs(scrollX)
    x.set(scrollX)
    return W === 0 ? -1 : (scrollX / W + 0.5) >> 0
  })

  function onIndex(v) {
    //    console.log("CFGL onIndex", v, rflastfocus.current)
    if (v < 0) return
    focus.set(v)
  }
  useLayoutEffect(() => mvSubscribe(index, onIndex), [])

  function updateScroll(v) {
    clearTimeout(rffreezing.current)
    rflist.current.scrollLeft = dir * v
    rffreezing.current = setTimeout(() => {
      freeze(false)
    }, FREEZEDELAY)
  }

  function freeze(v) {
    rflist.current.classList[v ? "add" : "remove"]("cfgf-straight")
    if (!v) frozen.set(false)
  }

  function onRmfocus(v) {
    if (v === null || W.get() === 0) return
    freeze(frozen.get())
    updateScroll(W.get() * v)
    rmfocus.set(null)
  }
  useLayoutEffect(() => mvSubscribe(rmfocus, onRmfocus), [])

  function onW(v) {
    if (v === 0 || rmfocus.get() === null) return
    updateScroll(rmfocus.get() * v)
    rmfocus.set(null)
  }
  useLayoutEffect(() => mvSubscribe(W, onW), [])

  return (
    <>
      <List ref={rflist} className='cfgf-straight'>
        {items.map((item, i) => (
          <Item key={`cfgf-feature-${i}`}>
            <LauncherCard ctx={ctx} index={i} {...item} />
          </Item>
        ))}
      </List>
      <Arrows ctx={ctx} />
      <Apply ctx={ctx} Ctx={Ctx} />
      <Pagination ctx={ctx} items={items?.map(v => v.heading)} />
      {children}
    </>
  )
}

const overlay_variants = {
  vanish: {
    opacity: 0,
    transition: {
      duration: 0.4,
      ease: [0.32, 0.94, 0.6, 1],
    },
  },
  appear: {
    opacity: 1,
    transition: {
      duration: 0.4,
      ease: [0.32, 0.94, 0.6, 1],
    },
  },
}

const main_variants = (startX, startY) => ({
  vanish: {
    opacity: 0,
    x: `${startX}%`,
    y: `${startY}%`,
    transition: {
      duration: 0.5,
      ease: [0.32, 0.94, 0.6, 1],
      opacity: {
        duration: 0.5,
        ease: [0.32, 0.94, 0.6, 1],
      },
    },
  },
  appear: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.32, 0.94, 0.6, 1],
      opacity: {
        duration: 0.6,
        ease: [0.32, 0.94, 0.6, 1],
      },
    },
  },
})

export default function FeatureLauncher(props) {
  const console = useConsole()
  const { Ctx, startX = 0, startY = 0 } = props
  const { feature, model } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)
  const rfmodal = useRef(null)
  const ctx = createContext(null)
  const [dir, setDir] = useState(0)
  const [touchenabled, setTouchenabled] = useState(-1)

  //console.log("••••• feature.get()", feature.get())

  // const rmcs = feature.get()?.split(",")
  const [items] = useState(
    feature.get()?.map((v, i) => Object.assign({}, CARDS[i], v))
    // CARDS.slice(0, feature.get()?.length).map((v, i) => {
    //   console.log("•••• v", v)

    //   const heading = v.heading
    //   heading.title = rmcs?.[i]
    //   return Object.assign({}, v, heading)
    // })
  )
  const scrollLock = useScrollLock()

  useLayoutEffect(() => {
    setTouchenabled(+isTouchEnabled())
    setDir(document.querySelector("html")?.getAttribute("dir") === "rtl" ? -1 : 1)
  }, [])

  useLayoutEffect(() => {
    const el = rfmodal.current
    scrollLock.lock(el)

    return () => {
      scrollLock.unlock(el)
    }
  }, [])

  function onFeature(v) {
    if (!v) setDir(0)
  }
  useLayoutEffect(() => mvSubscribe(feature, onFeature), [])

  return (
    <Modal role='dialog' aria-modal='true' aria-labelledby='' ref={rfmodal}>
      <AnimatePresence>
        {touchenabled >= 0 && dir !== 0 && (
          <>
            <Overlay variants={overlay_variants} initial='vanish' animate='appear' exit='vanish' key='qv-overlay' />
            <Main ref={rfmain} variants={main_variants(dir * startX, dir * startY)} initial='vanish' animate='appear' exit='vanish' key='qv-modal'>
              <FeatureProvider
                Ctx={ctx}
                count={items?.length}
                dir={dir}
                touchenabled={touchenabled}
                start={feature.get()?.findIndex(x => x.rmc === model.get())}
              >
                <FeatureLauncherContents ctx={ctx} items={items} {...props} />
              </FeatureProvider>
            </Main>
          </>
        )}
      </AnimatePresence>
    </Modal>
  )
}
