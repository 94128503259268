import { useEffect, useRef } from "react"
import { useScroll, AnimatePresence, motion } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import generateComponents, { parseModularBlock } from "utils/generateComponents"

import ScrollInvit from "components/scroll-invit/ScrollInvit"

import { StateProvider, useValue } from "./context"
import Pagination from "./Pagination"

import AshlarReturnVisitor from "./return-visitor/AshlarReturnVisitor"

const Root = styled.section`
  position: relative;
  background: #212121;
`

const PaginationContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: fit-content;
  top: 0;
  z-index: 10;
  pointer-events: none;
  transform: translateX(-50%);
  inset-inline-end: calc(var(--outer-margin) / 2);
`

function WallContent({ scrollinvit, children }) {
  const console = useConsole()

  const { currentIndex, targetSlide, input, prog, pagesCount } = useValue()

  const ref = useRef()
  const listRef = useRef()

  const { scrollYProgress } = useScroll({ target: ref, offset: ["start start", "end start"] })

  function onYp(y) {
    prog.set(y)
    currentIndex.set(input.get().findLastIndex(item => item <= y))
  }
  useEffect(() => scrollYProgress.onChange(onYp))

  function onTargetChange(i) {
    if (i === -1) return
    if (i === 0) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    } else {
      const el = document.getElementById(`ashlar--${i}`)
      const pos = i === 1 ? "center" : "start"
      el?.scrollIntoView({ behavior: "smooth", block: pos, inline: "nearest" })
    }
  }
  useEffect(() => targetSlide.onChange(onTargetChange))

  return (
    <Root ref={ref}>
      <AnimatePresence>
        {pagesCount > 1 ? (
          <PaginationContainer
            key='pagination'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "tween", duration: 0.7 }}
          >
            <Pagination pagesCount={pagesCount} />
          </PaginationContainer>
        ) : null}
      </AnimatePresence>
      <ul ref={listRef}>{children}</ul>
      {scrollinvit ? <ScrollInvit /> : null}
    </Root>
  )
}

export default function AshlarWall({ scrollinvit, ashlars, event_card }) {
  const console = useConsole()

  console.verbose("\n••••••••• AshlarWall", scrollinvit, ashlars, event_card)

  const modular = parseModularBlock(ashlars, { console }).map((item, i) => Object.assign(item, { index: i }))
  const blocks = generateComponents(modular, { console })
  blocks.splice(1, 0, <AshlarReturnVisitor key='ashlar-visitor' event_card={event_card} />)

  return (
    <StateProvider>
      <WallContent scrollinvit={scrollinvit}>{blocks}</WallContent>
    </StateProvider>
  )
}
