import { Helmet } from "react-helmet-async"
import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"

export default function HelmetConfig({ children, initialState }) {
  const console = useConsole()
  const locale = useLocale()
  const { default_title = "", title_template = "%s" } = initialState[locale.current.codes.www]?.meta ?? {}

  console.verbose("Helmet(%o)", { lang: locale.current.codes.www, dir: locale.current.dir, default_title, title_template })
  return (
    <>
      <Helmet defaultTitle={default_title} defer={false} titleTemplate={title_template} encodeSpecialCharacters={false}>
        <html lang={locale.current.codes.www} dir={locale.current.dir} />
      </Helmet>
      {children}
    </>
  )
}
