function updateLS(x) {
  localStorage.setItem("returnVisitor", JSON.stringify(x))
}

export function handleVisitor({ ...data }) {
  const type = data.type

  const rv = localStorage?.getItem("returnVisitor")

  if (!rv) {
    if (type === "wishlist" && data.list.length === 0) {
      //
    } else {
      updateLS([data])
    }
  } else {
    const arr = JSON.parse(rv)
    const a = arr.findIndex(obj => obj.type === type)
    if (a < 0) {
      arr.unshift(data)
    } else {
      arr.splice(a, 1)
      arr.unshift(data)
    }

    if (type === "wishlist") {
      if (data.list.length === 0) {
        const a = arr.findIndex(obj => obj.type === type)
        arr.splice(a, 1)
      }
    }

    if (arr.length) {
      updateLS(arr)
    } else {
      localStorage.removeItem("returnVisitor")
    }
  }
}
