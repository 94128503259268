import { sourceIndexes } from "../utils/source-types"

export const useSearchController = () => {
  const tabs = [
    {
      label: "all",
      contentType: "",
      defaultActive: false,
    },
    {
      label: "watches",
      contentType: sourceIndexes.watches,
      defaultActive: false,
    },
    {
      label: "articles",
      contentType: sourceIndexes.articles,
      defaultActive: false,
    },
    {
      label: "retailers",
      contentType: sourceIndexes.retailers,
      defaultActive: false,
    },
  ]

  return { tabs }
}
