import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import ConfigFilterItem from "./ConfigFilterItem"
import { useDictionary } from "contexts/Dictionary"

const FiltersContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: auto;
  grid-row: 1/-1;
  grid-column: 1/-1;
  align-self: start;
  margin-block-start: 2.75rem;
  mask-image: linear-gradient(90deg, rgb(0 0 0 / 0.2), rgb(0 0 0 / 1) 10%, rgb(0 0 0 / 1) 90%, rgb(0 0 0 / 0.2) 100%);

  ${getMediaQuery("m")} {
    margin-block-start: 3.45rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Filters = styled.ul`
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  border: none;
  padding: 0;
  padding-inline: var(--outer-margin);
  box-sizing: border-box;
  margin-block-end: 16px;

  ${getMediaQuery("m")} {
    margin-block-end: 24px;
  }
`

export default function ConfigFilters({ tabs, update, selected }) {
  const dictionary = useDictionary()

  return (
    <FiltersContainer>
      {tabs.length > 1 ? (
        <Filters aria-label={dictionary["filterWatchType"]()}>
          {
            tabs.map((t, i) => (
              <ConfigFilterItem key={`filter-item-${i}`} index={i} label={t.label} update={update} selected={selected} />
            ))
          }
        </Filters>
      ) : null
      }
    </FiltersContainer >
  )
}
