import styled from "@emotion/styled"
import { body50, light } from "css/text"

const Para = styled.p`
  ${body50}
  ${light}
  font-size: inherit;
  color: rgb(var(--dark-grey));
  margin-block-end: 20px;

  &.first-child {
    margin-block-start: 20vh;
  }

  &.last-child {
    margin-block-end: 10vh;
  }
`

export default function LegalFooter({ paragraph }) {
  return paragraph.map((p, i) => (
    <Para
      key={`footer-paragraph-${i}`}
      className={i === 0 ? "first-child" : i + 1 === paragraph.length && "last-child"}
      dangerouslySetInnerHTML={{ __html: p }}
    />
  ))
}
