import useConstant from "hooks/useConstant"
import { Provider } from "contexts/Console"

export const levels = {
  error: 0,
  warn: 1,
  ssr: 7, // "hide" from browser
  http: 7, // "hide" from browser
  info: 4,
  debug: 5,
  verbose: 6,
  silly: 7,
  default: 6,
}

export default function Console({ children, level: defaultLevel = process.env.LOG_LEVEL || levels.default, console = global.console }) {
  const proxy = useConstant(
    () =>
      new Proxy(console, {
        get(t, k) {
          const level = Number.isFinite(levels[k]) ? k : "default"

          if (levels[level] > levels[defaultLevel]) return Function.prototype
          if (typeof console[level] === "function") return (...args) => console[level](...args)
          return process.browser ? (...args) => console.log(...args) : (...args) => console.log(level, ...args)

          // if (!Number.isFinite(levels[k])) return console?.[k]
          // if (levels[k] > levels[level]) return Function.prototype
          // if (!process.browser) return (...args) => console.log(k === "log" ? "default" : k, ...args)
          // if (console[k]) return (...args) => console[k](...args)
          // return (...args) => console.log(...args)
        },
      })
  )

  proxy.verbose("Console(%o)", { level: defaultLevel })
  return <Provider value={proxy}>{children}</Provider>
}
