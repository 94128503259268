import { useEffect, useRef, useLayoutEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useFrameAnimation } from "./FrameAnimation"
import { fullGrid } from "css/grid"
import { headline50, legend110 } from "css/text"
import Button from "components/button/Button"
import getMediaQuery from "css/breakpoints"
import Image from "components/media/ImageCLD"
import { mvSubscribe, rafDelay } from "./utils"
import { useTransform, useMotionValue, motion } from "framer-motion"
import { useDictionary } from "contexts/Dictionary"
import { Card, CardContent, ReadMore, Div, TextWrapper, P, Overlay } from "./../config-launcher/ConfigLauncher"
import { LAUNCHER_FADE_DURATION, SWAP_IN, SWAP_OUT } from "./constants"

const Main = styled(Card)`
  background-color: #fff;
  ${SWAP_OUT}

  &.wv_reveal {
    ${SWAP_IN}
    transition-delay: 0ms, 0ms;
    transition-duration: 200ms, 0ms;
  }
`

export function CfgLauncherCard({ Ctx, ...props }) {
  const { image_cld, heading, theme } = props
  const { launched, wa, fetching, catalog } = useFrameAnimation(Ctx)
  //  console.log("LauncherCard", heading)
  const rfmain = useRef(null)
  const dictionary = useDictionary()
  const [animation, setAnimation] = useState(false)
  const rfcta = useRef(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
    if (state) {
      setTimeout(() => rfcta.current.focus(), 100)
    }
  }

  function onClick(e) {
    launched.set(true)
    wa()
  }

  function handleClick(e) {
    setAnimation(v => !v)
  }

  useLayoutEffect(() => {
    const img = rfmain.current.querySelector("img")
    if (!img || img?.complete) return onLoad()
    img.addEventListener("load", onLoad, { once: true })
    img.addEventListener("error", onLoad, { once: true })

    return () => {
      img.removeEventListener("load", onLoad, { once: true })
      img.removeEventListener("error", onLoad, { once: true })
    }

    function onLoad(e) {
      display(!launched.get())
    }
  }, [])

  function onLaunched(v) {
    if (!v) return
    display(false)
  }
  useEffect(() => mvSubscribe(launched, onLaunched), [])

  return (
    <Main
      ref={rfmain}
      className={theme?.theme ?? ""}
    >
      <Overlay animation={animation} onClick={() => setAnimation(false)} />
      <Image {...image_cld} alt='' sizes='100vw' quality='auto:good' />
      <CardContent>
        <Div animation={animation}>
          <TextWrapper animation={animation}>
            <h2>{heading?.title}</h2>
            <P animation={animation} dangerouslySetInnerHTML={{ __html: heading?.paragraph }} />
            <ReadMore onClick={handleClick} animation={animation}>
              {!animation ? dictionary.readMore() : dictionary.readLess()}
            </ReadMore>
          </TextWrapper>
        </Div>
        <Button ref={rfcta} className='filled opaque-white-cfg' icon='none' onPress={onClick}>
          {dictionary.configure()}
        </Button>
      </CardContent>
    </Main >
  )
}