import { useState, startTransition, useLayoutEffect } from "react"

let defaultState = true
export function disable() {
  defaultState = false
}

export default function useSSR() {
  const [SSR, setSSR] = useState(defaultState)

  useLayoutEffect(() => {
    if (SSR) startTransition(() => setSSR(false))
  })

  return SSR
}
