import { useEffect, useState, useRef, memo, useLayoutEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useTransform } from "framer-motion"

import { useEnv } from "contexts/Env"

import { handleVisitor } from "utils/handleVisitor"

import { useFrameAnimation, STEPS } from "./FrameAnimation"
import { rafDelay, mvSubscribe } from "./utils"
import { buildPicture } from "./canvas"

export function hashIt(s, replace) {
  //console.log("hashIt", s, replace)
  if (s === null) return
  const _hash = decodeURI(window.location.hash.split("").slice(1).join(""))
  if (!s.length) {
    window.history[!!replace ? "replaceState" : "pushState"]({}, "", window.location.href.replace(/#.*$/, ""))
    return
  }
  if (_hash !== s) {
    window.history[!!replace ? "replaceState" : "pushState"]({}, "", ["#", s].join(""))
  }
}

export function CfgHash({ Ctx }) {
  const env = useEnv()
  const { family, model, step, lastStep, getModel } = useFrameAnimation(Ctx)
  const rfnohash = useRef(null)

  const hash = useTransform([model, step], ([model, step]) => {
    if (!family || !model || step <= 0) return null
    let h = `cfg/${family}/${model}/${STEPS[step]}`
    return h
  })

  function onHash(v) {
    //console.log("onHash", v)
    if (v === null) return
    if (lastStep() && rfnohash.current) return
    hashIt(v)

    const port = buildPicture(getModel(model.get()), "side", env.catalogYear, 1)
    const land = buildPicture(getModel(model.get()), "side", env.catalogYear, 0)
    Object.assign(port, { metadata: { v7_type: ["portrait"] } })
    const pic = [port, land]
    const { color_set } = getModel(model.get())
    const obj = { type: "configurator", action: "resume", rmc: model.get(), step: v, bkg: color_set, picture: pic }
    handleVisitor(obj)
    const event = new StorageEvent("storage", obj)
    window.dispatchEvent(event)

    rfnohash.current = lastStep()
  }
  useEffect(() => mvSubscribe(hash, onHash), [])

  return null
}
