export const sliceResults = (results, limit = 9) => {
  if (!results || !Array.isArray(results) || !results.length) {
    return []
  }

  return results.slice(0, limit)
}

export const updateSearchList = () => {
  const searchString = new URLSearchParams(global?.location?.search).get("q")

  if (searchString) {
    if (localStorage?.getItem("searchList")) {
      const searchList = JSON.parse(localStorage?.getItem("searchList"))

      let includeString = false
      searchList.forEach(item => {
        if (item.localeCompare(searchString) === 0) {
          includeString = true

          return
        }
      })
      
      if (!includeString) {
        searchList.unshift(searchString)

        localStorage?.setItem("searchList", JSON.stringify(searchList.slice(0, 5)))
      }
    } else {
      localStorage?.setItem("searchList", JSON.stringify([searchString]))
    }
  }
}
