import { createContext, useContext } from "react"
import { useMotionValue } from "framer-motion"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"

export const Context = createContext()

export const RollerProvider = ({ slides, children }) => {
  const slide = useMotionValue(0)
  const targetSlide = useMotionValue(-1)

  function track({ event, customEvents, index, ctaName }) {
    waTriggerEvent({
      eventType: event,
      eventName: customEvents,
      returningVisitor: {
        category: slides[index].type,
        slidePosition: index + 1,
        ctaName: ctaName,
      },
    })
  }

  return <Context.Provider value={{ slide, targetSlide, track }}>{children}</Context.Provider>
}

export const useRoller = () => useContext(Context)
