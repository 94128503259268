import styled from "@emotion/styled"
import { Icon } from "components/icon/Icon"
import { buttonReset } from "css/buttons"
import { bold, legend100 } from "css/text"

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`

export const Button = styled.button`
  ${buttonReset}
  align-items: center;
  display: inline-flex;
  /* column-gap: 1ex; */

  > .flex {
    display: flex;
    flex-direction: column;
  }

  & > span {
    margin-inline-start: 0.5rem;
    ${bold}
    ${legend100}
  }

  svg {
    fill: rgb(var(--pure-white));
  }

  &.wishlist-button {
    transition: color 0.3s;
    color: rgb(var(--light-black));
    cursor: pointer;

    > svg {
      transition: fill 0.3s;
      fill: rgb(var(--green));
    }

    //specific demand => sepcific button styling
    @media (hover: hover) {
      :hover {
        color: rgb(var(--green));
      }
    }

    .dark-theme & {
      color: rgb(var(--pure-white));
      > svg {
        fill: rgb(var(--pure-white));
      }

      @media (hover: hover) {
        :hover {
          > svg {
            fill: rgb(var(--grey));
          }
          color: rgb(var(--grey));
        }
      }
    }
  }

  &.wishlist-button-variations {
    transition: color 0.3s;
    color: rgb(var(--green));
    cursor: pointer;

    > svg {
      transition: fill 0.3s;
      fill: rgb(var(--green));
    }

    //specific demand => sepcific button styling
    @media (hover: hover) {
      :hover {
        color: rgb(var(--light-black));

        > svg {
          transition: fill 0.3s;
          fill: rgb(var(--light-black));
        }
      }
    }

    .dark-theme & {
      color: rgb(var(--pure-white));
      > svg {
        fill: rgb(var(--pure-white));
      }

      @media (hover: hover) {
        :hover {
          > svg {
            fill: rgb(var(--grey));
          }
          color: rgb(var(--grey));
        }
      }
    }
  }

  .no-js & {
    display: none;
  }
`
export const WishlistIcon = styled(Icon)`
  // fill: #d4d4d4;
`

export const AriaHelper = styled.div`
  div:not(:focus):not(:active) {
    position: absolute !important;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const NewModel = styled.div`
  ${bold}
  ${legend100}
  margin-block-end: .25rem;
`
