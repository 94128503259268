import { forwardRef, useLayoutEffect, useState, useRef } from "react"
import { useReducedMotion } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"
import { useEnv } from "contexts/Env"

import Image from "components/media/ImageCLD"

export const cssContainer = `css-${hash("video:container")}`
export const cssVideo = `css-${hash("video:video")}`
export const cssPoster = `css-${hash("video:poster")}`
export const cssFallback = `css-${hash("video:fallback")}`
export const cssButton = `css-${hash("video:button")}`
export const cssIsReady = `css-${hash("video:state:is-ready")}`

const Container = styled.div`
  * {
    pointer-events: none;
  }

  display: grid;
  grid-template-areas: "video";
  overflow: hidden;
  position: relative;

  picture {
    grid-area: video;
  }

  img,
  video {
    height: auto;
    grid-area: video;
    width: 100%;
  }

  img {
    display: block;
  }

  video {
    object-fit: cover;
  }
`

function Video(props, ref) {
  const console = useConsole()

  const { autoPlay = null, fallback, loop = false, mime = "video/mp4", poster, preload = "metadata", sources, sizes } = props

  const env = useEnv()
  const rcontainer = useRef(null)

  const shouldReduceMotion = useReducedMotion()

  const { isMobile } = useViewport()

  const [src, setSrc] = useState(null)

  const onOrientationChange = bool => {
    if (sources.length < 2) return
    const orient = bool ? "portrait" : "landscape"
    setSrc(sources.find(({ media }) => media === orient).src)
  }

  useLayoutEffect(() => {
    const orient = isMobile.get() ? "portrait" : "landscape"
    setSrc(sources?.length > 1 ? sources.find(({ media }) => media === orient).src : sources[0]?.src)
    const unsub = isMobile.onChange(onOrientationChange)
    return () => unsub()
  }, [])

  // If url is valid, second argument will be ignored
  // and the url argument will be used as the base URL
  const url = new URL(src, env.content)

  return (
    <Container className={`${cssContainer}`} ref={rcontainer}>
      <Image {...poster} className={cssPoster} sizes={sizes} />
      <Image {...fallback} className={cssFallback} sizes={sizes} />
      {!shouldReduceMotion ? (
        <video
          autoPlay={autoPlay}
          className={cssVideo}
          controls={false}
          controlsList='nofullscreen nodownload'
          disablePictureInPicture
          disableRemotePlayback
          key={url} // force re-render the component on source change
          loop={loop}
          muted={true}
          playsInline
          preload={preload}
          ref={ref}
          tabIndex='-1' // non-controllable videos shouldn't be focusable (Firefox)
        >
          <source src={url} type={mime} />
        </video>
      ) : null}
    </Container>
  )
}

export default forwardRef(Video)
