export const ALWAYSCOMPLETE = process.browser && window.navigator.userAgent.toUpperCase().indexOf("MAC") >= 0 && !!window.mozInnerScreenX

export const SWAP_IN_DURATION = 400
export const SWAP_OUT_DURATION = 200

export const SWAP_IN = `
  transition: opacity ${SWAP_IN_DURATION}ms cubic-bezier(1,1,1,1) ${SWAP_OUT_DURATION}ms, visibility 0ms 0ms;
  opacity: 1;
  visibility: inherit;
`

export const SWAP_OUT = `
  transition: opacity ${SWAP_OUT_DURATION}ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms ${SWAP_OUT_DURATION}ms;
  opacity: 0;
  visibility: hidden;
//  will-change: opacity, visibility;
`

export const LOADING_TRESHOLD = 1000

export const WIDTH_CONSTRAINT = 1100

export const INSTALL_DURATION = 800
export const INSTALL_WAIT = SWAP_IN_DURATION
export const INSTALL_ECO_DELAY = 200
export const INSTALL_ECO_DURATION = 400
export const INSTALL_ECO_TRANSITION = `opacity ${INSTALL_ECO_DURATION}ms cubic-bezier(1,1,1,1) ${INSTALL_ECO_DELAY}ms, visibility 0ms 0ms`
export const EMPTY_STRING = "\u00A0"

export const SWAPPING_DELAY = SWAP_OUT_DURATION
export const LAUNCHER_FADE_DURATION = 600