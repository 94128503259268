import { useRef, useState } from "react"
import { motion, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useDictionary } from "contexts/Dictionary"

import { a11yOutline } from "css/a11y"
import getMediaQuery from "css/breakpoints"

import { useRoller } from "./context"

const PageContainer = styled.fieldset`
  z-index: 1;
  display: flex;
  gap: 1.5625rem;
  border: none;
  padding: 0;
  margin: 0;
  height: fit-content;
  position: sticky;
  top: 92vh;
  width: fit-content;
  margin: 0 auto;
  grid-row: 1;
  margin-block-start: -100vh;

  ${getMediaQuery("m")} {
    top: 90vh;
  }

  @media (prefers-contrast: more) {
    background: #fff;
    padding: 20px 10px;
  }
`

const PageGroup = styled.div`
  display: flex;
  align-items: center;
  & input.focus-visible + label {
    ${a11yOutline}
  }
`

const PageRadio = styled.input`
  width: 0;
  height: 0;
  appearance: none;
`

const Label = styled(motion.label)`
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: var(--color);
  border: none;
  padding: 0;
  pointer-events: all;
  @media (prefers-contrast: more) {
    background: black;
  }

  @media (hover: hover) and (pointer: fine) {
    &.unchecked:hover {
      cursor: pointer;
    }
  }

  &.unchecked::after {
    content: "";
    position: absolute;
    display: block;
    height: 250%;
    width: 250%;
    background: transparent;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const variants = {
  checked: { "--border-size": "1rem", "--color": "#212121" },
  unchecked: { "--border-size": "0.625rem", "--color": "#ccc" },
}

function Page({ id, index }) {
  const { slide, targetSlide } = useRoller()

  const ref = useRef()

  const [checked, setChecked] = useState(slide.get() === index)

  function onSlideChange(i) {
    setChecked(index === i)
  }
  useMotionValueEvent(slide, "change", onSlideChange)

  function onKeyDown(e) {
    if (e.code !== "Enter") return
    targetSlide.set(index)
  }

  return (
    <PageGroup>
      <PageRadio
        ref={ref}
        key={`page-${id}`}
        onKeyDown={onKeyDown}
        id={`page-${id}`}
        type='radio'
        name='lightbox-control'
        value={index}
        checked={checked}
        aria-checked={checked}
        readOnly
      />
      <Label
        htmlFor={`page-${id}`}
        variants={variants}
        initial={checked ? "checked" : "unchecked"}
        animate={checked ? "checked" : "unchecked"}
        className={checked ? "checked" : "unchecked"}
        transition={{ type: "tween", ease: "easeOut", duration: 0.35 }}
      />
    </PageGroup>
  )
}

export default function Pagination({ nb }) {
  const { slide, targetSlide, track } = useRoller()

  const ref = useRef()

  function onChange(e) {
    targetSlide.set(slide.get(), false)
    targetSlide.set(Number(e.target.value))

    track({
      event: "Click",
      customEvents: "returningVisitorClicked",
      index: Number(e.target.value),
      ctaName: "pin",
    })
  }

  return (
    <PageContainer ref={ref} onChange={onChange}>
      {Array.from(Array(nb)).map((_, i) => (
        <Page key={`page-${i.toString()}`} id={`page-${i}`} index={i} />
      ))}
    </PageContainer>
  )
}
