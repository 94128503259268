import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { kickerCN, pageHeaderCSS, subtitleCN } from "./style"

const Header = styled.header`
  ${pageHeaderCSS}

  &.gradient {
    background: ${props => (props.color ? `-webkit-linear-gradient(left, rgb(var(${props.color[0]})) 0%, rgb(var(${props.color[1]})) 100%)` : null)};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.gradient h1 {
    padding-block-end: 0.8rem;
  }
  &.gradient {
    margin-block-end: -0.8rem;
  }

  @media (prefers-contrast: no-preference) {
    &.gradient {
      background: var(--gradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  // A11y
  html.prefers-contrast & {
    color: black !important;

    a,
    button > span,
    button > em {
      color: black !important;
    }

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: -1;
    }
  }
`

export default function PageHeading({ heading, gradient, className }) {
  const console = useConsole()

  const { kicker, title, subtitle } = heading[0].classic || heading[0].date

  const colors = gradient?.colors ? gradient?.colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`) : []
  const hgradient = colors.length ? `linear-gradient(${gradient.angle ? `${gradient.angle}deg, ` : ""}${colors})` : ""

  return (
    <Header className={`page-title-block ${className}${hgradient ? " gradient" : ""}`} style={{ "--gradient": hgradient }}>
      {kicker && <p className={kickerCN}>{kicker}</p>}
      <h1>{title}</h1>
      {subtitle && <p className={subtitleCN}>{subtitle}</p>}
    </Header>
  )
}
