/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */
import { createContext, useContext, useEffect, useLayoutEffect, useState } from "react"
import { useMotionValue, useMotionValueEvent } from "framer-motion"

import { useConsole } from "contexts/Console"
import { cssVarExpansion, cssVarTransitionDelay } from "contexts/Header"
import { useLocale } from "contexts/Locale"
import { useEnv } from "contexts/Env"
import { useUser } from "contexts/User"

import { handleVisitor } from "utils/handleVisitor.js"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer.js"
import { getPriceDatas } from "components/price/utils"

export const ctx__ONLY_FOR_WISHLIST__ = {}
const Context = createContext(ctx__ONLY_FOR_WISHLIST__)

async function getModelData({ rmc, locale, env }) {
  return Promise.resolve(
    new URL(`/api/catalog/watches/${rmc}?language=${locale.current.codes.www}`, process.browser ? env.services : process.env.SERVICE_CATALOG_URL)
  )
    .then(url => new Request(url))
    .then(request => fetch(request))
    .then(response => response.json())
    .catch(err => err)
}

export const Provider = ({ children }) => {
  const console = useConsole()

  const locale = useLocale()
  const env = useEnv()
  const { countryCode } = useUser()

  const [wishlist, setWishlist] = useState(null)
  const [notificationRMC, setNotificationRMC] = useState(null)
  const [wishlistUpdateIndex, setWishlistUpdateIndex] = useState(0)

  const wl = useMotionValue("")

  function onWL(w) {
    setWishlist(JSON.parse(w) || [])
  }
  useMotionValueEvent(wl, "change", onWL)

  function updateList(l) {
    wl.set(l)
  }

  function updateFromStorage() {
    wl.set(localStorage.getItem("rmcWishList") || "[]")
  }

  useLayoutEffect(() => {
    // update context with wishlist in local storage
    // setWishlist(JSON.parse(localStorage.getItem("rmcWishList") || "[]") || [])
    updateList(localStorage.getItem("rmcWishList") || "[]")
    window.addEventListener("storage", updateFromStorage)
    return () => window.removeEventListener("storage", updateFromStorage)
  }, [])

  useEffect(() => {
    // if rmc parameter is present in the url, add it to the wishlist
    if (process.browser) {
      // get rmc parameter in the url
      const urlParams = new URLSearchParams(window.location.search)
      const rmcs = urlParams.get("rmc") ? urlParams.get("rmc").replace("(", "").replace(")", "").split(",") : []

      if (rmcs && rmcs.length > 0) {
        let newWishlist = []
        rmcs.forEach(rmc => {
          // if (wishlist.indexOf(rmc) === -1) { /** ??? */
          newWishlist.push(rmc)
          // }
        })

        if (newWishlist && newWishlist.length > 0) {
          // setWishlist(newWishlist)
          updateList(JSON.stringify(newWishlist))
          updateLocalStorage(newWishlist)
          setWishlistUpdateIndex(wishlistUpdateIndex + 1)
        }

        // remove rmc parameter from the url
        // window.history.replaceState({}, document.title, window.location.pathname)
      }
    }
  }, [])

  const updateWishlist = list => {
    // update wishlist in both context and local storage
    // setWishlist(list)
    updateList(JSON.stringify(list))
    updateLocalStorage(list)
    setWishlistUpdateIndex(wishlistUpdateIndex + 1)
    // window.dispatchEvent(new Event("wishlistUpdate"))
  }

  const triggerNotification = (rmc, action) => {
    // trigger notification for 3 seconds
    setNotificationRMC({ rmc, action })

    // set header visible
    document.documentElement.style.setProperty(cssVarExpansion, 1)
    document.documentElement.style.setProperty(cssVarTransitionDelay, "300ms")

    // clear previous timeout so the notification doesn't disappear too early
    clearTimeout(window.wishlistNotificationTimeout)
    window.wishlistNotificationTimeout = setTimeout(() => {
      setNotificationRMC(null)
    }, 3000)
  }

  const updateLocalStorage = list => {
    // update wishlist in local storage
    if (process.browser) localStorage.setItem("rmcWishList", JSON.stringify(list))
  }

  const toggleWishlist = async (rmc, family) => {
    // add or remove rmc from wishlist
    const { price, currency } = await getPriceDatas({ rmc, countryCode, locale, env })
    const modelData = await getModelData({ rmc, locale, env })
    const products = [
      {
        productCategory: modelData.familyNode.code,
        productModel: modelData.nameCode,
        productID: rmc,
        productQuantity: "1",
        productPrice: price,
        currency: currency,
      },
    ]

    if (wishlist.includes(rmc)) {
      const newWishlist = wishlist.filter(item => item !== rmc)
      updateWishlist(newWishlist)
      triggerNotification(rmc, "remove")

      handleVisitor({ type: "wishlist", list: newWishlist })

      // Tracking
      waTriggerEvent({
        eventName: "removeFromWishlist",
        eventType: "Click",
        products,
      })
    } else {
      const newWishlist = wishlist
      newWishlist.push(rmc)
      updateWishlist(newWishlist)
      triggerNotification(rmc, "add")

      handleVisitor({ type: "wishlist", list: newWishlist })

      // Tracking
      waTriggerEvent({
        eventName: "addToWishlist",
        eventType: "Click",
        products,
      })
    }
  }

  const isInWishlist = rmc => {
    // check if rmc is in wishlist
    return wishlist.includes(rmc)
  }

  // Events
  const onNotificationMouseEnter = () => {
    // clear timeout when hovering over notification
    clearTimeout(window.wishlistNotificationTimeout)
  }

  const onNotificationMouseLeave = () => {
    // trigger notification for 3 seconds
    window.wishlistNotificationTimeout = setTimeout(() => {
      setNotificationRMC(null)
    }, 3000)
  }

  return (
    <Context.Provider
      value={{ wishlist, toggleWishlist, isInWishlist, wishlistUpdateIndex, notificationRMC, onNotificationMouseEnter, onNotificationMouseLeave }}
    >
      {children}
    </Context.Provider>
  )
}

export function useWishlist() {
  return useContext(Context)
}
