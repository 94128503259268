import { useEffect, useRef } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"
import FocusTrap from "focus-trap-react"

import { useDictionary } from "contexts/Dictionary"
import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import useScrollLock from "hooks/useScrollLock"

import Button from "components/button/Button"

export const priceModal = `m${hash("price-modal")}`
export const weChatModal = `m${hash("we-chat-modal")}`
export const qrCodeModal = `m${hash("qr-code-modal")}`
export const a11yModal = `m${hash("a11y-modal")}`
export const langModal = `m${hash("lang-modal")}`
export const downloadModal = `m${hash("download-modal")}`
export const specTerms = `m${hash("spec-terms")}`

const Root = styled(motion.aside)`
  ${fullGrid}
  position: fixed;
  inset: 0;
  z-index: calc(var(--z-top, 1000));
  background-color: rgb(var(--light-black) / 0.8);
`

const Container = styled(motion.div)`
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: auto;
  grid-column: doc;
  z-index: 1;

  ${getMediaQuery("m")} {
    .${a11yModal} &,
    .${langModal} &,
    .${weChatModal} &,
    .${priceModal} &,
    .${downloadModal} &,
    .${qrCodeModal} & {
      grid-column: col 2 / col -2;
    }
  }

  ${getMediaQuery("l")} {
    .${langModal} &,
    .${weChatModal} &,
    .${qrCodeModal} & {
      grid-column: col 3 / col -3;
    }
  }
`

const Modal = styled(motion.div)`
  position: fixed;
  inset: 0;
  background-color: rgb(var(--pure-white));
  padding-inline: var(--grid-col-unit);
  will-change: transform;
  transform: translateY(0px);
  text-align: ${({ align }) => align || "initial"};
  max-height: 100vh;
  overflow: auto;

  pointer-events: none;

  display: flex;
  flex-direction: column;

  justify-content: center;

  ${getMediaQuery("m")} {
    width: 100%;
    position: relative;
    inset: unset;
    border-radius: 50px;
    padding-inline: 6.25rem;
  }
`

const Close = styled(Button)`
  --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--height) / 2);
  ${getMediaQuery("m")} {
    --spacing: var(--height);
  }
  pointer-events: all;
  position: absolute;
  top: var(--spacing);
  right: var(--spacing);
  [dir="rtl"] & {
    left: var(--spacing);
    right: auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  > svg {
    width: 16px;
    height: 16px;
  }
`

const Content = styled.div`
  overflow-y: auto;
  overflow: visible;
  padding-block: 6.25rem;
  scrollbar-width: none;
  pointer-events: all;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Backerlay = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
`

export default function ModalContainer({ onClose, className, modalHeading = "", modalContent = "", align, children }) {
  const console = useConsole()

  const dictionary = useDictionary()
  const scrollLock = useScrollLock()

  const modalRef = useRef()

  const onkeydown = e => {
    e.preventDefault()
    if (e.key === "Enter") onClose()
  }

  const onClickOutside = e => {
    e.stopPropagation()
    // If the click is outside the modal, close it
    const path = e.nativeEvent.composedPath ? e.nativeEvent.composedPath() : e.nativeEvent.path
    if (!path.includes(modalRef.current)) {
      onClose()
    }
  }

  const onClickCont = e => {
    e.stopPropagation()
  }

  useEffect(() => {
    const r = modalRef.current
    scrollLock.lock(r)
    return () => scrollLock.unlock(r)
  }, [])

  function onDragEnd(e, info) {
    if (info.velocity.y > 100) onClose()
  }

  return (
    <FocusTrap focusTrapOptions={{ onDeactivate: onClose }}>
      <Root
        initial={{ opacity: 0, backdropFilter: "blur(0px) opacity(0)", WebkitBackdropFilter: "blur(0px) opacity(0)" }}
        animate={{ opacity: 1, backdropFilter: "blur(4px) opacity(1)", WebkitBackdropFilter: "blur(4px) opacity(1)" }}
        exit={{ opacity: 0, backdropFilter: "blur(0px) opacity(0)", WebkitBackdropFilter: "blur(0px) opacity(0)" }}
        transition={{ duration: 0.2, ease: "easeOut" }}
        className={className}
      >
        <Backerlay onClickCapture={onClickOutside} />

        <Container
          onClick={onClickCont}
        // drag='y'
        // dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        // onDragEnd={onDragEnd}
        // dragElastic={{ bottom: 0.5 }}
        // dragTransition={{ bounceStiffness: 600, bounceDamping: 50 }}
        >
          <Modal
            initial={{ y: "20px" }}
            animate={{ y: "0px" }}
            exit={{ y: "-20px" }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            ref={modalRef}
            role='dialog'
            aria-modal='true'
            aria-labelledby={modalHeading}
            aria-describedby={modalContent}
            align={align}
          >
            <Content>{children}</Content>
            <Close aria-label={dictionary.popinClose()} onPress={onClose} icon='close' className='icon translucent-light' onKeyDown={onkeydown}>
              <span>{dictionary.popinClose()}</span>
            </Close>
          </Modal>
        </Container>
      </Root>
    </FocusTrap>
  )
}
