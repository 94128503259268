import { useConsole } from "contexts/Console"

import generateModularBlocks from "utils/generateModularBlocks"

import { Root, Heading, ContentRoot } from "./styles"

export default function LegalNotices({ heading, content }) {
  const console = useConsole()
  console.verbose("boom ~ LegalNotices ~ content", content)

  return (
    <Root>
      {heading.title && <Heading dangerouslySetInnerHTML={{ __html: heading.title }} />}
      <ContentRoot>{generateModularBlocks(content, { console })}</ContentRoot>
    </Root>
  )
}
