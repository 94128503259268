import { useContext } from "react"
import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"
import { Icon } from "components/icon/Icon"

const Arrow = styled.button`
  position: relative;
  grid-row: 1/-1;
  grid-column: 1/-1;
  align-self: center;
  --arrow-size: 40px;
  ${buttonIcon}
  ${translucentDark}

  pointer-events: auto;
  display: none;
  border: 0;
  border-radius: 50%;

  width: var(--arrow-size);
  height: var(--arrow-size);

  transform: translateX(0%);
  transition: transform .6s cubic-bezier(0.33, 1, 0.68, 1) 1.2s;

  &.previous {
    justify-self: start;
    inset-inline-start: calc((var(--outer-margin) - var(--arrow-size)) / 2);
  }
  &.next {
    justify-self: end;
    inset-inline-end: calc((var(--outer-margin) - var(--arrow-size)) / 2);
  }
  ${getMediaQuery("m")} {
    --arrow-size: 44px;
    display: flex;
  }

  &.previous svg {
    transform: scale(-1);
  }
  [dir="rtl"] &.previous svg {
    transform: scale(1);
  }
  [dir="rtl"] &.next svg {
    transform: scale(-1);
  }
`

export default function Arrows(props) {
  const { ctx } = props
  const { rmfocus, focus } = useContext(ctx)

  function update(v) {
    rmfocus.set(focus.get() + v)
  }

  function onPrevious(e) {
    return update(-1)
  }
  function onNext(e) {
    return update(1)
  }

  return (
    <>
      <Arrow className='previous' onClick={onPrevious} aria-hidden="true" tabIndex="-1">
        <Icon type={"moveNext"} />
      </Arrow>
      <Arrow className='next' onClick={onNext} aria-hidden="true" tabIndex="-1">
        <Icon type={"moveNext"} />
      </Arrow>
    </>
  )
}
