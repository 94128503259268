import styled from "@emotion/styled"
import { headline30 } from "css/text"
import getMediaQuery from "css/breakpoints"

const Title = styled.h2`
  ${headline30}
  margin-block-start: 5rem;
  margin-block-end: 2.5rem;

  ${getMediaQuery("m")} {
    margin-block-start: 6.875rem;
  }
`

export default function LegalHeading({ text }) {
  return <Title dangerouslySetInnerHTML={{ __html: text }} />
}
