import styled from "@emotion/styled"
import hash from "@emotion/hash"
import { css } from "@emotion/react"

import { Icon } from "components/icon/Icon"
import Link from "components/link/Link"

import { useConsole } from "contexts/Console"

import * as button from "css/buttons"
import * as text from "css/text"
import getMediaQuery from "css/breakpoints"

export const isBoldCN = `b${hash("is-bold:true")}`
export const isDarkCN = `d${hash("is-dark:true")}`
export const isInnerCN = `i${hash("is-inner:true")}`
export const horizontalAlign = {
  start: `a${hash("horizontal-align-start")}`,
  center: `a${hash("horizontal-align-center")}`,
  end: `a${hash("horizontal-align-end")}`,
}

export const fullWidthInnerStyle = css`
  position: relative;
  grid-row: 1;
  align-self: end;
  margin-block-end: 30px;
  width: 100%;

  &.${horizontalAlign.start} {
    grid-column: main-start / span 4;
  }
  &.${horizontalAlign.center} {
    grid-column: col 2 / span 4;
  }
  &.${horizontalAlign.end} {
    grid-column: col 3 / span 4;
  }

  ${getMediaQuery("m")} {
    &.${horizontalAlign.start} {
      grid-column: main-start / span 8;
    }
    &.${horizontalAlign.center} {
      grid-column: col 3 / span 8;
    }
    &.${horizontalAlign.end} {
      grid-column: col 5 / span 8;
    }
  }

  ${getMediaQuery("xl")} {
    &.${horizontalAlign.start} {
      grid-column: main-start / span 4;
    }
    &.${horizontalAlign.center} {
      grid-column: col 5 / span 4;
    }
    &.${horizontalAlign.end} {
      grid-column: col 9 / span 4;
    }
  }
`

export const innerStyle = css`
  position: relative;
  grid-row: 1;
  grid-column: 1;
  margin-block-end: 20px;
  height: fit-content;
  /* width: fit-content; */
  align-self: end;

  &.${horizontalAlign.start} {
    justify-self: start;
    margin-inline-start: 16.5%;
  }
  &.${horizontalAlign.center} {
    justify-self: center;
    inset: auto 0 0 0;
  }
  &.${horizontalAlign.end} {
    justify-self: end;
    margin-inline-end: 16.5%;
  }
`

export const innerStyle2 = css`
  position: relative;
  grid-row: 1;
  grid-column: 1;
  margin-block-end: 20px;
  height: fit-content;
  align-self: end;

  &.${horizontalAlign.start} {
    justify-self: start;
    // margin-inline-start: 16.5%;
  }
  &.${horizontalAlign.center} {
    justify-self: center;
    inset: auto 0 0 0;
  }
  &.${horizontalAlign.end} {
    justify-self: end;
    // margin-inline-end: 16.5%;
  }
`

export const suggestionCardStyle = css`
  ${innerStyle}
  margin-block: 0 3em;

  &.${horizontalAlign.start} {
    margin-inline-start: 3em;
  }
  &.${horizontalAlign.end} {
    margin-inline-end: 3em;
  }
`

const Caption = styled.figcaption`
  z-index: 1;
  color: rgb(var(--light-black));
  margin-block-start: 20px;

  &.${isDarkCN} {
    color: white;
  }

  .width-xl & {
    margin-inline: 20px;
  }

  &.${horizontalAlign.start} {
    text-align: start;
    justify-self: start;
  }
  &.${horizontalAlign.center} {
    text-align: center;
    justify-self: center;
  }
  &.${horizontalAlign.end} {
    text-align: end;
    justify-self: end;
  }
`

const Span = styled.span`
  display: block;
  ${text.legend100};
  &.${isBoldCN} {
    ${text.bold};
  }

  svg {
    width: 10px;
    height: 10px;
    margin-inline: 0.25rem;
  }
`

const LinkInline = styled(Link)`
  ${button.buttonReset};
  display: block;
`

export const entries = {
  normal_entry: NormalEntry,
  link_entry: LinkEntry,
}

function NormalEntry({ text, _metadata }) {
  const console = useConsole()

  console.verbose("•••• NormalEntry", text, _metadata)
  return text.map(({ content, is_bold }, i) => (
    <Span key={`${_metadata.uid}-${i}`} className={`${is_bold ? isBoldCN : ""}`} dangerouslySetInnerHTML={{ __html: content }} />
  ))
}

function LinkEntry({ text, href, is_external, _metadata }) {
  const console = useConsole()

  console.verbose("•••• LinkEntry", text, href, is_external, _metadata)
  return (
    <LinkInline href={href} icon='none'>
      {text.map(({ content, is_bold }, i) => (
        <Span key={`${_metadata.uid}-${i}`} className={`${is_bold ? isBoldCN : ""}`}>
          {content}
          <Icon type="chevron"/>
        </Span>
      ))}
    </LinkInline>
  )
}

export default function FigCaption({ caption, is_inner, is_dark_theme, horizontal_position }) {
  const console = useConsole()

  const childs = caption.map((props, i) => {
    const key = Object.keys(props)[0]
    const Entry = entries[key]
    return <Entry key={`${key}-${i}`} {...Object.values(props)[0]} />
  })

  const classCSS = `${horizontalAlign[horizontal_position]}${is_inner ? ` ${isInnerCN}` : ""}${is_dark_theme ? ` ${isDarkCN}` : ""}`

  console.verbose("\n•••••• FigCaption", is_dark_theme, is_inner, caption, horizontal_position)
  return <Caption className={classCSS}>{childs}</Caption>
}
