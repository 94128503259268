import { css } from "@emotion/react"

import getMediaQuery from "css/breakpoints"
import { fullWidth } from "css/box"

export const gridVars = css`
  --grid-gap: 6px;
  --grid-col-num: 6;

  ${getMediaQuery("m")} {
    --grid-gap: 8px;
    --grid-col-num: 12;
  }

  --grid-col-unit: calc(((100vw - var(--outer-margin) * 2) - (var(--grid-gap) * (var(--grid-col-num) - 1))) / var(--grid-col-num));

  /* WATCHES GRID */
  --watches-grid-width: calc((var(--grid-col-num) - 1) * var(--grid-gap) + var(--grid-col-num) * var(--grid-col-unit));
`

const baseStyle = css`
  display: grid;
  column-gap: var(--grid-gap);
`

export const grid = css`
  ${baseStyle}
  grid-template-columns: [col] repeat(var(--grid-col-num), [col] minmax(0, 1fr)) [col];
`

// eslint ignore
export const fullGrid = css`
  ${fullWidth}
  ${baseStyle}

  grid-template-columns:
    [doc-start]
    calc(var(--outer-margin) - var(--grid-gap))
    [main-start]
    repeat(var(--grid-col-num), [col] minmax(0, 1fr))
    [col main-end]
    calc(var(--outer-margin) - var(--grid-gap))
    [doc-end];
`
