import styled from "@emotion/styled"
import hash from "@emotion/hash"
import { motion, useTransform, transform } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import { headline100, surtitle100 } from "css/text"
import getMediaQuery from "css/breakpoints"
import { contrastMode } from "css/a11y"

export const kickerCN = hash("page-heading:kicker")
export const subtitleCN = hash("page-heading:subtitle")

const Hgroup = styled(motion.hgroup)`
  grid-row: title;
  text-align: center;
  color: white;
  padding-block-start: 40vh;
  padding-block-end: 60vh;

  display: grid;
  grid-template-rows: [kicker subtitle] min-content [mid-line] min-content [title];

  grid-column: col 1 / span 6;

  position: sticky;
  top: 0;

  /* height: fit-content; */

  & h2 {
    ${headline100}
  }

  ${getMediaQuery("m")} {
    grid-column: col 2 / span 10;
  }
  ${getMediaQuery("l")} {
    grid-column: col 3 / span 8;
  }

  & h2,
  & p {
    position: relative;
    ${contrastMode}
  }

  & .${kickerCN} {
    ${surtitle100}
    grid-row: kicker / mid-line;
  }

  & .${subtitleCN} {
    ${surtitle100}
    grid-row: subtitle / mid-line;
  }
`

export default function SubHeading({ kicker, title, subtitle, prog }) {
  const console = useConsole()

  const { height } = useViewport()

  const opacity = useTransform([prog, height], ([p, h]) => transform(p, [h * 0.1, h * 0.15, h * 0.5, h * 0.9], [0, 1, 1, 0]))

  return (
    <Hgroup className='subheading' style={{ opacity }}>
      {kicker && <p className={kickerCN}>{kicker}</p>}
      <h2 dangerouslySetInnerHTML={{ __html: title }} />
      {subtitle && <p className={subtitleCN}>{subtitle}</p>}
    </Hgroup>
  )
}
