import { css } from "@emotion/react"

export const a11yOutline = css`
  outline: 2px solid rgb(var(--focus, 0 255 255) / 1) !important;
  outline-offset: 3px !important;
`

export const a11y = css`
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  :is(a, button, summary, [role="button"]).focus-visible {
    &,
    .fake-link {
      ${a11yOutline}
    }
  }
`

export const contrastMode = css`
  html.prefers-contrast & {
    &:not(:empty):after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 30px);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      background: white;
      z-index: -1;
    }
    color: black;
  }
`
