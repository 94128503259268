import { useRef } from "react"

import { useConsole } from "contexts/Console"

import StackItem from "./StackItem"

export default function Stacker({ items }) {
  const console = useConsole()

  const ref = useRef()

  const covers = items.map(obj => Object.values(obj)[0])

  return (
    <nav ref={ref}>
      <ul>
        {covers.map((c, i, a) => (
          <StackItem key={`stack-item-${i}`} {...c} />
        ))}
      </ul>
    </nav>
  )
}
