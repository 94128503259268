import { forwardRef, useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import getMediaQuery, { breakpoint } from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"

import { Icon } from "components/icon/Icon"
import { useDictionary } from "contexts/Dictionary"

const Root = styled.nav`
  position: relative;
  display: grid;
  grid-area: 1/1;
`

const Track = styled.ul`
  display: flex;
  gap: var(--grid-gap);

  padding-block-end: 20px;

  grid-row: 1;
  grid-column: 1;

  overflow-x: auto;
  overflow-y: hidden;

  padding-inline-start: var(--outer-margin);

  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${getMediaQuery("m")} {
    padding-block-end: 50px;
  }
`

const Arrow = styled.button`
  --d: 40px;
  ${getMediaQuery("m")} {
    --d: 44px;
  }

  --s: -1;
  [dir="rtl"] & {
    --s: 1;
  }

  ${buttonIcon}
  ${translucentDark}

  pointer-events: all;
  display: flex;
  border: 0;
  border-radius: 50%;

  width: var(--d);
  height: var(--d);

  position: absolute;
  z-index: 1;
  inset-block-start: var(--o, 50%);
  transform: translateY(calc(var(--d) * -0.5)) scale(calc(var(--s) * 1));
  inset-inline: 2vw auto;

  &:nth-of-type(2) {
    inset-inline: auto 2vw;
    transform: translateY(calc(var(--d) * -0.5)) scale(calc(var(--s) * -1));
  }

  visibility: hidden;
  @media (any-pointer: fine) {
    &:not([hidden]) {
      visibility: visible;
    }
  }

  opacity: 0;
  pointer-events: none;
  .active > &.active {
    opacity: 1;
    pointer-events: auto;
  }

  transition: opacity 300ms;
`

function Roller({ children, active, ...props }, listRef) {
  const console = useConsole()
  const dictionary = useDictionary()
  const { outerMargin } = useViewport()

  const rtrack = listRef
  const rprev = useRef()
  const rnext = useRef()
  const ractives = useRef(new Set())
  const roffset = useRef(undefined)

  useLayoutEffect(() => {
    const track = rtrack.current
    const prev = rprev.current
    const next = rnext.current
    const elements = Array.prototype.slice.call(track.children)
    const actives = ractives.current

    const onscroll = e => {
      const width = track.clientWidth
      const scrollWidth = track.scrollWidth
      const start = Math.round(Math.abs(track.scrollLeft))
      const end = Math.round(scrollWidth - width - start)

      if (start < outerMargin.get()) prev.classList.remove("active")
      else prev.classList.add("active")

      if (end <= outerMargin.get()) next.classList.remove("active")
      else next.classList.add("active")
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          const index = elements.indexOf(entry.target)
          if (entry.intersectionRect.width / entry.boundingClientRect.width === 1) actives.add(index)
          else actives.delete(index)
        }),
      {
        threshold: 1,
      }
    )

    elements.forEach(element => observer.observe(element))

    track.addEventListener("scroll", onscroll)
    onscroll()
    return () => {
      track.removeEventListener("scroll", onscroll)
      elements.forEach(element => observer.unobserve(element))
      observer.disconnect()
    }
  })

  const seek = dir => () => {
    const track = rtrack.current
    const moveBy = global.matchMedia(`(min-width: ${breakpoint.m})`).matches ? 2 : 1
    const multiplier = document.documentElement.getAttribute("dir") === "ltr" ? 1 : -1

    if (dir === -1) {
      const activeIndex = Math.min(...[...ractives.current])
      const nextIndex = Math.max(0, activeIndex - 1)
      const node = track.children[nextIndex]
      track.scrollBy({ top: 0, left: multiplier * node.clientWidth * -moveBy, behavior: "smooth" })
    } else {
      const activeIndex = Math.max(...[...ractives.current])
      const nextIndex = Math.min(activeIndex + 1, track.children.length)
      const node = track.children[nextIndex]
      track.scrollBy({ top: 0, left: multiplier * node.clientWidth * moveBy, behavior: "smooth" })
    }
  }

  useLayoutEffect(() => {
    const track = rtrack.current
    const prev = rprev.current
    const next = rnext.current

    const onresize = () => {
      const li = track.querySelector("li")
      const paddingTop = parseFloat(getComputedStyle(li).getPropertyValue("padding-top") || "0px")
      const img = li.querySelector("img")
      roffset.current = `${paddingTop + (img?.clientHeight || 0) / 2}px`
      prev.style.setProperty("--o", roffset.current)
      next.style.setProperty("--o", roffset.current)
    }

    onresize()
    global.addEventListener("resize", onresize)
    return () => global.removeEventListener("resize", onresize)
  })

  useLayoutEffect(() => {
    const cur = rtrack.current.getElementsByClassName("current")
    if (!!cur[0] && active) {
      const ofsl = cur[0].parentNode.offsetLeft
      const center = rtrack.current.clientWidth / 2
      rtrack.current.scrollTo({
        top: 0,
        left: ofsl - center,
      })
    }
  }, [active])

  function onFocus(e) {
    const ind = Array.from(e.target.parentNode.parentNode.children).indexOf(e.target.parentNode)
    if (!ractives.current.has(ind)) {
      rtrack.current.scrollTo({
        top: 0,
        left: e.target.parentNode.offsetLeft,
        behavior: "smooth",
      })
    }
  }

  console.verbose("Menu:Sections:Roller(%o)", props)
  return (
    <Root className={`${active ? "active" : ""} dark-theme`}>
      <Arrow aria-label={dictionary.previous()} ref={rprev} tabIndex='-1' onClick={seek(-1)}>
        <Icon type={"moveNext"} />
      </Arrow>
      <Track ref={listRef} aria-labelledby={props["aria-labelledby"]} id={props.id} onFocus={onFocus}>
        {children}
      </Track>
      <Arrow aria-label={dictionary.next()} ref={rnext} tabIndex='-1' onClick={seek(1)}>
        <Icon type={"moveNext"} />
      </Arrow>
    </Root>
  )
}

export default forwardRef(Roller)
