import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"
import { opaqueGreen, buttonFilled } from "css/buttons"
import { useDictionary } from "contexts/Dictionary"

const SkipLinksContainer = styled.div`
  position: absolute;
  display: flex;
  top: 90vh;
  left: 50%;
  transform: translateX(-50%);
  outline: none 0px;
  z-index: calc(var(--z-top, 1000) + 1);
`

const Button = styled.button`
  ${opaqueGreen}
  ${buttonFilled}
  opacity: 0;
  pointer-events: none;
  &:focus-within {
    pointer-events: auto;
    opacity: 1;
  }
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: calc(var(--z-top, 1000) + 1);
`

export default function Skip(props, { className }) {
  const console = useConsole()
  const dictionary = useDictionary()

  const onskiptofooter = e => {
    e.preventDefault()
    let target = document.documentElement
      .querySelector("footer")
      .querySelector(
        `input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), object:not([disabled]), a[href], area[href], iframe, embed,[tabindex]:not([tabindex^="-"]), [contenteditable]`
      )
    if (!target) return
    target.scrollIntoView({
      behavior: "instant",
      block: "start",
      inline: "start",
    })

    target.focus({ focusVisible: true })
  }

  const onskiptocontent = e => {
    e.preventDefault()
    let target = document.documentElement
      .querySelector("main")
      .querySelector(
        `input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), object:not([disabled]), a[href], area[href], iframe, embed,[tabindex]:not([tabindex^="-"]), [contenteditable]`
      )
    if (!target) return
    target.scrollIntoView({
      behavior: "instant",
      block: "start",
      inline: "start",
    })
    target.focus({ focusVisible: true })
  }

  const skiptoa11y = e => {
    e.preventDefault()
    let target = document.documentElement.querySelector("#a11y").querySelector(`button:not([disabled])`)
    if (!target) return
    target.scrollIntoView({
      behavior: "instant",
      block: "start",
      inline: "start",
    })
    target.focus({ focusVisible: true })
  }

  console.verbose("Header:skip(%o)", props)
  return (
    <SkipLinksContainer id='skipLinks' className={className}>
      <Button id='skiptoa11y' onClick={skiptoa11y} aria-label={dictionary.skiptoa11y()}>
        {dictionary.skiptoa11y()}
      </Button>
      <Button id='skiptocontent' onClick={onskiptocontent} aria-label={dictionary.skipToContent()}>
        {dictionary.skipToContent()}
      </Button>
      {!props.nofooter && (
        <Button id='skiptofooter' onClick={onskiptofooter} aria-label={dictionary.skipToFooter()}>
          {dictionary.skipToFooter()}
        </Button>
      )}
    </SkipLinksContainer>
  )
}
