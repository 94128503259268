import { startTransition, useId, useLayoutEffect, useRef, useState } from "react"
import FocusTrap from "focus-trap-react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useHeader } from "contexts/Header"
import { ctx__ONLY_FOR_SEARCH__ as ctx, cssVarFocusIn, cssVarExpansion } from "contexts/Search"
import { cssVarHeight as cssVarHeaderHeight } from "contexts/Header"
import { useMenu } from "contexts/Menu"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { useDictionary } from "contexts/Dictionary"

import { SearchForm } from "./SearchForm"

const Root = styled.div`
  --fx: 400ms;
  --t: max(var(${cssVarFocusIn}, 0), var(${cssVarExpansion}, 0));
  --delay: calc((1 - var(--t, 0)) * 600ms);

  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding-block-start: var(${cssVarHeaderHeight});
  ${fullGrid}

  transform: translateY(calc(100% * var(--t) - var(${cssVarHeaderHeight}) * var(--t)));

  ${cssVarFocusIn}: 0;
  &:focus-within {
    ${cssVarFocusIn}: 1;
  }

  overflow-x: hidden;
  overflow-y: hidden;

  transition: transform var(--fx), height calc(var(--fx) * 0.1), opacity var(--fx), visibility var(--fx) var(--delay);
  will-change: transform, opacity;

  background: linear-gradient(90deg, #0b3e27, #197149);
`

const Wrapper = styled.form`
  display: block;
  box-sizing: border-box;
  // padding-block: 0.0625rem 5rem;
  grid-column: col 1 / span -1;

  ${getMediaQuery("m")} {
    // padding-block: 0.625rem 5rem;
    grid-column: col 1 / span -1;
  }
`

export default function SearchPane(props) {
  const console = useConsole()
  const header = useHeader()
  const dictionary = useDictionary()
  const rroot = useRef()
  const inputRef = useRef()
  const [active, setActive] = useState(false)
  const menu = useMenu()
  const id = useId()
  const marked4Focus = useRef()

  Object.assign(ctx, {
    pane: {
      activate: ({ keyboard }) =>
        startTransition(() => {
          header.lock(id)
          if (keyboard) menu.toggle.focus({ keyboard })
          else marked4Focus.current = true
          setActive(true)
        }),
      deactivate: ({ keyboard }) =>
        startTransition(() => {
          if (active) ctx.toggle.focus({ keyboard })
          setActive(false)
        }),
    },
  })

  useLayoutEffect(() => {
    if (!marked4Focus.current) return
    marked4Focus.current = false

    setTimeout(() => {
      inputRef.current.focus()
    }, 300)
  })
  useLayoutEffect(() => header.on(header.events.unlock, e => startTransition(() => ctx.pane.deactivate({ keyboard: e.keyboard }))))

  function close() {
    header.unlock(id)
  }

  console.verbose("Search:Pane(%o)", props)
  return (
    <Root
      ref={rroot}
      style={{
        [cssVarFocusIn]: +active,
        visibility: active ? "visible" : "hidden",
      }}
    >
      <FocusTrap active={active} containerElements={[header.ref.current].filter(Boolean)} focusTrapOptions={{ allowOutsideClick: true, onDeactivate: close }}>
        <Wrapper
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <SearchForm inputRef={inputRef} />
        </Wrapper>
      </FocusTrap>
    </Root>
  )
}
