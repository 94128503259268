import { useEffect, useState } from "react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"

import Progress from "./Progress"
import StoryTitle from "./text/StoryTitle"

const Head = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 20;

  width: calc(100% + var(--outer-margin) + var(--grid-gap) - 2.5rem / 2);

  grid-column: main-start / span 5;
  grid-row: head;
  align-self: start;

  ${getMediaQuery("m")} {
    grid-column: main-start / span 11;
    width: 100%;
  }
  ${getMediaQuery("l")} {
    grid-column: main;
  }
`

export default function Header({ currentTheme, titleSubtitle }) {
  const console = useConsole()

  const [theme, setTheme] = useState(currentTheme.get())

  function onThemeChange(t) {
    setTheme(t)
  }
  useEffect(() => currentTheme.onChange(onThemeChange))
  return (
    <Head className={theme}>
      <Progress />
      <StoryTitle titleSubtitle={titleSubtitle} />
    </Head>
  )
}
