import { useEffect, useRef, useLayoutEffect, useState } from "react"
import styled from "@emotion/styled"
import { useInstallAnimation, trueindex } from "./InstallAnimation"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { SWAP_IN, SWAP_OUT, INSTALL_WAIT, INSTALL_DURATION } from "./constants"
import getMediaQuery from "css/breakpoints"

const Main = styled.div`
  position: absolute;
  //  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //  transform-style: preserve-3d;
  aspect-ratio: 1 / 1;
  width: 100%;

  ${getMediaQuery("m")} {
    height: 100%;
    }

  ${SWAP_OUT}

    &.wv_reveal {
        ${SWAP_IN}
//        transition-delay: 0ms, 0ms;
    }

//    background: blue;

  & > canvas {
    display: block;
//    will-change: transform, opacity;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    aspect-ratio: 1 / 1;
//    opacity: .5;
//    background: rgba(0,255,0,.3);
  }
`

function sleep(d) {
    return new Promise(resolve => {
        setTimeout(resolve, d)
    })
}



export function CfgCanvas(props) {
    const { Ctx, ictx } = props
    const { step, model, mobile, resized, updateStep, settled, launched } = useFrameAnimation(Ctx)
    const { PARAMS, canvasctx, frames, clearFrames } = useInstallAnimation(ictx)
    const rfmain = useRef()
    const rfcanvas = useRef()
    const rfpreload = useRef(new Image())
    const lastdrawn = useRef(null)
    let trans = useRef(null).current
    let size = useRef(null).current
    let play = useRef(null).current
    let stop = useRef(null).current
    let wait = useRef(0).current
    let start = useRef(0).current

    function display(state) {
        rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
    }

    function onComplete() {
        if (settled.get() < 0) return
        updateStep()
        settled.set(1)
    }

    async function render() {
        //        console.trace("renderrender")
        display(true)
        //        if (!ghosted) {
        const spent = Date.now() - start
        const delay = (wait > 0 ? wait : (INSTALL_DURATION / stop)) - spent
        await sleep(delay)
        if (play >= stop) return onComplete()
        wait = 0
        start = Date.now()
        paintindex(play++)
    }

    function paintindex(v) {
        let tindex = trueindex(trans.i(v))
        let frame = frames.current?.get(tindex)
        //        console.log("paintindex", frame)
        let src = frame?.src
        if (!src || lastdrawn.current === src) return draw()
        size = frame?.size || 0
        if (!size) return
        rfpreload.current.src = lastdrawn.current = src
    }

    function move(v) {
        let scale = trans.s(v)
        let x = trans.x(v)
        let y = trans.y(v)
        //        const alpha = trans.a(v).toFixed(2)
        if (!rfmain.current) return
        let sfactor = [rfmain.current.offsetHeight, rfmain.current.offsetWidth][mobile.get()]
        let rh = sfactor * scale
        scale = rh / size
        x *= scale
        y *= scale
        x -= 50
        y -= 50
        //        rfcanvas.current.style.opacity = alpha
        rfcanvas.current.style.transform = `translate3d(${x}%,${y}%,0) rotate(${trans.r(v)}deg) scale(${scale})`
    }

    function draw(e) {
        rfcanvas.current.width = rfcanvas.current.height = size
        canvasctx.current.drawImage(e?.target || rfpreload.current, 0, 0, size, size)
        move(play)
        window.requestAnimationFrame(render)
    }

    function onModel(v) {
        if (!v) return
        play = stop = 0
        start = Date.now()
        paintindex(0)
    }
    useLayoutEffect(() => mvSubscribe(model, onModel, false), [])

    function onSettled(v) {
        if (v !== 0) return
        wait = INSTALL_WAIT
        play = 1
        stop = PARAMS.range
        start = Date.now()
        window.requestAnimationFrame(render)
    }
    useLayoutEffect(() => mvSubscribe(settled, onSettled, false), [])

    function onMobile(v) {
        if (v < 0) return
        //        if (rfcanvas.current.width === 0) rfcanvas.current.width = rfcanvas.current.height = QUALITYSIZE.S.hi[v] * window.devicePixelRatio
        trans = PARAMS.trans[v]
    }
    useLayoutEffect(() => mvSubscribe(mobile, onMobile), [])
    /*
        function onResized() {
            if (!trans) return
            draw({ target: rfpreload.current })
        }
        useLayoutEffect(() => mvSubscribe(resized, onResized), [])
    */
    useLayoutEffect(() => {
        canvasctx.current = rfcanvas.current.getContext("2d", { alpha: true })
        rfpreload.current.addEventListener("load", draw, false)
        rfpreload.current.addEventListener("error", draw, false)

        return () => {
            rfpreload.current.removeEventListener("load", draw, false)
            rfpreload.current.removeEventListener("error", draw, false)
        }
    }, [])

    return (
        <Main ref={rfmain}>
            <canvas ref={rfcanvas} width='0' height='0' />
        </Main>
    )
}