import styled from "@emotion/styled"
import * as button from "css/buttons"
import getMediaQuery from "css/breakpoints"
import Quancast from "../analytics/QuantCast"

const Button = styled.button`
  ${button.buttonFilled}
  ${button.opaqueGreen}
  display: flex;
  margin: 0 auto;
  margin-block-start: 6.25rem;

  ${getMediaQuery("m")} {
    margin-block-start: 11.25rem;
  }
`

export default function LegalButton({ label, aria_label }) {
  return (
    <Button onClick={() => process.browser && window.__tcfapi("displayConsentUi", 2, function () {})} aria-label={aria_label}>
      {label}
    </Button>
  )
}
