import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Env"
import { useLocale } from "contexts/Locale"

export default function Env({ children, initialState }) {
  const console = useConsole()
  const locale = useLocale()

  const hostname = initialState.env.hostname
  const cn = locale.defaultLocale.codes.www === "zh-hans"
  const development = process.env.NODE_ENV === "development"
  const production = !development
  const staging = production && hostname.endsWith("staging.com")
  const uat = production && hostname.endsWith("uat.com")
  const live = production && (hostname.endsWith("rolex.com") || hostname.endsWith("rolex.cn") || hostname.endsWith("rolex.de") || hostname.endsWith("prod.com"))

  const { sharing, uri } = initialState[locale.current?.codes.www]

  const ctx = Object.assign({}, { cn, development, live, production, sharing, staging, uat, uri }, initialState.env ?? {})
  ctx.services ??= process.browser && global.location.origin // can be a specific url, OR should be set by the nav instead of the server to make development easier

  // if (process.browser) global.console.log("env %o", ctx)
  console.verbose("Env(%o)", ctx)
  return <Provider value={ctx}>{children}</Provider>
}
