import { useEffect, useState } from "react"
import { motion, useAnimationControls } from "framer-motion"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"
import { useMenu } from "contexts/Menu"
import { useViewport } from "contexts/Viewport"
import { useNavigation } from "contexts/Navigation"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"
import { bold, headline30, legend110, normal } from "css/text"

import { statesLabel } from "components/menu/Pane"
import Roller from "components/menu/Roller"
import { Icon } from "components/icon/Icon"
import { cssActive } from "components/menu/cssVars"
import Grid from "components/menu/Grid"

const isLastCL = `a${hash("is-last")}`
const isToolCL = `a${hash("is-tool")}`

const Root = styled(motion.div)`
  display: block;
  box-sizing: border-box;
  grid-area: 1 / 1 / 3 / 1;

  ${fullGrid}

  padding-block-start: 2rem;
  ${getMediaQuery("m")} {
    padding-block: 3.75rem 2rem;
    grid-area: 1 / 1;
  }

  transition: visibility 360ms;
`

const itemsStyles = css`
  all: unset;
  cursor: pointer;
  ${bold}
  color: rgb(var(--pure-white));

  display: inline-flex;

  margin-block-end: 0.75rem;
  &.${isLastCL} {
    margin-block-end: 2rem;
  }

  ${headline30}

  &.${isToolCL} {
    ${legend110}
    ${normal}
  }

  &:lang(ar),
  &:lang(fa),
  &:lang(he),
  &:lang(hi),
  &:lang(ja),
  &:lang(ko),
  &:lang(th),
  &:lang(vi),
  &:lang(zh-hant),
  &:lang(zh-hans) {
    line-height: 1.143;
  }

  &.${cssActive} {
    color: rgb(var(--pure-white));
  }

  transition: color 300ms;

  ${getMediaQuery("m")} {
    &.${isLastCL} {
      margin-block-end: 2.625rem;
    }
  }

  &.current {
    color: rgb(var(--ocean-green) / 1);
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--ocean-green) / 1);
    }
  }
`

const Label = styled.a`
  ${itemsStyles}
`

const ButtonLabel = styled.button`
  ${itemsStyles}
  & svg {
    align-self: center;
    margin-inline-start: 0.5rem;
  }
`

const List = styled(motion.ul)`
  grid-column: main;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  z-index: 1;

  ${getMediaQuery("m")} {
    grid-column: main-start / span 4;
  }

  ${getMediaQuery("l")} {
    grid-column: main-start / span 3;
  }

  & li:last-of-type a {
    padding-block-end: 0;
  }

  &:lang(ja) {
    word-break: keep-all;
  }
`

export default function LinkSections({ links_section, watches }) {
  const console = useConsole()

  const { isMobile } = useViewport()
  const navigation = useNavigation()
  const menu = useMenu()

  const [isMob, setIsMob] = useState(isMobile.get())

  const anim = useAnimationControls()

  const sections = links_section.reduce(
    (acc, group) =>
      acc.concat(
        group.items.reduce(
          (a, o, j, ar) =>
            a.concat([
              {
                link: o.link,
                isLast: j === ar.length - 1,
                tool_link: group.tool_link,
                switch_to_button: o.switch_to_button,
                toggleLabel: o.switch_to_button ? watches.title : null,
              },
            ]),
          []
        )
      ),
    []
  )

  function onClick() {
    menu.switch(statesLabel.rollergrid)
  }

  function onMob(bool) {
    setIsMob(bool)
    anim.start("show")
  }
  useEffect(() => isMobile.onChange(onMob))

  const navigate = e => {
    e.preventDefault()
    navigation.navigate(e.currentTarget.getAttribute("href"))
  }

  function onStateChange(state) {
    if (state === statesLabel.opened) {
      anim.start("show")
    } else if (state === statesLabel.closed) {
      anim.start("hide")
    } else if (state === statesLabel.rollergrid) {
      anim.start("showgrid")
    } else if (state === statesLabel.closegrid) {
      anim.start("closegrid")
    }
  }
  useEffect(() => menu.currentState.onChange(onStateChange))

  const listVariants = {
    hide: { opacity: 0, pointerEvents: "none", transition: { duration: 0.08 } },
    show: { opacity: 1, pointerEvents: "all", transition: { delay: 0.35, duration: 0.08 } },
    showgrid: { x: "-100%", pointerEvents: "none", transition: { duration: 0.3, ease: "linear" } },
    closegrid: { x: "0%", pointerEvents: "all", transition: { duration: 0.3, ease: "linear" } },
  }

  return (
    <Root variants={listVariants} animate={anim} initial='hide'>
      <List>
        {sections.map((item, i, a) => {
          let active = item.link.href === navigation.slug

          // dirty hack to highlight store locator link even with country in url... bad bad...
          if (navigation.slug.split("/").filter(Boolean).includes("store-locator")) {
            active = item.link.href === "/store-locator"
          }
          return (
            <motion.li key={`menu-section-${i}`}>
              {item.switch_to_button && !!item.toggleLabel && isMob ? (
                <ButtonLabel onClick={onClick}>
                  {item.toggleLabel}
                  <Icon type='chevron' />
                </ButtonLabel>
              ) : (
                <Label
                  href={item.link.href ? navigation.localize(item.link.href) : null}
                  onClick={navigate}
                  className={`${item.isLast ? isLastCL : ""}${item.tool_link ? ` ${isToolCL}` : ""}${active ? " current" : ""}`}
                >
                  {item.link.label}
                </Label>
              )}
            </motion.li>
          )
        })}
      </List>
      {isMob ? <Grid {...watches} /> : <Roller {...watches} />}
    </Root>
  )
}
