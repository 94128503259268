import { useEffect, useState } from "react"
import { animate, motion, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { headline70, surtitle50 } from "css/text"

const Hgroup = styled(motion.hgroup)`
  margin-block-start: 20px;
  align-self: start;

  .dark-theme & {
    color: rgb(var(--pure-white));
  }
  .light-theme & {
    color: rgb(var(--light-black));
  }
`

const Title = styled.h2`
  ${headline70}
  align-self: start;
  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`
const Kicker = styled.p`
  ${surtitle50}
  align-self: start;
  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

export default function StoryTitle({ titleSubtitle }) {
  const console = useConsole()

  const opacity = useMotionValue(1)

  const [title, setTitle] = useState(titleSubtitle.get().split(":")[0])
  const [kicker, setKicker] = useState(titleSubtitle.get().split(":")[1])

  function onCh(txt) {
    const prevSplited = titleSubtitle.prev.split(":")
    const splited = txt.split(":")

    if (!prevSplited[0] && !prevSplited[1]) {
      setTitle(splited[0])
      setKicker(splited[1])
      return
    }
    if (txt === titleSubtitle.prev) return
    function switchTxt() {
      setTitle(splited[0])
      setKicker(splited[1])
      animate(opacity, 1, { duration: 0.15, ease: "linear" })
    }

    animate(opacity, 0, { duration: 0.15, ease: "linear", onComplete: switchTxt })
  }
  useEffect(() => titleSubtitle.onChange(onCh))

  return kicker || title ? (
    <Hgroup style={{ opacity }}>
      {kicker ? <Kicker>{kicker}</Kicker> : null}
      {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
    </Hgroup>
  ) : null
}
