import { useEffect, useLayoutEffect, useRef } from "react"
import { motion, useAnimationControls, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"
import { useMenu } from "contexts/Menu"
import { useDictionary } from "contexts/Dictionary"

import { buttonIcon, translucentDark } from "css/buttons"
import getMediaQuery, { breakpoint } from "css/breakpoints"
import { headline50 } from "css/text"

import { statesLabel } from "components/menu/Pane"
import { ButtonStyled } from "components/button/Button"
import { cssFixed } from "components/menu/cssVars"
import { Icon } from "components/icon/Icon"
import Card, { cardsVariants } from "components/menu/Card"

const Root = styled(motion.div)`
  position: relative;
  display: none;
  grid-column: main;
  grid-row: 1/1;

  overflow: hidden;

  padding-inline-start: 10px;
  margin-inline-start: -10px;

  ${getMediaQuery("m")} {
    display: grid;
    position: relative;
    margin-block-end: 5.25rem;
    grid-column: col 6 / doc-end;
  }

  ${getMediaQuery("l")} {
    grid-column: col 5 / doc-end;
  }
`

const Title = styled(motion.h2)`
  ${headline50}
  align-self: end;
  margin-block-end: 30px;
`

const Track = styled.ul`
  overflow-x: auto;
  overscroll-behavior-x: contain;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  display: grid;
  gap: var(--grid-gap);

  grid-area: 1/1;

  height: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${getMediaQuery("m")} {
    grid-template-columns: unset;
    grid-auto-flow: column;
    grid-auto-columns: calc(var(--grid-col-unit) * 5 + var(--grid-gap) * 4);

    & > li {
      width: 100%;
      scroll-snap-align: start;

      &:last-of-type {
        padding-inline-end: var(--outer-margin);
      }
    }

    &.${cssFixed} {
      overflow-x: hidden;
    }

    & > li {
      scroll-snap-align: start;
      scroll-margin: unset;

      &:first-of-type {
        margin-inline-start: unset;
      }
    }
  }

  ${getMediaQuery("l")} {
    grid-auto-columns: calc(var(--grid-col-unit) * 4 + var(--grid-gap) * 3);
  }

  // to prevent cards focus outline to be cut :
  padding-block-start: 10px;
  margin-block-start: -10px;
  padding-block-end: 10px;
  margin-block-end: -10px;
  //////////////////////////////////////////////
`

const Arrow = styled(motion(ButtonStyled))`
  ${getMediaQuery("m")} {
    display: flex;
  }

  --s: -1;
  [dir="rtl"] & {
    --s: 1;
  }

  ${buttonIcon}
  ${translucentDark}

  pointer-events: all;
  border: 0;
  border-radius: 50%;

  grid-area: 1/1;

  z-index: 1;
  transform: translateY(calc(var(--height) * -0.5)) scale(calc(var(--s) * 1));

  justify-self: start;

  margin-inline: calc(var(--outer-margin) / 2 - var(--height) / 2 - var(--grid-gap)) 0;

  &:nth-of-type(2) {
    justify-self: end;
    inset-inline: auto 2vw;
    transform: translateY(calc(var(--height) * -0.5)) scale(calc(var(--s) * -1));
    margin-inline: 0 calc(var(--outer-margin) / 2 - var(--height) / 2 - var(--grid-gap));
  }

  visibility: hidden;
  @media (any-pointer: fine) {
    &:not([hidden]) {
      visibility: visible;
    }
  }

  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  transition: opacity 300ms;
`

const RollerContainer = styled.div`
  display: grid;
  overflow-y: auto;
  overflow-x: visible;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${getMediaQuery("m")} {
    overflow-y: visible;
  }
`

export default function Roller({ cards, cta_configure, last_card, title, ...props }) {
  const console = useConsole()

  const dictionary = useDictionary()
  const { outerMargin, isMobile } = useViewport()
  const menu = useMenu()

  const rtrack = useRef()
  const rprev = useRef()
  const rnext = useRef()
  const ractives = useRef(new Set())
  const roffset = useRef(undefined)
  const contref = useRef()

  const anim = useAnimationControls()

  const marginBlockStart = useMotionValue(0)

  function updateArrows() {
    const track = rtrack.current
    const prev = rprev.current
    const next = rnext.current

    const width = track.clientWidth
    const scrollWidth = track.scrollWidth
    const start = Math.round(Math.abs(track.scrollLeft))
    const end = Math.round(scrollWidth - width - start)

    if (start <= outerMargin.get()) prev.classList.remove("active")
    else prev.classList.add("active")

    if (end <= outerMargin.get()) next.classList.remove("active")
    else next.classList.add("active")
  }

  useLayoutEffect(() => {
    const track = rtrack.current
    const elements = Array.prototype.slice.call(track.children)
    const actives = ractives.current

    const onscroll = e => {
      updateArrows()
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          const index = elements.indexOf(entry.target)
          if (entry.intersectionRect.width / entry.boundingClientRect.width === 1) actives.add(index)
          else actives.delete(index)
        }),
      {
        threshold: 1,
      }
    )

    elements.forEach(element => observer.observe(element))

    track.addEventListener("scroll", onscroll)
    onscroll()
    return () => {
      track.removeEventListener("scroll", onscroll)
      elements.forEach(element => observer.unobserve(element))
      observer.disconnect()
    }
  }, [])

  const seek = dir => () => {
    const track = rtrack.current
    const moveBy = global.matchMedia(`(min-width: ${breakpoint.m})`).matches ? 2 : 1
    const multiplier = document.documentElement.getAttribute("dir") === "ltr" ? 1 : -1

    if (dir === -1) {
      const activeIndex = Math.min(...[...ractives.current])
      const nextIndex = Math.max(0, activeIndex - 1)
      const node = track.children[nextIndex]
      track.scrollBy({ top: 0, left: multiplier * node.clientWidth * -moveBy, behavior: "smooth" })
    } else {
      const activeIndex = Math.max(...[...ractives.current])
      const nextIndex = Math.min(activeIndex + 1, track.children.length)
      const node = track.children[nextIndex]
      track.scrollBy({ top: 0, left: multiplier * node.clientWidth * moveBy, behavior: "smooth" })
    }
  }

  useLayoutEffect(() => {
    const track = rtrack.current

    const onresize = () => {
      const li = track.querySelector("li")
      const img = li.querySelector("img")
      roffset.current = `${(img?.clientHeight || 0) / 2}px`
      marginBlockStart.set(roffset.current)
    }

    onresize()
    global.addEventListener("resize", onresize)
    return () => global.removeEventListener("resize", onresize)
  })

  function resetTrack() {
    rtrack.current.scrollTo(0, 0)
    contref.current.scrollTo(0, 0)
  }

  function hide() {
    rprev.current.classList.remove("active")
    rnext.current.classList.remove("active")
    anim.start("hide").then(() => resetTrack())
  }

  function onStateChange(state) {
    if (state === statesLabel.opened) {
      resetTrack()
      updateArrows()
      anim.start("show")
    } else if (state === statesLabel.closed) {
      hide()
    }
  }
  useEffect(() => menu.currentState.onChange(onStateChange))

  const rootVariants = {
    hide: { pointerEvents: "none", transition: {} },
    show: { pointerEvents: "all", transition: { staggerChildren: 0.02, delayChildren: 0.35 } },
  }

  const variantsTitle = {
    hide: { opacity: 0, transition: { duration: 0.08 }, transitionEnd: { visibility: "hidden" } },
    show: { opacity: 1, visibility: "visible", transition: { duration: 0.2 } },
  }

  console.verbose("Menu:Sections:Roller(%o)", props)
  return (
    <Root className={`${props.className} dark-theme`} variants={rootVariants} animate={anim} initial={isMobile.timeDelta > 0 ? "" : "hide"}>
      <Title variants={variantsTitle}>{title}</Title>
      <RollerContainer ref={contref}>
        <Arrow aria-label={dictionary.previous()} ref={rprev} tabIndex='-1' onClick={seek(-1)} style={{ marginBlockStart }}>
          <Icon type={"moveNext"} />
        </Arrow>
        <Track ref={rtrack} className={props.className} aria-labelledby={props["aria-labelledby"]} id={props.id}>
          {cards.map((card, i, a) => (
            <Card key={card._metadata.uid} {...card} meta={{ index: i, reverseIndex: a.length - i }} cardsVariants={cardsVariants.desk} />
          ))}
        </Track>
        <Arrow aria-label={dictionary.next()} ref={rnext} tabIndex='-1' onClick={seek(1)} style={{ marginBlockStart }}>
          <Icon type={"moveNext"} />
        </Arrow>
      </RollerContainer>
    </Root>
  )
}
