import { useEffect, useLayoutEffect, useState } from "react"
import { createPortal } from "react-dom"
import { AnimatePresence } from "framer-motion"
import styled from "@emotion/styled"
import useTopDomain from "hooks/useTopDomain"

import { useConsole } from "contexts/Console"
import { useLocale } from "contexts/Locale"
import { useNavigation } from "contexts/Navigation"
import { useUser } from "contexts/User"
import { useDictionary } from "contexts/Dictionary"
import { useEnv } from "contexts/Env"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { headline70, legend100, bold } from "css/text"

import ModalContainer, { langModal } from "components/modal/ModalContainer"
import { Icon } from "components/icon/Icon"

import useReducedMotion from "hooks/useReducedMotion"
import useContrast from "hooks/useContrast"

const Root = styled.div`
  ${fullGrid}
  background: rgb(var(--light-grey));
  padding-block: 1.875rem 1.25rem;
`

const LanguageBlock = styled.div`
  position: relative;
  display: grid;
  grid-column: main;
  grid-row-gap: 0.5rem;
  margin-block-start: -0.5rem;

  ${getMediaQuery("s", { max: true })} {
    grid-row-gap: 2rem;
    grid-template-rows: repeat(2, minmax(0, max-content));
  }
`

const Icp = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;

  > a {
    ${legend100}
    text-decoration: none;
    pointer-events: auto;
    color: currentColor;
    transition: color 0.3s;
    @media (hover: hover) {
      :hover {
        color: rgb(var(--green));
      }
    }
  }
`

const Button = styled.button`
  ${legend100}
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  gap: 1ex;
  line-height: 1.375;
  padding: 0.5rem;
  margin: 0 -0.5rem 0;
  transition: color 0.3s;
  grid-area: 1/-1;
  width: fit-content;

  * {
    pointer-events: none;
  }

  svg {
    height: 1rem;
    width: 1rem;

    ${getMediaQuery("m")} {
      height: 1.125rem;
      width: 1.125rem;
    }
  }

  @media (hover: hover) {
    :hover {
      color: rgb(var(--green));
    }
  }
`

const LanguageContainer = styled.div`
  display: grid;
  position: relative;
`

const Title = styled.h2`
  ${headline70}
  display: block;
  margin-block-end: 2.5rem;

  ${getMediaQuery("m")} {
    margin-block: 3.75rem;
  }

  em {
    font-style: normal;
    color: rgb(var(--green));
    display: block;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1ex;

  ${getMediaQuery("m")} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Link = styled.a`
  ${bold}
  align-items: center;
  color: rgb(var(--light-black));
  display: inline-flex;
  gap: 0.5rem;
  text-decoration: none;
  transition: color 0.3s;

  [dir="rtl"] &:not([dir="rtl"]) {
    flex-direction: row-reverse;
  }

  @media (hover: hover) {
    :hover {
      color: rgb(var(--green));
    }
  }

  .active & {
    color: rgb(var(--green));
    cursor: default;

    // bullet
    ::after {
      background-color: currentColor;
      border-radius: 50%;
      content: "";
      height: 0.5rem;
      width: 0.5rem;
    }
  }
`

const TogglesContainer = styled.div`
  ${legend100}
  grid-row: 1;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: flex-end;
  gap: 1rem;

  ${getMediaQuery("s", { max: true })} {
    grid-row: 2;
    flex-direction: column;
    justify-self: flex-start;
    margin-inline-start: 0;
    padding: 0;
    margin-block-end: 20px;
  }
`

const ToggleButton = styled.button`
  height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: transparent;
  padding-inline: 0;
  cursor: pointer;
  > * {
    transition:
      color 0.3s,
      background-color 0.3s;
  }

  > em {
    margin-inline-start: 10px;
    font-style: inherit;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      > em {
        color: rgb(var(--green));
      }
      > span {
        background-color: rgb(var(--green));
      }
    }
  }
  .first {
    margin-inline-start: 0;
  }

  color: inherit;
  html.prefers-reduced-motion &.motion,
  html.prefers-contrast &.contrast {
    color: rgb(var(--green));
  }
`

const Toggle = styled.span`
  display: flex;
  position: relative;
  width: 37px;
  min-width: 37px;
  height: 20px;
  align-items: center;
  border-radius: 50px;
  background-color: black;
  transition:
    color 0.3s,
    background-color 0.3s;

  & > span {
    transform: translateX(0);
  }

  html.prefers-reduced-motion .motion > &,
  html.prefers-contrast .contrast > & {
    background-color: #006139;
    & > span {
      transform: translateX(100%);
    }
  }

  html[dir="rtl"].prefers-reduced-motion .motion > &,
  html[dir="rtl"].prefers-contrast .contrast > & {
    & > span {
      transform: translateX(-100%);
    }
  }

  > span {
    position: absolute;
    width: calc(50% - 2px);
    height: calc(100% - 3px);
    border-radius: 9999px;
    margin-inline: 2px;
    background-color: rgb(var(--pure-white));
    transition:
      transform 300ms ease-in-out,
      backgroundColor 300ms ease-in-out;
  }
`

function A11yToggle() {
  const topDomain = useTopDomain()
  const maxAge = 60 * 60 * 24 * 3
  const dictionary = useDictionary()
  const reducedMotion = useReducedMotion()
  const contrast = useContrast()

  const setCookie = () => {
    const mask =
      (document.documentElement.classList.contains("prefers-contrast") ? 1 : 0) +
      (document.documentElement.classList.contains("prefers-reduced-motion") ? 2 : 0)
    document.cookie = `__a11y=${mask}; path=/; domain=${topDomain}; max-age=${maxAge};SameSite=Strict; Secure`
  }

  const handleMotion = () => {
    document.documentElement.classList.toggle("prefers-reduced-motion")
    setCookie()
  }

  const handleConstrast = () => {
    document.documentElement.classList.toggle("prefers-contrast")
    setCookie()
  }

  useLayoutEffect(() => {
    const motionmq = global.matchMedia("(prefers-reduced-motion: reduce)")
    const contrastmq = global.matchMedia("(prefers-contrast: more)")
    const onchange = () => {
      if (document.cookie.match("__a11y=([0-3])")) return /** if settings is set by the user, user's setting wins */

      if (motionmq.matches) document.documentElement.classList.add("prefers-reduced-motion")
      else document.documentElement.classList.remove("prefers-reduced-motion")
      if (contrastmq.matches) document.documentElement.classList.add("prefers-contrast")
      else document.documentElement.classList.remove("prefers-contrast")
    }

    motionmq.addEventListener("change", onchange)
    contrastmq.addEventListener("change", onchange)
    return () => {
      motionmq.removeEventListener("change", onchange)
      contrastmq.removeEventListener("change", onchange)
    }
  })

  return (
    <TogglesContainer id='a11y'>
      <ToggleButton role='switch' aria-checked={reducedMotion} className='motion first' onClick={handleMotion}>
        <Toggle>
          <span />
        </Toggle>
        <em>{dictionary.reduceMotion()}</em>
      </ToggleButton>
      <ToggleButton role='switch' aria-checked={contrast} className='contrast' onClick={handleConstrast}>
        <Toggle>
          <span />
        </Toggle>
        <em>{dictionary.reduceContrast()}</em>
      </ToggleButton>
    </TogglesContainer>
  )
}

export default function ControlPanel({ label, title, firstlinkicp, secondlinkicp }) {
  const console = useConsole()

  const locale = useLocale()
  const navigation = useNavigation()
  const user = useUser()
  const dictionary = useDictionary()
  const env = useEnv()

  const currentLocale = locale?.current.label

  const [isLangModalOpen, setIsLangModalOpen] = useState(false)

  const setLocaleAndNavigate = e => {
    e.preventDefault()
    user.locale = e.target.getAttribute("lang")
    onCloseLangModal()
    requestAnimationFrame(() => navigation.navigate(e.target.href))
  }

  const onCloseLangModal = () => {
    setIsLangModalOpen(false)
  }

  const onOpenLangModal = e => {
    e.preventDefault()
    setIsLangModalOpen(true)
  }

  console.verbose("ControlPanel(%o)", { label, title })
  return (
    <Root>
      <LanguageBlock>
        <Button onClick={onOpenLangModal} aria-label={currentLocale + " - " + dictionary.ariaSwitchLanguage()}>
          <Icon type='language' />
          {label}
        </Button>
        <A11yToggle />

        {env.cn && (
          <Icp>
            <a href={firstlinkicp?.external} target='_blank' rel='noopener noreferrer'>
              {firstlinkicp?.label}
            </a>
            <a href={secondlinkicp?.external} target='_blank' rel='noopener noreferrer'>
              {secondlinkicp?.label}
            </a>
          </Icp>
        )}
      </LanguageBlock>
      {process.browser &&
        createPortal(
          <AnimatePresence>
            {isLangModalOpen && (
              <ModalContainer onClose={onCloseLangModal} className={langModal} modalHeading='modal-heading'>
                <LanguageContainer>
                  <Title id='modal-heading' dangerouslySetInnerHTML={{ __html: title }} />
                  <List aria-label={dictionary.ariaLanguages()}>
                    {locale.locales.map(item => {
                      const isCurrent = item.codes.www === locale.current.codes.www
                      let href =
                        env.cn && item.external
                          ? new URL(navigation.localize(navigation.slug, item.codes.www), "https://rolex.com").href
                          : navigation.localize(navigation.slug, item.codes.www)

                      if (process.browser && global.location.search) href += global.location.search

                      return (
                        <li key={item.codes.www} className={isCurrent ? "active" : null}>
                          <Link
                            href={href}
                            lang={item.codes.www}
                            dir={item.dir}
                            onClick={setLocaleAndNavigate}
                            target='_self'
                            rel='noopener'
                            aria-current={isCurrent || null}
                          >
                            {item.label}
                          </Link>
                        </li>
                      )
                    })}
                  </List>
                </LanguageContainer>
              </ModalContainer>
            )}
          </AnimatePresence>,
          document.body
        )}

      {/* {state.isOpen && (
        <OverlayContainer>
          <Modal isOpen onClose={state.close} isDismissable>
            <Close ref={closeButtonRef} {...closeButtonProps} aria-label={dictionary.popinClose()}>
              <Icon type='close' />
            </Close>
          </Modal>
        </OverlayContainer>
      )} */}
    </Root>
  )
}
