import { useState, startTransition, useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"
import { useHeader } from "contexts/Header"
import { ctx__ONLY_FOR_HEADER__ as ctx } from "contexts/Header"

import getMediaQuery from "css/breakpoints"

const cssVarT = `--${hash("header:overlay:t")}`

const Root = styled.div`
  position: fixed;
  z-index: calc(var(--z-top, 1000) - 2);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: calc(1 * var(${cssVarT}));
  background: rgb(0 0 0 / calc(0 + 0.8 * var(${cssVarT})));
  transition: background-color 300ms, opacity 300ms;

  @supports (backdrop-filter: blur(1px)) OR (-webkit-backdrop-filter: blur(1px)) {
    ${getMediaQuery("m")} {
      opacity: unset !important;
      backdrop-filter: blur(calc(var(${cssVarT}) * 0.618ex));
      transition: height 1ms calc((1 - var(${cssVarT})) * 600ms), backdrop-filter 300ms calc((1 - var(${cssVarT})) * 250ms),
        background-color 300ms calc((1 - var(${cssVarT})) * 250ms);
    }
  }
`

export default function Overlay(props) {
  const console = useConsole()
  const header = useHeader()
  const [active, setActive] = useState(false)
  const customDeactivationHandler = useRef(() => Function.prototype)

  const deactivate = () => {
    customDeactivationHandler.current?.()
    header.forceUnlock()
  }
  useLayoutEffect(() => header.on(header.events.lock, () => startTransition(() => setActive(true))))
  useLayoutEffect(() => header.on(header.events.unlock, () => startTransition(() => setActive(false))))
  useLayoutEffect(() => header.on(header.events.willunlock, () => startTransition(() => setActive(false))))

  Object.assign(ctx, {
    overlay: {
      activate: (deactivateHandler = Function.prototype) =>
        startTransition(() => {
          customDeactivationHandler.current = deactivateHandler
          setActive(true)
        }),
      deactivate: () =>
        startTransition(() => {
          customDeactivationHandler.current = Function.prototype
          setActive(false)
        }),
    },
  })

  console.verbose("Header:Overlay(%o)", { active })
  return <Root style={{ pointerEvents: active ? "auto" : "none", [cssVarT]: +active }} onClick={deactivate} />
}
