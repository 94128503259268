import { useConsole } from "contexts/Console"
import { usePage } from "contexts/Page"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { useNavigation } from "contexts/Navigation"
import { useLocale } from "contexts/Locale"
import { useEnv } from "contexts/Env"

export default function Meta({ children, initialState }) {
  const console = useConsole()
  const location = useLocation()
  const page = usePage()
  const locale = useLocale()
  const navigation = useNavigation()
  const env = useEnv()

  let href = new URL(location.pathname, `https://${env.hostname}`).href
  if (href[href.length - 1] === "/") href = href.slice(0, -1)

  const title = page?.meta?.title
  const description = page?.meta?.description
  const image = page?.meta?.image
  const searchimage = page?.meta?.searchimage
  const ogtitle = page?.meta?.title_og || page?.meta?.title
  const ogdescription = page?.meta?.description_og || page?.meta?.description
  const ogurl = href
  const ogimage = page?.meta?.image_og_cld && new URL(page?.meta?.image_og_cld, `https://${env.cloudinary.secureDistribution}`)
  const ogsearchimage = page?.meta?.searchimage_og_cld && new URL(page?.meta?.searchimage_og_cld, `https://${env.cloudinary.secureDistribution}`)
  const ogtype = initialState?.[locale.current.codes.www]?.meta?.type_og
  const ogsitename = initialState?.[locale.current.codes.www]?.meta?.sitename_og
  const searchSection = page?.meta?.search_section
  const searchTitle = page?.meta?.search_title

  const canonical =
    !["wishlist page"].includes(page?.page_type) && // some pages do not have a canonical ?
    (() => {
      switch (locale.current.codes.www) {
        case "zh-hans": {
          // if zh-hans, force .cn with no locale code in url
          const url = new URL(href)
          url.hostname = "www.rolex.cn"
          url.pathname = url.pathname.replace(/^\/zh-hans/, "")
          return url.href
        }
        // case "zh-hant": { /** zh-hant is not present anymore on rolex.cn */
        //   // if zh-hant, force .cn
        //   const url = new URL(href)
        //   url.hostname = "rolex.cn"
        //   return url.href
        // }
        default:
          return href
      }
    })()

  const alternates =
    !env.cn && locale.current.codes.www == "zh-hans" // No alternates for zh-hans on .com, only canonical (pointing to .cn)
      ? []
      : [
        { hrefLang: "X-default", code: locale.defaultLocale.codes.www },
        { hrefLang: "en-cn", code: "en", external: env.cn ? false : true },
        ...[
          ...locale.locales.map(({ codes: { www: code }, external }) => ({ hrefLang: code, code, external })),
          ...(locale.externals ?? []).map(({ codes: { www: code }, external }) => ({ hrefLang: code, code, external })),
        ],
      ].map(({ hrefLang, code, external }) => {
        const pathname = navigation.localize(navigation.slug, code)
        const origin = env.cn && external ? "https://www.rolex.com" : `https://${env.hostname}` // TODO cn parity with current env

        let href
        try {
          href = new URL(pathname, origin).href
        } catch {
          href = new URL(pathname, "https://www.rolex.com").href
        }

        if (!env.cn && (hrefLang == "zh-hans" || hrefLang == "en-cn")) {
          const url = new URL(href)
          url.hostname = "www.rolex.cn"
          if (hrefLang == "zh-hans") url.pathname = url.pathname.replace(/^\/zh-hans/, "")
          if (hrefLang == "en-cn") url.pathname = "/en" + url.pathname
          href = url.href
        }

        if (env.cn && hrefLang == "en") {
          const url = new URL(href)
          url.hostname = "www.rolex.com"
          url.pathname = url.pathname.replace(/^\/en/, "")
          href = url.href
        }

        if (href[href.length - 1] === "/") href = href.slice(0, -1)

        return { hrefLang, href }
      })

  console.verbose("Meta(%o)", {
    title,
    description,
    canonical,
    image,
    searchimage,
    ogtitle,
    ogdescription,
    ogurl,
    ogimage,
    ogsearchimage,
    ogtype,
    ogsitename,
    alternates,
    page,
  })
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description || ""} />
        <meta name='image' content={image || ""} />
        <meta name='searchimage' content={searchimage || ""} />
        {canonical && <link rel='canonical' href={canonical} />}
        {ogsitename && <meta name='og:sitename' content={ogsitename} />}
        {ogtype && <meta name='og:type' content={ogtype} />}
        {ogtitle && <meta name='og:title' content={ogtitle} />}
        {ogdescription && <meta name='og:description' content={ogdescription} />}
        {ogurl && <meta name='og:url' content={ogurl} />}
        {ogimage && <meta name='og:image' content={ogimage} />}
        {ogsearchimage && <meta name='og:searchimage' content={ogsearchimage} />}
        {searchSection && <meta name='search_section' content={searchSection} />}
        {searchTitle && <meta name='search_title' content={searchTitle} />}
        {alternates.map(({ hrefLang, href }) => (
          <link key={hrefLang} rel='alternate' hrefLang={hrefLang} href={href} />
        ))}
      </Helmet>
      {children}
    </>
  )
}
