import { useConsole } from "contexts/Console"
import { Provider } from "contexts/Suspender"
import { Helmet } from "react-helmet-async"

export default function Suspender({ children, store = new Map() }) {
  const console = useConsole()

  const ctx = (params, resolver, ssr = false) => {
    const id = (Array.isArray(params) ? params : [params]).filter(param => typeof param === "string").join(":")

    if (!store.has(id)) {
      if (ssr && process.browser && global.__suspenders?.[id]) {
        store.set(id, {
          done: true,
          result: global.__suspenders?.[id],
          promise: Promise.resolve(),
        })
      } else {
        const promise = resolver()

        if (typeof promise.then !== "function") {
          store.set(id, {
            done: true,
            result: promise,
            promise: Promise.resolve(),
          })
        } else {
          store.set(id, {
            done: false,
            result: null,
            promise: promise
              .catch(err => (console.error(err), null))
              .then(result => {
                const suspender = store.get(id)
                suspender.done = true
                suspender.result = result
              }),
          })
        }
      }
    }

    const suspender = store.get(id)
    console.silly("suspender:get(%s) <= %o", id, suspender)
    if (!suspender.done) {
      console.verbose("xxy suspender not done %s", id)
      throw suspender.promise
    }

    try {
      return [
        suspender.result,
        ssr ? (
          <Helmet>
            <script type='application/javascript'>
              {!process.browser && `window.__suspenders = window.__suspenders || {}; window.__suspenders["${id}"] = ${JSON.stringify(suspender.result)};`}
            </script>
          </Helmet>
        ) : null,
      ]
    } catch (err) {
      console.error(err)
      return [suspender.result, null]
    }
  }

  console.silly("Suspender(%o)", { store })
  return <Provider value={ctx}>{children}</Provider>
}
