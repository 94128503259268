import { useEffect, useRef, useLayoutEffect, useState } from "react"
import { useInstallAnimation, QUALITYSIZE } from "./InstallAnimation"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { useEnv } from "contexts/Env"
import { LAUNCHER_FADE_DURATION } from "./constants"

function trueIndex(v) {
    return v.toString().padStart(3, "0")
}

function initSourceCld(path3d, quality, secureDistribution, catalogYear) {
    return `https://${secureDistribution}/image/upload/${quality}/v1/catalogue/${catalogYear}/360/${path3d}/${path3d}--`
}

function qualityPathCld(s, q) {
    return `w_${s}/q_${q}`
}

function fetchRmc(rmc, path3d, loframes, hiframes, catalogYear, mobile, secureDistribution) {
    let q = Array.from({ length: 2 }).map((v, i) => qualityPathCld(QUALITYSIZE.S.lo[i], QUALITYSIZE.Q.lo[i]))
    const lopath = initSourceCld(path3d, q[mobile], secureDistribution, catalogYear)
    q = Array.from({ length: 2 }).map((v, i) => qualityPathCld(QUALITYSIZE.S.hi[i], QUALITYSIZE.Q.hi[i]))
    const hipath = initSourceCld(path3d, q[mobile], secureDistribution, catalogYear)
    let lodata = loframes.map(v => ({
        rmc: rmc,
        path: `${lopath}${trueIndex(v)}.webp`,
        index: v,
        size: QUALITYSIZE.S.lo[mobile],
    }))
    let hidata = hiframes.map(v => ({
        rmc: rmc,
        path: `${hipath}${trueIndex(v)}.webp`,
        index: v,
        size: QUALITYSIZE.S.hi[mobile],
    }))

    return hidata.concat(lodata)
}

function loadBlob(aborts) {
    return function (path) {
        const controller = new AbortController()
        aborts.push(controller)
        return (
            fetch(new Request(path), {
                signal: controller.signal
            })
                .then(r => r.status === 200 && r.blob())
                .then(URL.createObjectURL)
                .catch(e => null)
        )
    }
}

const CCR = 25

export function CfgPreload(props) {
    const { Ctx, ictx } = props
    const { fetching, getModel, mobile, model, startrmc, settled, readyCanvas, launched } = useFrameAnimation(Ctx)
    const { frames, clearFrames, PARAMS } = useInstallAnimation(ictx)
    const rfaborts = useRef([])
    const env = useEnv()
    const { cloudinary: { secureDistribution } } = env
    let loaded = useRef(false).current

    async function preload(v) {
        if (v) return
        await kill()
        const m = getModel(startrmc)
        const { loframes, hiframes, range } = PARAMS
        //        console.log("PARAMS", loframes, hiframes, range)
        const all = fetchRmc(m.rmc, m.path3d, loframes.split(","), hiframes.split(","), env.catalogYear, mobile.get(), secureDistribution)
        //        console.log("all", all)
        await load(true)
        model.set(startrmc)

        for (let i = 0; i < CCR; i++) {
            load()
        }

        async function load(once) {
            if (!all.length) return
            let { rmc, path, index, size } = all.shift()
            size = frames.current.size > 0 ? size : size * 2//window.devicePixelRatio
            const bloburl = await loadBlob(rfaborts.current)(path)
            frames.current.set(+index, {
                src: bloburl,
                size: size,
            })
            if (frames.current.size >= range) {
                loaded = true
                return settled.set(launched.get() && readyCanvas.get() ? 0 : -1)
            }
            if (!once) load()
        }
    }
    useEffect(() => mvSubscribe(fetching, preload, false), [])

    function onReadyCanvas(v) {
        if (!v) return
        settled.set(launched.get() && loaded ? 0 : -1)
    }
    useEffect(() => mvSubscribe(readyCanvas, onReadyCanvas, false), [])

    function onLaunched(v) {
        settled.set(v && loaded && readyCanvas.get() ? 0 : -1)
    }
    useEffect(() => mvSubscribe(launched, onLaunched), [])

    async function kill() {
        await clearFrames()
        return new Promise(resolve => {
            rfaborts.current.forEach(controller => controller.abort())
            resolve()
        })
    }
    useEffect(() => kill, [])

    return null
}