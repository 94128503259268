import { useRef, useState } from "react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useHeader } from "contexts/Header"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import Crown from "components/header/Crown"
import Menu from "components/menu/Toggle"
import LangSwitch from "components/menu/LangSwitch"
import Search from "components/search/Toggle"
import Wishlist from "components/wishlist/Toggle"
import StoreLocator from "components/store-locator/Toggle"

const Root = styled.div`
  position: relative;
  z-index: 2;
  ${fullGrid}
  --touch-padding: 0.5rem;

  & > figure,
  & > ul {
    grid-area: 1 / main-start / 1 / main-end;
    pointer-events: none;
  }

  & > figure {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > ul {
    display: flex;
    align-items: stretch;
    gap: calc(4vw - var(--touch-padding) * 2);

    & > li {
      display: grid;
      place-items: center start;
    }

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;

      & li:last-of-type {
        place-items: center end;
      }

      transform: scale(0.8);
      ${getMediaQuery("m")} {
        transform: scale(1);
      }

      transform-origin: right center;

      [dir="rtl"] & {
        transform-origin: left center;
      }

      transition: transform 300ms;
    }
  }

  background: linear-gradient(90deg, #0b3e27, #197149);
`

export default function Nav(props) {
  const console = useConsole()
  // const mql = global?.matchMedia?.(`(max-width: ${breakpoint.xl})`)
  const [alt, setAlt] = useState(false) //mql?.matches ?? false)
  const rroot = useRef()
  const header = useHeader()

  // useLayoutEffect(() => {
  //   const onchange = e => setAlt(mql.matches)
  //   mql.addListener(onchange)
  //   return () => mql.removeListener(onchange)
  // })

  console.verbose("Header:Nav(%o)", props)
  return (
    <Root ref={rroot} style={{ height: `var(${header.cssVars.height})`, ...(props.style ?? {}) }} className='header-container'>
      <ul>
        <li>
          <Menu />
        </li>
        {alt && (
          <li>
            <Search alt={alt} />
          </li>
        )}
      </ul>
      <Crown />
      <ul>
        {!alt && (
          <li>
            <Search alt={alt} />
          </li>
        )}
        <li>
          <StoreLocator />
        </li>
        <li>
          <Wishlist />
        </li>
      </ul>
      <LangSwitch />
    </Root>
  )
}
