export const sourceTypes = {
  watches: "watch",
  articles: "article",
  retailers: "retail",
}

export const sourceIndexes = {
  watches: "@rlx_contenttype==watch",
  articles: "@rlx_contenttype==article",
  retailers: "@rlx_contenttype==retail",
}

export const determineSourceTypeFromIndexName = (indexName) => { //todosearch: remove if still unused
	return Object.keys(sourceTypes).find(key => sourceTypes[key] === indexName)
} 
