import { useState, useEffect, useRef } from "react"
import { useInView } from "framer-motion"
import { useViewport } from "contexts/Viewport"

import Image from "components/media/ImageCLD"

import { Header, HGroup, Kicker, H1, SubTitle, Figure } from "./style"

export default function CoverLana({ heading, image }) {
  const { kicker, title, subtitle } = heading

  const viewport = useViewport()
  const { isMobile } = viewport

  const headerRef = useRef(null)
  const isInView = useInView(headerRef, { once: true })
  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (prefersReducedMotion) setAnimate(true)
    setAnimate(true)
  }, [isInView, prefersReducedMotion])

  const xValue = isMobile.current ? -25 : -80

  const hgroupVariants = {
    off: { opacity: 0, x: xValue },
    on: { opacity: 1, x: 0, transition: { duration: 0.8, x: { ease: "easeInOut" } } },
  }

  const figureVariants = {
    off: { transform: "scale(1)" },
    on: { transform: "scale(1.08)", transition: { duration: 4, ease: "easeOut" } },
  }

  return (
    <Header ref={headerRef}>
      <HGroup variants={hgroupVariants} initial='off' animate={prefersReducedMotion ? "on" : animate ? "on" : "off"}>
        {kicker && <Kicker>{kicker}</Kicker>}
        <H1>{title}</H1>
        {subtitle && <SubTitle>{subtitle}</SubTitle>}
      </HGroup>

      <Figure variants={figureVariants} initial='off' animate={prefersReducedMotion ? "on" : animate ? "on" : "off"}>
        <Image {...image} sizes='100vw' />
      </Figure>
    </Header>
  )
}
