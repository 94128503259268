import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useDictionary } from "contexts/Dictionary"

import { body50, bold, headline70, surtitle50 } from "css/text"
import { Heading as HeadingCN } from "css/classnames"
import getMediaQuery from "css/breakpoints"
import { grid } from "css/grid"

import { pageGradientVar } from "components/model/colors"
import Link from "components/link/Link"
import Button from "components/button/Button"
import Image from "components/media/ImageCLD"
import { useConfigurator } from "components/configurator-v3/context"
import { useRoller } from "./context"

export const KickerCN = `css-${hash("kicker")}`

const Li = styled.li`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  ${grid}
  grid-template-rows: 100%;
  background: var(${pageGradientVar});
`

const Figure = styled.figure`
  grid-column: 1 / -1;
  grid-row: 1;
  /* height: 100%; */
  width: 90%;
  align-self: end;
  justify-self: end;
  & img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: right bottom;

    top: 50%;
    position: absolute;
    transform: translateY(-30%);
  }
  ${getMediaQuery("m")} {
    height: 100%;
    & img {
      position: relative;
      top: unset;
      height: 100%;
      transform: translateY(0%);
    }
  }
  .a${hash("ended")} & img {
    object-position: center bottom;
  }

  ${getMediaQuery("m")} {
    height: 100%;
    .a${hash("ended")} & {
      width: 50%;
      justify-self: end;
    }
    & img {
      transform: translateY(0%);
    }
  }
`

const Hgroup = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  z-index: 1;
  height: fit-content;
  grid-column: 1 / span 5;
  grid-row: 1;
  text-align: start;
  justify-items: start;
  align-self: start;

  margin-block-start: 2.5rem;
  margin-inline-start: calc(var(--grid-col-unit) / 2);

  ${getMediaQuery("m")} {
    margin-block-start: 0;
    margin-inline-start: 0;
    grid-column: 2 / span 4;
    align-self: center;
  }
  & > button,
  & > a {
    margin-block-start: 2rem;
  }
  & > h2 {
    grid-row-start: 2;
  }

  & > p:first-of-type {
    ${surtitle50}
    grid-row-start: 1;
    margin-block-end: 10px;
  }

  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`

const Title = styled.h2`
  ${headline70}
  padding-bottom: 0.7rem;
  margin-bottom: -0.7rem;

  ${getMediaQuery("m")} {
    padding-bottom: 0.7rem;
    margin-bottom: -0.7rem;
  }

  em {
    color: rgb(var(--green));
    font-style: normal;
  }
`

const Fam = styled.p`
  ${body50}
  ${bold}
  margin-block-start: 20px;
`
const Desc = styled.p`
  ${body50}
  ${getMediaQuery("m")} {
    margin-inline-end: var(--grid-col-unit);
  }
`

export default function AshlarReturnLastConfigured({ index, action, step, bkg, description, familyName, picture }) {
  const { route, launched, bkgfromrv, cta } = useConfigurator()
  const dictionary = useDictionary()

  const { track } = useRoller()

  function onPress(e) {
    const s = step.replace("cfg/", "")
    bkgfromrv.set(bkg)
    launched.set(true)
    route.set(s)
    cta.current = e?.target
    onClick()
  }

  const title = {
    resume: dictionary.rvConfigResumeTitle(),
    ended: dictionary.rvConfigLatestTitle(),
  }

  function onClick() {
    track({
      event: "Click",
      customEvents: "returningVisitorClicked",
      index: index,
      ctaName: "Continue",
    })
  }

  const btn = {
    children: dictionary.continue(),
    icon: "chevron",
    label: "Continue",
    style: "filled translucent-dark",
  }

  return (
    <Li data-index={index} className={`dark-theme pcs${hash(bkg)} a${hash(action)}`}>
      <Figure>
        <Image sources={picture} sizes='(max-width: 48rem) 100vw, min(70vw, 1200px)' />
      </Figure>
      <Hgroup className={HeadingCN}>
        <p className={KickerCN}>{dictionary.welcomeBack()}</p>
        <Title id='buttonLabel' dangerouslySetInnerHTML={{ __html: title[action] }} />
        <Fam id='familyName'>{familyName}</Fam>
        <Desc>{description}</Desc>
        {action === "resume" ? (
          <Button aria-labelledby='buttonLabel' aria-describedby='familyName' onPress={onPress} {...btn} />
        ) : action === "ended" ? (
          <Link {...btn} href={step} onPress={onClick} />
        ) : null}
      </Hgroup>
    </Li>
  )
}
