export default function exposeEventTarget(eventTarget) {
  if (!(eventTarget instanceof EventTarget)) throw new TypeError("eventTarget does not implement EventTarget")

  const on = (...args) => {
    eventTarget.addEventListener(...args)
    return () => eventTarget.removeEventListener(...args)
  }
  const off = (...args) => {
    eventTarget.removeEventListener(...args)
    return Function.prototype
  }
  const once = (...args) => {
    const options = args.length === 3 ? args.pop() : {}
    options.once = true
    eventTarget.addEventListener(...args, options)
    return () => eventTarget.removeEventListener(...args, options)
  }

  return {
    on,
    off,
    once,
  }
}
