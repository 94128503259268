import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"

import { body50, bold, light } from "css/text"
import { link } from "css/links"

const Para = styled.p`
  ${body50}
  ${light}

  b {
    ${bold}
  }

  a {
    ${link}
  }
`

export default function Paragraph({ text, className }) {
  const console = useConsole()

  return <Para className={`${className} paragraph`} dangerouslySetInnerHTML={{ __html: text }} />
}
