import { useLayoutEffect } from "react"
import { motion, useMotionValue, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"
import { fullGrid } from "css/grid"

import { Icon } from "components/icon/Icon"

import { useRoller } from "./context"

const ArrowsContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  margin-block-start: -100vh;
  max-width: 120rem;
  margin-inline: auto;

  ${fullGrid}

  z-index: 1;
  pointer-events: none;
  align-self: center;
  grid-row: 1;

  ${getMediaQuery("xxxl")} {
    width: 88vw;
  }
`

const Arrow = styled(motion.button)`
  --arrow-size: 40px;
  ${getMediaQuery("m")} {
    --arrow-size: 44px;
  }
  ${buttonIcon}
  ${translucentDark}

  pointer-events: all;
  display: flex;
  border: 0;
  border-radius: 50%;

  width: var(--arrow-size);
  height: var(--arrow-size);

  &.previous {
    grid-column: col 1;
    justify-self: start;
  }
  &.next {
    grid-column: col 6;
    justify-self: end;
  }
  ${getMediaQuery("m")} {
    &.previous {
      justify-self: center;
      grid-column: col 1;
    }
    &.next {
      justify-self: center;
      grid-column: col 12;
    }
  }
  ${getMediaQuery("xxxl")} {
    &.previous {
      justify-self: start;
      grid-column: doc-start;
      margin-inline-start: 50px;
    }
    &.next {
      justify-self: end;
      grid-column: doc-end;
      margin-inline-end: 50px;
    }
  }

  &.previous svg {
    transform: scale(-1);
  }
  [dir="rtl"] &.previous svg {
    transform: scale(1);
  }
  [dir="rtl"] &.next svg {
    transform: scale(-1);
  }
`

export default function Arrows({ nb }) {
  const { slide, targetSlide, track } = useRoller()

  const prevVisible = useMotionValue("hidden")
  const nextVisible = useMotionValue("visible")

  function onPrevious() {
    targetSlide.set(slide.get() - 1)
    track({
      event: "Click",
      customEvents: "returningVisitorClicked",
      index: slide.get() - 1,
      ctaName: "arrow previous",
    })
  }
  function onNext() {
    targetSlide.set(slide.get() + 1)
    track({
      event: "Click",
      customEvents: "returningVisitorClicked",
      index: slide.get() + 1,
      ctaName: "arrow next",
    })
  }

  function handleVisibility(i) {
    if (i === nb - 1) {
      nextVisible.set("hidden")
    } else {
      nextVisible.set("visible")
    }
    if (i === 0) {
      prevVisible.set("hidden")
    } else {
      prevVisible.set("visible")
    }
  }

  function onSlideChange(i) {
    handleVisibility(i)
  }
  useMotionValueEvent(slide, "change", onSlideChange)

  useLayoutEffect(() => {
    handleVisibility(slide.get())
  }, [])

  return (
    <ArrowsContainer className='dark-theme'>
      <Arrow className='previous' onClick={onPrevious} style={{ visibility: prevVisible }} tabIndex={-1} aria-hidden={true}>
        <Icon type={"moveNext"} />
      </Arrow>
      <Arrow className='next' onClick={onNext} style={{ visibility: nextVisible }} tabIndex={-1} aria-hidden={true}>
        <Icon type={"moveNext"} />
      </Arrow>
    </ArrowsContainer>
  )
}
