import hash from "@emotion/hash"
import { css } from "@emotion/react"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"
import { headline100, surtitle100 } from "css/text"

export const kickerCN = hash("page-heading:kicker")
export const subtitleCN = hash("page-heading:subtitle")

export const pageHeaderCSS = css`
  text-align: center;
  margin-top: 10vh;
  width: 100%;
  z-index: 10;
  ${fullGrid}
  grid-template-rows: [kicker subtitle] min-content [mid-line] min-content [title];
  column-gap: var(--grid-gap);

  & h1,
  & p {
    grid-column: main;

    ${getMediaQuery("m")} {
      grid-column: col 3 / span 8;
    }
    ${getMediaQuery("l")} {
      grid-column: col 4 / span 6;
    }
  }

  & h1 {
    ${headline100}
    grid-row: mid-line / title;
  }

  & .${kickerCN} {
    ${surtitle100}
    grid-row: kicker / mid-line;
  }

  & .${subtitleCN} {
    ${surtitle100}
    grid-row: subtitle / mid-line;
  }
`
