import { loadSearchActions, loadSearchAnalyticsActions } from "@coveo/headless"
import { useConsole } from "contexts/Console"
import { useEffect } from "react"

export default function SearchWrapper({ children }) {
  const console = useConsole()

  useEffect(() => {
    const { logInterfaceLoad } = loadSearchAnalyticsActions(global.coveoHeadlessEngine)
    const { executeSearch } = loadSearchActions(global.coveoHeadlessEngine)
    // global.coveoHeadlessEngine.dispatch(executeSearch(logInterfaceLoad()))
  }, [children])

  console.verbose("Search(%o)", {})
  return <>{children}</>
}
