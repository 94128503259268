import { useConsole } from "contexts/Console"
import { useNavigation } from "contexts/Navigation"

import { storeCustomEvent } from "components/analytics/DigitalDataLayer"
import { A, Caption, Figure, ImgContainer, Selected } from "components/cards/subnavCommon"
import Image from "components/media/ImageCLD"

export default function SubNavCard({ image_cld: image, link, title, heading, meta, index, ...props }) {
  const console = useConsole()
  const navigation = useNavigation()

  const navigate = e => {
    e.preventDefault()
    //storeCustomEvent({ eventName: "ctaSubNav" })
    navigation.navigate(e.target.getAttribute("href"))
  }
  const active = link === navigation.slug
  console.verbose("SubNavCard(%o)", { link, title, props })

  const variants = {
    hide: { transition: { duration: 0.1 } },
    show: { transition: { duration: 0.25, ease: "easeOut" } },
  }

  return (
    <A
      className={active && "current"}
      aria-current={active ? "page" : false}
      href={navigation.localize(link)}
      onClick={navigate}
      style={{ "--i": meta.index, "--reverse-i": meta.reverseIndex }}
    >
      {active && <Selected />}
      <Figure variants={variants} role='figure' aria-labelledby={`imageCaption${index}`}>
        <ImgContainer className='img-cont'>
          <Image {...image} quality='auto:best' sizes='(min-width: 48rem) 28vw, 70vw' /> {/*Demand to be forced in quality best*/}
        </ImgContainer>

        <Caption id={`imageCaption${index}`}>
          {heading?.kicker && <span className='kicker' dangerouslySetInnerHTML={{ __html: heading.kicker }} />}
          {heading?.title && <span className='title' dangerouslySetInnerHTML={{ __html: heading.title }} />}
          {heading?.subtitle && <span className='subtitle' dangerouslySetInnerHTML={{ __html: heading.subtitle }} />}
        </Caption>
      </Figure>
    </A>
  )
}
