const TOP_DOMAIN =
  process.browser &&
  (function (split, cookiestr, i, curr, hit) {
    cookiestr = "__rlxtestcookie=testcookie"

    function cookie(domain) {
      document.cookie = cookiestr + "; domain=" + domain + "; SameSite=Lax" + (window.location.protocol === "https:" ? "; Secure" : "")

      if (document.cookie.indexOf(cookiestr) != -1) {
        document.cookie = cookiestr + "; domain=" + domain + "; max-age=0" + "; SameSite=Lax" + (window.location.protocol === "https:" ? "; Secure" : "")
        return true
      }
      return false
    }

    i = split.length

    while (i--) if (((curr = split.slice(i).join(".")), (hit = cookie(curr)), hit)) return curr

    return window.location.hostname
  })(window.location.hostname.split("."))

export default function useTopDomain() {
  return TOP_DOMAIN
}
