import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { AnimatePresence } from "framer-motion"

import { useConsole } from "contexts/Console"
import useSSR from "hooks/useSSR"

import { useStory } from "../context"
import Stories from "./Stories"

export default function StoryPlayer() {
  const console = useConsole()
  const ssr = useSSR()
  const { storyOpener, storyGroups, root, wasKeyDown } = useStory()

  const [openId, setOpenId] = useState(storyOpener.get().split(":")[0])

  function onOpenerChange(str) {
    const [id] = str.split(":")
    setOpenId(id)
  }
  useEffect(() => storyOpener.onChange(onOpenerChange))

  return !ssr
    ? createPortal(
        <AnimatePresence>{!!openId && <Stories openId={openId} stories={storyGroups.get(openId)} fromKeyDown={wasKeyDown.get()} />}</AnimatePresence>,
        root.current
      )
    : null
}
