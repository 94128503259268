import { Cloudinary } from "@cloudinary/url-gen"
import { limitFit } from "@cloudinary/url-gen/actions/resize"

export function videoLoader({ policy, src, format = "auto:video" }, env) {
  const { cloudinary: { cloudName, secureDistribution } = {} } = env
  src = src && src.constructor === Object ? src : { public_id: src || "/", version: 1 }

  const cld = new Cloudinary({
    cloud: {
      cloudName: cloudName,
    },
    url: Object.assign({ privateCdn: !!secureDistribution }, !!secureDistribution && { secureDistribution }),
  })

  return ({ width, quality = "auto" }) => {
    const video = cld.video(src.public_id)
    video.setVersion(src.version)

    if (policy) {
      video.namedTransformation(policy)
    }

    video.resize(limitFit().width(width))
    video.addTransformation(`f_${format}`)
    video.quality(quality)

    const vUrl = new URL(video.toURL())
    vUrl.searchParams.delete("_a") //necessary for hydration

    const poster = video.addTransformation("so_0")
    const pUrl = new URL(poster.toURL())
    pUrl.searchParams.delete("_a") //necessary for hydration

    return {
      poster: pUrl,
      src: vUrl,
    }
  }
}
