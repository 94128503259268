import { css } from "@emotion/react"

export const colors = css`
  :root {
    --rolex-gold: 163 126 44; // #a37e2c
    --green: 18 119 73; // #127749
    --dark-green: 11 62 39; //#0b3e27
    --light-green: 36 154 100; // #127749
    --light-black: 33 33 33; // #212121
    --dark-grey: 118 118 118; // #767676
    --grey: 212 212 212; // #d4d4d4
    --alternate-grey: 216 216 216; // #d8d8d8
    --light-grey: 248 248 248; // #f8f8f8
    --pure-white: 255 255 255; // #fff
    /* --ocean-green: 69 165 122; // #45a57a */
    --ocean-green: 97 189 147; // #61bd93

    --focus: var(--light-green);

    /* Gradients */
    --gradient-green-from: 16 99 61; // #10633d
    --gradient-green-to: 26 145 90; // #1a915a
    --gradient-dark-green-from: 12 76 47; // #0c4c2f
    --gradient-dark-green-to: 20 115 72; // #147348
  }
  ::selection {
    background: #127749;
    color: white;
    -webkit-text-fill-color: white;
  }
`
