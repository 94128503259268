import { css } from "@emotion/react"

/**
 * full width boxes should
 * allow the page scrollbar to disappear
 * without moving content
 */
export const fullWidth = css`
  width: var(--vw100, 100vw);
`
