import styled from "@emotion/styled"
import parse from "html-react-parser"
import { body50, bold, light } from "css/text"
import Link from "components/link/Link"

export const Para = styled.p`
  ${body50}
  ${light}
  margin-block-start: 1em;
  margin-block-end: 1em;
  font-size: inherit;

  &.last-child {
    margin-block-end: 0;
  }

  b {
    ${bold}
  }
`

export default function LegalParagraph({ paragraph }) {
  const transformHtml = html => {
    return parse(html, {
      replace: node => {
        if (node.name === "a") {
          const href = node.attribs.href
          const label = node.children[0].data

          return (
            <Link icon='none' href={href}>
              {label}
            </Link>
          )
        }
      },
    })
  }

  return paragraph.map((p, i) => (
    <Para key={`legal-para-${i}`} className={i + 1 === paragraph.length ? "last-child" : null}>
      {transformHtml(p)}
    </Para>
  ))
}
