import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"
import { headline100, headline70, surtitle70 } from "css/text"

const Root = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  height: fit-content;
  margin-block-end: 1.5rem;
  ${({ align }) => align && `text-align: ${align} !important;`}

  & > h2 {
    ${headline70}
    grid-row-start: 2;

    em {
      color: rgb(var(--green));
      font-style: normal;
    }
  }

  & > p {
    grid-row-start: 1;
  }

  .kicker {
    ${surtitle70}
    margin-block-end: 10px;
  }

  .suptitle {
    margin-block-end: 20px;
  }

  .suptitle,
  .bigtitle {
    ${headline100}
  }

  .bigtitle {
    padding: 0 0 0.8rem 0;
  }
`

export default function BigHeading({ kicker, suptitle, big_title, title, subtitle, justify, className, id }) {
  const console = useConsole()

  console.verbose("BigHeading(%o)", { title, big_title, kicker, justify, className, id })

  return (
    <Root className={`title-block ${className}`} align={justify}>
      {kicker && <p className='kicker'>{kicker}</p>}
      {suptitle && <p className='suptitle'>{suptitle}</p>}
      {big_title && <h2 className='bigtitle' dangerouslySetInnerHTML={{ __html: big_title }} />}
      {title && <h2 className='title' dangerouslySetInnerHTML={{ __html: title }} id={id} />}
      {subtitle && <p className='subtitle'>{subtitle}</p>}
    </Root>
  )
}
