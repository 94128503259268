import { motion, useTransform, transform } from "framer-motion"
import styled from "@emotion/styled"

import { useViewport } from "contexts/Viewport"

const Div = styled(motion.div)`
  display: block;
  width: 100%;
  height: 100.1%;
  background: rgb(0 0 0);
  grid-row: fullh;
  grid-column: doc;
  z-index: -1;
  transform: translate3d(0, 0, 0);
`

export default function Overlay({ prog, doubled }) {
  const { height } = useViewport()

  const opacity = useTransform([prog, height], ([p, h]) => transform(p, doubled ? [0, h] : [0, h * 0.5], [0.2, 0.8]))

  return <Div style={{ opacity }} />
}
