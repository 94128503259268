import { useEffect, useState } from "react"

export default function useKeyboard() {
  const bodyElement = process.browser && document.body
  const userKeys = ["Tab", "Enter", "ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"]
  const [keyboardActive, setKeyboardActive] = useState(false)

  function unsetKeyboard() {
    setKeyboardActive(false)
  }
  function setKeyboard() {
    setKeyboardActive(true)
  }

  useEffect(() => {
    bodyElement.addEventListener("keydown", e => userKeys.includes(e.key) && setKeyboard())
    bodyElement.addEventListener("mousedown", () => unsetKeyboard())

    return () => {
      bodyElement.removeEventListener("keydown", () => unsetKeyboard())
      bodyElement.removeEventListener("mousedown", () => unsetKeyboard())
    }
  }, [])

  return keyboardActive
}
