import { useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { useMotionValueEvent, motion } from "framer-motion"
import { FocusScope } from "@react-aria/focus"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"
import { buttonIcon, translucentDark } from "css/buttons"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useQuickViews } from "contexts/QuickViews"

import useScrollLock from "hooks/useScrollLock"

import camelCase from "utils/camelCase"

import { Icon } from "components/icon/Icon"
import Button from "components/button/Button"
import Arrows from "./Arrows"
import Pagination from "./Pagination"
import { RollerProvider, useRoller } from "./context"
import { getMatTheme, getTheme } from "../utils"
import LauncherCard from "./LauncherCard"

const Modal = styled(motion.div)`
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: min(100%, 100dvh);
  overflow-y: auto;
  transform: translateZ(0);
  overscroll-behavior: none;

  &.wv_straight {
    opacity: 1;
  }

  &:focus-within {
    nav {
      visibility: visible;
    }
  }
`

const Close = styled.button`
  ${buttonIcon}
  ${translucentDark}
  --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--height) / 2);
  ${getMediaQuery("m")} {
    --spacing: calc(var(--outer-margin) / 2 - var(--height) / 2);
  }

  transition: color 0.3s;

  inset-block-start: var(--spacing);
  inset-inline-end: var(--spacing);
  position: absolute;
  z-index: 29;
`

const Main = styled(motion.section)`
  position: relative;
  height: 100%;
  ${fullGrid}
  user-select: none;
  background-color: white;
  will-change: opacity, transform;
`

const List = styled.ul`
  grid-row: 1/-1;
  grid-column: doc;
  display: flex;
  height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overscroll-behavior-x: none;
  scroll-behavior: smooth;

  scroll-behavior: auto;

  transition: opacity 0.3s cubic-bezier(0.61, 1, 0.88, 1) 0s;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Item = styled.li`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  flex: 0 0 100%;

  &:last-of-type {
    scroll-snap-align: end;
  }
`

const ApplyButton = styled(Button)`
  position: relative;
  grid-row: 1/-1;
  grid-column: main;
  align-self: end;
  justify-self: center;
  margin-block-end: 5rem;
`

function Apply({ feature }) {
  const { qvContent } = useQuickViews()
  const { currentIndex } = useRoller()
  const dictionary = useDictionary()

  const [ariaId, setAriaId] = useState(`apply-id-${currentIndex.get()}`)

  function onClick(e) {
    const event = new CustomEvent("quickviews:apply:configurator", { detail: { index: currentIndex.get() } })
    document.dispatchEvent(event)
    qvContent.set(null)
  }

  function onCurrentChange(i) {
    setAriaId(`apply-id-${i}`)
  }
  useMotionValueEvent(currentIndex, "change", onCurrentChange)

  return (
    <ApplyButton className='filled opaque-white-cfg' icon='none' onPress={onClick} aria-describedby={ariaId}>
      {dictionary[camelCase(`apply-${feature}-to-watch`)]()}
    </ApplyButton>
  )
}

function FeatureLauncherContents({ items, apply, obj, wa, feature, themes }) {
  const rflist = useRef(null)
  const [current, setCurrent] = useState(-1)

  const { track } = useQuickViews()
  const { currentIndex, targetIndex, waIndex } = useRoller()

  function onTargetChange(t) {
    const firstEl = [...rflist.current.children][0]
    const elTarg = [...rflist.current.children][t].offsetLeft - firstEl.offsetLeft

    rflist.current.scrollTo({
      top: 0,
      left: elTarg,
      behavior: "smooth",
    })
  }
  useMotionValueEvent(targetIndex, "change", onTargetChange)

  function onCurrentChange(i) {
    track({ event: "Click", product: wa[i], techCode: obj[i].techCode, step: feature, index: i })
  }
  useMotionValueEvent(waIndex, "change", onCurrentChange)

  function onCurrent(i) {
    setCurrent(i)
    setTimeout(() => {
      if (i === currentIndex.get()) {
        waIndex.set(i)
      }
    }, 350)
  }
  useMotionValueEvent(currentIndex, "change", onCurrent)

  useLayoutEffect(() => {
    const elements = [...rflist.current.children]

    const slideSize = rflist.current.offsetWidth
    rflist.current.scrollTo({
      top: 0,
      left: slideSize * targetIndex.get(),
    })

    function onIntersectionObserved(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const curI = +entry.target.getAttribute("data-index")
          if (curI > -1) currentIndex.set(curI)
        }
      }
    }
    const observer = new IntersectionObserver(onIntersectionObserved, {
      root: rflist.current,
      rootMargin: "0% -60% 0% 0%",
      threshold: 0.2,
    })

    for (const item of elements) observer.observe(item)

    return () => {
      for (const item of elements) observer.unobserve(item)
    }
  }, [])

  return (
    <>
      <List ref={rflist} aria-live="polite">
        {items.map((item, i) => (
          <Item key={`quick-view-${i.toString()}`} data-index={i} aria-hidden={i !== current}>
            <LauncherCard index={i} {...item} theme={themes[i]} asset={obj[i].assetPath} topicLabel={obj[i].topicLabel} />
          </Item>
        ))}
      </List>
      <Arrows />
      {apply ? <Apply feature={feature} /> : null}
      {items.length > 1 ? <Pagination items={items?.map(v => v.heading)} /> : null}
    </>
  )
}

function Content({ items, apply, obj, wa, feature, themes }) {
  const dictionary = useDictionary()

  const { qvContent } = useQuickViews()
  const { theme } = useRoller()

  const ref = useRef(null)

  function onClose(e) {
    qvContent.set(null)
  }

  function onTheme(t) {
    if (t === "dark-theme") {
      ref.current.classList.replace("light-theme", t)
    } else {
      ref.current.classList.replace("dark-theme", t)
    }
  }
  useMotionValueEvent(theme, "change", onTheme)

  return (
    <Main ref={ref} className={theme.get()}>
      <Close onClick={onClose} aria-label={dictionary.popinClose()}>
        <Icon type='close' />
      </Close>
      <FeatureLauncherContents items={items} apply={apply} obj={obj} wa={wa} feature={feature} themes={themes} />
    </Main>
  )
}

export default function FeatureLauncher({ data }) {
  const console = useConsole()

  const rfmodal = useRef(null)

  const { qvContent } = useQuickViews()

  const scrollLock = useScrollLock()

  const d = JSON.parse(data)

  useLayoutEffect(() => {
    const el = rfmodal.current
    scrollLock.lock(el)

    return () => {
      scrollLock.unlock(el)
    }
  }, [])

  function onKeyDown(e) {
    if (e.code === "Escape") {
      qvContent.set(null)
    }
  }

  const themes = d.obj.reduce((acc, x) => acc.concat([d.feature === "material" ? getMatTheme(x.techCode) : getTheme(x.techCode)]), [])

  return (
    <FocusScope contain restoreFocus autoFocus>
      <Modal
        role='dialog'
        aria-modal='true'
        ref={rfmodal}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0 } }}
        transition={{ type: "tween", duration: 0.3 }}
        onKeyDownCapture={onKeyDown}
      >
        <RollerProvider count={d.items.length} start={d.start} themes={themes}>
          <Content {...d} themes={themes} />
        </RollerProvider>
      </Modal>
    </FocusScope>
  )
}
