export const CARDS = [
    {
        "_content_type_uid": "data_config_intro",
        "_version": 8,
        "locale": "en-gb",
        "uid": "bltba8b622376b0fc40",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-05T16:37:48.217Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Datejust",
            "paragraph": "Available in Oystersteel, Rolesor or gold,<br> with a fluted, smooth or gem-set bezel, and fitted with an Oyster, Jubilee or President bracelet – for 31-mm versions in gold."
        },
        "image_cld": {
            "alt": "Datejust",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-datejust-m126200-0023_2403jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056733,
                    "url": "http://media.rolex.com/image/upload/v1717056733/rolexcom/test/config-launcher/config-launcher-watches-datejust-m126200-0023_2403jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056733/rolexcom/test/config-launcher/config-launcher-watches-datejust-m126200-0023_2403jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 182789,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-30T08:12:13Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "e35cd39a2f1b90279740318e976c2490",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-datejust-m126200-0023_2403jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056730,
                    "url": "http://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-datejust-m126200-0023_2403jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-datejust-m126200-0023_2403jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2600,
                    "bytes": 156100,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-30T08:12:10Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "fefe62c94838127d1a2fd847fbe6feb4",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "datejust",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:datejust",
        "updated_at": "2024-06-13T12:26:45.726Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:26:48.175Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 4,
        "locale": "en-gb",
        "uid": "blt9661331c3c7dd793",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T10:13:09.194Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Submariner",
            "paragraph": "The Submariner Date is available in Oystersteel, Rolesor, yellow or white gold with varying dials and bezel, depending on the model. The Submariner is only available in Oystersteel with a black dial and bezel."
        },
        "image_cld": {
            "alt": "Submariner",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-submariner-m124060-0001_2108jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717057258,
                    "url": "http://media.rolex.com/image/upload/v1717057258/rolexcom/test/config-launcher/config-launcher-watches-submariner-m124060-0001_2108jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717057258/rolexcom/test/config-launcher/config-launcher-watches-submariner-m124060-0001_2108jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 313300,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:45Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "1bf041a3f294ec15a98ffb8868198f5f",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-submariner-m124060-0001_2108jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717057258,
                    "url": "http://media.rolex.com/image/upload/v1717057258/rolexcom/test/config-launcher/config-launcher-watches-submariner-m124060-0001_2108jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717057258/rolexcom/test/config-launcher/config-launcher-watches-submariner-m124060-0001_2108jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 178738,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:41Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "542fcf51f0546cc3e05f3451b46691bb",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "submariner",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:submariner",
        "updated_at": "2024-06-13T12:29:48.786Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:29:51.246Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "blt0aceea9509c84e2e",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T10:16:17.606Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "GMT-Master II",
            "paragraph": "Available in Oystersteel, yellow or Everose Rolesor, or yellow, white or Everose gold with two-colour ceramic bezel inserts, and Oyster or Jubilee bracelets."
        },
        "image_cld": {
            "alt": "GMT-Master II",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-gmt-master-ii-m126713grnr-0001_2301jva_002",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056732,
                    "url": "http://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-gmt-master-ii-m126713grnr-0001_2301jva_002.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-gmt-master-ii-m126713grnr-0001_2301jva_002.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 249543,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:40Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "01bb028960ba1aa736f0fae8a481f118",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-gmt-master-ii-m126713grnr-0001_2301jva_002-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056730,
                    "url": "http://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-gmt-master-ii-m126713grnr-0001_2301jva_002-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-gmt-master-ii-m126713grnr-0001_2301jva_002-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 147921,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:35Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "2df19b1ebd0cb7b11c06cbbe2f16c5ae",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "gmt-master-ii",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:gmt-master-ii",
        "updated_at": "2024-06-13T12:29:37.341Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:29:41.020Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 5,
        "locale": "en-gb",
        "uid": "blt939c759a6ef76222",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T10:17:57.353Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Day-Date",
            "paragraph": "Available with a 36 or 40 mm case in gold or platinum, a fluted or diamond-set bezel, and fitted with a President or Oyster bracelet."
        },
        "image_cld": {
            "alt": "Day-date",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-day-date-m228236-0012_2207jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717057731,
                    "url": "http://media.rolex.com/image/upload/v1717057731/rolexcom/test/config-launcher/config-launcher-watches-day-date-m228236-0012_2207jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717057731/rolexcom/test/config-launcher/config-launcher-watches-day-date-m228236-0012_2207jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 263091,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:44Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "25c3bce1e09c0f76008e9deed2b03c47",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-day-date-m228236-0012_2207jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717057731,
                    "url": "http://media.rolex.com/image/upload/v1717057731/rolexcom/test/config-launcher/config-launcher-watches-day-date-m228236-0012_2207jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717057731/rolexcom/test/config-launcher/config-launcher-watches-day-date-m228236-0012_2207jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 153585,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:37Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "70462f80f909b9b754fb98e575555f19",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "day-date",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:day-date",
        "updated_at": "2024-06-13T12:29:58.292Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:30:00.525Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "blt426879aeca36a617",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T10:21:41.780Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Cosmograph Daytona",
            "paragraph": "Available in Oystersteel, Rolesor, gold or platinum, with a fixed tachymetric bezel or a diamond-set bezel, and fitted with an Oyster bracelet or Oysterflex bracelet – for versions in gold."
        },
        "image_cld": {
            "alt": "Cosmograph Daytona",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-cosmograph-daytona-m126506-0001_2301ac_002",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056732,
                    "url": "http://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-cosmograph-daytona-m126506-0001_2301ac_002.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-cosmograph-daytona-m126506-0001_2301ac_002.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 225303,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-30T08:12:12Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "33e60f64594f413a1fc81ebb7b319326",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-cosmograph-daytona-m126506-0001_2301ac_002-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056730,
                    "url": "http://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-cosmograph-daytona-m126506-0001_2301ac_002-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-cosmograph-daytona-m126506-0001_2301ac_002-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 162910,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-30T08:12:10Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "6ae923c882cc02689a304db2946acd73",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "cosmograph-daytona",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:cosmograph-daytona",
        "updated_at": "2024-06-13T12:29:14.883Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:29:17.005Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 5,
        "locale": "en-gb",
        "uid": "blt8ebf7b45da556438",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T10:23:54.273Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Oyster Perpetual",
            "paragraph": "Available in five sizes in Oystersteel with an Oyster bracelet, domed bezel and a variety of dial colours, featuring luminecent indexes."
        },
        "image_cld": {
            "alt": "Oyster Perpetual",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-oyster-perpetual-m126000-0003_2112es_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058017,
                    "url": "http://media.rolex.com/image/upload/v1717058017/rolexcom/test/config-launcher/config-launcher-watches-oyster-perpetual-m126000-0003_2112es_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058017/rolexcom/test/config-launcher/config-launcher-watches-oyster-perpetual-m126000-0003_2112es_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 356132,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:47Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "17f0ba87003de94030e002a4a36a8e55",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-oyster-perpetual-m126000-0003_2112es_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058018,
                    "url": "http://media.rolex.com/image/upload/v1717058018/rolexcom/test/config-launcher/config-launcher-watches-oyster-perpetual-m126000-0003_2112es_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058018/rolexcom/test/config-launcher/config-launcher-watches-oyster-perpetual-m126000-0003_2112es_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 189881,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:40Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "fa1645e0a288795ed1c5eaa56829238e",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "oyster-perpetual",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:oyster-perpetual",
        "updated_at": "2024-06-13T12:26:57.157Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:26:59.265Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "blt28b0a8e10847538b",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T13:00:14.231Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Yacht-Master",
            "paragraph": "Available in Oystersteel, Rolesium, RLX Titanium or gold, with a bidirectional rotatable bezel and fitted with an Oyster or Oysterflex bracelet."
        },
        "image_cld": {
            "alt": "Yacht-Master",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-yacht-master-m126622-0002_1911jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058062,
                    "url": "http://media.rolex.com/image/upload/v1717058062/rolexcom/test/config-launcher/config-launcher-watches-yacht-master-m126622-0002_1911jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058062/rolexcom/test/config-launcher/config-launcher-watches-yacht-master-m126622-0002_1911jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 360594,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:42Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "d4e84810461c95da0780e8aec7aa0074",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-yacht-master-m126622-0002_1911jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058062,
                    "url": "http://media.rolex.com/image/upload/v1717058062/rolexcom/test/config-launcher/config-launcher-watches-yacht-master-m126622-0002_1911jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058062/rolexcom/test/config-launcher/config-launcher-watches-yacht-master-m126622-0002_1911jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 278093,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:39Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "1ab160887f0908de5d8abb4c22b1854d",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "yacht-master",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:yacht-master",
        "updated_at": "2024-06-13T12:29:04.838Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:29:07.473Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "bltf884009712bc4d49",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T13:24:34.781Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Sea-Dweller",
            "paragraph": "Available with a 43 mm case in Oystersteel or yellow Rolesor, with a unidirectional rotatable bezel and an Oyster bracelet."
        },
        "image_cld": {
            "alt": "Sea-Dweller",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-sea-dweller-m126603_0001_1905jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058261,
                    "url": "http://media.rolex.com/image/upload/v1717058261/rolexcom/test/config-launcher/config-launcher-watches-sea-dweller-m126603_0001_1905jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058261/rolexcom/test/config-launcher/config-launcher-watches-sea-dweller-m126603_0001_1905jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 300051,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:41Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "6beca244fc427753be96373da0caa8a2",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-sea-dweller-m126603_0001_1905jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058261,
                    "url": "http://media.rolex.com/image/upload/v1717058261/rolexcom/test/config-launcher/config-launcher-watches-sea-dweller-m126603_0001_1905jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058261/rolexcom/test/config-launcher/config-launcher-watches-sea-dweller-m126603_0001_1905jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 200911,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:35Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "edb841f99ab9b3fd66bf55024ccb74e8",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "sea-dweller",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:sea-dweller",
        "updated_at": "2024-06-13T12:28:42.212Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:28:44.535Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "bltf83c2a349227ebc0",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T13:29:54.098Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Rolex Deepsea",
            "paragraph": "Available in Oystersteel, yellow gold or RLX titanium, with a unidirectional rotatable bezel and an Oyster bracelet."
        },
        "image_cld": {
            "alt": "",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-deepsea-m136668LB-0001_2401jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056733,
                    "url": "http://media.rolex.com/image/upload/v1717056733/rolexcom/test/config-launcher/config-launcher-watches-deepsea-m136668LB-0001_2401jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056733/rolexcom/test/config-launcher/config-launcher-watches-deepsea-m136668LB-0001_2401jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 215801,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-30T08:12:13Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "4a5fb750b4431b1528b467607fad3e38",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-deepsea-m136668LB-0001_2401jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056730,
                    "url": "http://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-deepsea-m136668LB-0001_2401jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-deepsea-m136668LB-0001_2401jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 180626,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-30T08:12:10Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "82ee5b19684599ff6615042cdf9ba5ad",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "deepsea",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:deepsea",
        "updated_at": "2024-06-13T12:28:31.224Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:28:33.894Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 4,
        "locale": "en-gb",
        "uid": "blt7f4bbfcbc6777329",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T16:23:05.753Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Explorer",
            "paragraph": "The Explorer is available with a 36 mm case in Oystersteel or yellow Rolesor, and with a 40 mm case in Oystersteel. The Explorer II with a 42 mm case is available in Oystersteel, with a black or white dial. "
        },
        "image_cld": {
            "alt": "Explorer",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-explorer-m224270-0001_2301jva_002",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056732,
                    "url": "http://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-explorer-m224270-0001_2301jva_002.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-explorer-m224270-0001_2301jva_002.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 289611,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-30T08:12:12Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "6dba5cdb3e3bc8340966f90f9b83f329",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-explorer-m224270-0001_2301jva_002-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056730,
                    "url": "http://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-explorer-m224270-0001_2301jva_002-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-explorer-m224270-0001_2301jva_002-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 186306,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-30T08:12:10Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "662b9467bb1a0259cf2cbf257625e209",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "explorer",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:explorer",
        "updated_at": "2024-06-13T12:28:21.846Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:28:23.915Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "bltbccbd2b457b88890",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T16:24:36.770Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Lady-Datejust",
            "paragraph": "Variations include options in Oystersteel, Rolesor, or gold, with fluted, domed, or diamond-set bezels, and fitted with a President, Jubilee, or Oyster bracelet."
        },
        "image_cld": {
            "alt": "Lady-Datejust",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-lady-datejust-m279175-0002_2308jva_001",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056732,
                    "url": "http://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-lady-datejust-m279175-0002_2308jva_001.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056732/rolexcom/test/config-launcher/config-launcher-watches-lady-datejust-m279175-0002_2308jva_001.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 232928,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:49:17Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "a2758dc294969f3c4757aa2883fb7932",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-lady-datejust-m279175-0002_2308jva_001-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056730,
                    "url": "http://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-lady-datejust-m279175-0002_2308jva_001-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056730/rolexcom/test/config-launcher/config-launcher-watches-lady-datejust-m279175-0002_2308jva_001-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 180008,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:49:15Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "c2a06ed8f8473b2af5232c12fbe195b7",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "lady-datejust",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:lady-datejust",
        "updated_at": "2024-06-13T12:28:11.925Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:28:13.963Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "bltf1e1d339bc7c969a",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T16:26:05.594Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "Sky-Dweller",
            "paragraph": "Available in Oystersteel, Rolesor, yellow or Everose gold, with a fluted bezel, and fitted with an Oyster, Jubilee, or Oysterflex bracelet – only available for gold watches."
        },
        "image_cld": {
            "alt": "Sky-Dweller",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-sky-dweller-m336239-0002_2301jva_002",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058746,
                    "url": "http://media.rolex.com/image/upload/v1717058746/rolexcom/test/config-launcher/config-launcher-watches-sky-dweller-m336239-0002_2301jva_002.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058746/rolexcom/test/config-launcher/config-launcher-watches-sky-dweller-m336239-0002_2301jva_002.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 236088,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-22T15:45:41Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "97200e4ca45506be1f6d670a0a834d2a",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-sky-dweller-m336239-0002_2301jva_002-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717058746,
                    "url": "http://media.rolex.com/image/upload/v1717058746/rolexcom/test/config-launcher/config-launcher-watches-sky-dweller-m336239-0002_2301jva_002-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717058746/rolexcom/test/config-launcher/config-launcher-watches-sky-dweller-m336239-0002_2301jva_002-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 176999,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-22T15:45:35Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "f8a319a33ba1d2126860c0b9e4998254",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "sky-dweller",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:sky-dweller",
        "updated_at": "2024-06-13T12:28:03.006Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:28:05.090Z",
            "user": "bltc6205eed2f737d3d"
        }
    },
    {
        "_content_type_uid": "data_config_intro",
        "_version": 3,
        "locale": "en-gb",
        "uid": "bltd5ed8fdfddcc1a45",
        "ACL": {},
        "_in_progress": false,
        "created_at": "2024-06-06T16:27:15.625Z",
        "created_by": "bltc6205eed2f737d3d",
        "heading": {
            "kicker": "",
            "title": "1908",
            "paragraph": "Available with a 39 mm case in yellow gold, white gold or platinum, with a domed and fluted bezel, and fitted on a brown or black leather strap."
        },
        "image_cld": {
            "alt": "1908",
            "loading": "lazy",
            "sources": [
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-1908-m52508-0006_2301jva_002",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056734,
                    "url": "http://media.rolex.com/image/upload/v1717056734/rolexcom/test/config-launcher/config-launcher-watches-1908-m52508-0006_2301jva_002.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056734/rolexcom/test/config-launcher/config-launcher-watches-1908-m52508-0006_2301jva_002.jpg",
                    "width": 3000,
                    "height": 1688,
                    "bytes": 290807,
                    "duration": null,
                    "tags": [],
                    "metadata": {},
                    "created_at": "2024-05-30T08:12:14Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "a648e1d694b492c5b2d76dbff963df8e",
                    "folder": "rolexcom/test/config-launcher"
                },
                {
                    "public_id": "rolexcom/test/config-launcher/config-launcher-watches-1908-m52508-0006_2301jva_002-portrait",
                    "resource_type": "image",
                    "type": "upload",
                    "format": "jpg",
                    "version": 1717056731,
                    "url": "http://media.rolex.com/image/upload/v1717056731/rolexcom/test/config-launcher/config-launcher-watches-1908-m52508-0006_2301jva_002-portrait.jpg",
                    "secure_url": "https://media.rolex.com/image/upload/v1717056731/rolexcom/test/config-launcher/config-launcher-watches-1908-m52508-0006_2301jva_002-portrait.jpg",
                    "width": 1560,
                    "height": 2560,
                    "bytes": 193259,
                    "duration": null,
                    "tags": [],
                    "metadata": {
                        "v7_type": [
                            "portrait"
                        ]
                    },
                    "created_at": "2024-05-30T08:12:11Z",
                    "access_mode": "public",
                    "access_control": [
                        {
                            "access_type": "anonymous"
                        }
                    ],
                    "created_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "uploaded_by": {
                        "type": "user",
                        "id": "409d675c9a6940ae61bec37ff30cee"
                    },
                    "folder_id": "c7d044dc01b2d2722c62e9b8742806cd71",
                    "id": "e654854b71f826798d5893f6962c9ba5",
                    "folder": "rolexcom/test/config-launcher"
                }
            ]
        },
        "param": "1908",
        "tags": [],
        "theme": {
            "theme": "dark-theme"
        },
        "title": "config-intro:1908",
        "updated_at": "2024-06-13T12:27:52.210Z",
        "updated_by": "bltc6205eed2f737d3d",
        "publish_details": {
            "environment": "blt67783f11316ec3f6",
            "locale": "en-gb",
            "time": "2024-06-13T12:27:54.166Z",
            "user": "bltc6205eed2f737d3d"
        }
    }
]