import styled from "@emotion/styled"
import { motion } from "framer-motion"

import getMediaQuery from "css/breakpoints"

import { surtitle100, headline100 } from "css/text"

import { cssVarHeight } from "contexts/Header"
import { contrastMode } from "css/a11y"

export const Header = styled.header`
  overflow: hidden;
  display: grid;
  grid-template: "container";
  color: rgb(var(--pure-white));

  & > * {
    grid-area: container;
  }
`

export const HGroup = styled(motion.hgroup)`
  display: grid;
  width: calc(100% - 2 * var(--outer-margin));
  place-self: end start;
  margin-block-end: 20vh;
  margin-inline-start: var(--outer-margin);
  z-index: 1;
  // height: 50vh;
  // align-content: center;
  position: relative;

  ${contrastMode}

  ${getMediaQuery("m")} {
    width: calc(50% - var(--outer-margin));
    place-self: center start;
    height: auto;
    margin-block-end: 0;
    margin-left: var(--outer-margin);

    [dir="rtl"] & {
      place-self: center end;
    }
  }

  ${getMediaQuery("l")} {
    width: calc(50% - var(--outer-margin) - var(--grid-col-unit));
  }
`

export const H1 = styled.h1`
  ${headline100}
  margin-block-start: 10px;
`

export const Kicker = styled.div`
  ${surtitle100}
`

export const SubTitle = styled.div`
  ${surtitle100}
`

export const Figure = styled(motion.figure)`
  display: block;
  height: calc(101vh - var(${cssVarHeight}));

  & img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
`