import { useEnv } from "contexts/Env"

function quantcast(env) {
  if (env.cn) return

  var url
  if (env.development || env.staging) {
    url = "https://cmp.inmobi.com".concat("/choice/", "j6ef-UjNU-sjS", "/", "rlx-staging.com", "/choice.js?tag_version=V3")
  } else if (env.uat) {
    url = "https://cmp.inmobi.com".concat("/choice/", "j6ef-UjNU-sjS", "/", "rlx-uat.com", "/choice.js?tag_version=V3")
  } else {
    url = "https://cmp.inmobi.com".concat("/choice/", "j6ef-UjNU-sjS", "/", "www.rolex.com", "/choice.js?tag_version=V3")
  }

  var element = document.createElement("script")
  var firstScript = document.getElementsByTagName("script")[0]
  var uspTries = 0
  var uspTriesLimit = 3
  element.async = true
  element.type = "text/javascript"
  element.src = url

  firstScript.parentNode.insertBefore(element, firstScript)

  function makeStub() {
    var TCF_LOCATOR_NAME = "__tcfapiLocator"
    var queue = []
    var win = window
    var cmpFrame

    function addFrame() {
      var doc = win.document
      var otherCMP = !!win.frames[TCF_LOCATOR_NAME]

      if (!otherCMP) {
        if (doc.body) {
          var iframe = doc.createElement("iframe")

          iframe.style.cssText = "display:none"
          iframe.name = TCF_LOCATOR_NAME
          doc.body.appendChild(iframe)
        } else {
          setTimeout(addFrame, 5)
        }
      }
      return !otherCMP
    }

    function tcfAPIHandler() {
      var gdprApplies
      var args = arguments

      if (!args.length) {
        return queue
      } else if (args[0] === "setGdprApplies") {
        if (args.length > 3 && args[2] === 2 && typeof args[3] === "boolean") {
          gdprApplies = args[3]
          if (typeof args[2] === "function") {
            args[2]("set", true)
          }
        }
      } else if (args[0] === "ping") {
        var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: "stub",
        }

        if (typeof args[2] === "function") {
          args[2](retr)
        }
      } else {
        if (args[0] === "init" && typeof args[3] === "object") {
          args[3] = { ...args[3], tag_version: "V3" }
        }
        queue.push(args)
      }
    }

    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === "string"
      var json = {}

      try {
        if (msgIsString) {
          json = JSON.parse(event.data)
        } else {
          json = event.data
        }
      } catch (ignore) {}

      var payload = json.__tcfapiCall

      if (payload) {
        window.__tcfapi(
          payload.command,
          payload.version,
          function (retValue, success) {
            var returnMsg = {
              __tcfapiReturn: {
                returnValue: retValue,
                success: success,
                callId: payload.callId,
              },
            }
            if (msgIsString) {
              returnMsg = JSON.stringify(returnMsg)
            }
            if (event && event.source && event.source.postMessage) {
              event.source.postMessage(returnMsg, "*")
            }
          },
          payload.parameter
        )
      }
    }

    while (win) {
      try {
        if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win
          break
        }
      } catch (ignore) {}

      if (win === window.top) {
        break
      }
      win = win.parent
    }
    if (!cmpFrame) {
      addFrame()
      win.__tcfapi = tcfAPIHandler
      win.addEventListener("message", postMessageEventHandler, false)
    }
  }

  makeStub()

  var uspStubFunction = function () {
    var arg = arguments
    if (typeof window.__uspapi !== uspStubFunction) {
      setTimeout(function () {
        if (typeof window.__uspapi !== "undefined") {
          window.__uspapi.apply(window.__uspapi, arg)
        }
      }, 500)
    }
  }

  var checkIfUspIsReady = function () {
    uspTries++
    if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
      console.warn("USP is not accessible")
    } else {
      clearInterval(uspInterval)
    }
  }

  if (typeof window.__uspapi === "undefined") {
    window.__uspapi = uspStubFunction
    var uspInterval = setInterval(checkIfUspIsReady, 6000)
  }

  //quantcast listener
  window.__tcfapi("addEventListener", 2, function (tcData, listenerSuccess) {
    console.log("makeStub -> listenerSuccess", listenerSuccess)
    console.log("makeStub -> tcData", tcData)
    console.log("makeStub -> window.digitalDataLayer", window.digitalDataLayer)
    if (listenerSuccess) {
      if (tcData.eventStatus === "cmpuishown" && tcData.gdprApplies) {
        //console.log("makeStub -> tcData.eventStatus === cmpuishown")
        //console.log("makeStub -> tcData.gdprApplies")
        window.localStorage.setItem("isGdprOpen", JSON.stringify(true))
        let gdprOpen = new CustomEvent("gdprOpen")
        window.dispatchEvent(gdprOpen)
      }
    }
    if (listenerSuccess && tcData.eventStatus === "tcloaded") {
      console.log("makeStub -> tcData.eventStatus", tcData.eventStatus)
      console.log("makeStub -> window.digitalDataLayer", window.digitalDataLayer)
      console.log("makeStub -> window.dataLayer", window.dataLayer)
      if (!tcData.gdprApplies) {
        window.localStorage.setItem("isNotGdpr", JSON.stringify(true))
      }
    }
    if (listenerSuccess && tcData.eventStatus === "useractioncomplete") {
      console.log("makeStub -> tcData eventStatus", tcData.eventStatus)
      window.localStorage.setItem("isGdprOpen", JSON.stringify(false))

      let gdprClosed = new CustomEvent("gdprClosed")
      window.dispatchEvent(gdprClosed)

      if (!tcData.gdprApplies) {
        //console.log("makeStub -> !tcData.gdprApplies")
        window._satellite.setVar("CC-Analyticsconsent", true)
        window._satellite.setVar("CC-CSconsent", true)
        window._satellite.setVar("CC-COconsent", true)
        window._satellite.setVar("CC-DYconsent", true)
        window._satellite.setVar("CC-Facebookconsent", true)
        window._satellite.setVar("CC-AACconsent", true)
        window._satellite.setVar("CC-linkedinConsent", true)

        if (!!window.dtrum) {
          window.dtrum.enable()
        }

        var event = new CustomEvent("event-view-end")
        var obj = document.querySelector("body")
        obj.dispatchEvent(event)
      } else {
        /**
         * IAB vendors.
         */
        var vendor_consent_id_list = []
        var vendor_consent_id_list_str = ""
        Object.keys(tcData.vendor.consents).forEach(function (key) {
          if (tcData.vendor.consents[key]) {
            vendor_consent_id_list.push(key)
          }
        })
        vendor_consent_id_list_str = vendor_consent_id_list.join()
        //console.log(" makeStub -> vendor_consent_id_list", tcData.vendor.consents)
        //console.log(" makeStub -> window._satellite", window._satellite)

        window.__tcfapi("getNonIABVendorConsents", 2, function (nonIabConsent, nonIabSuccess) {
          //console.log(" makeStub -> nonIabVendorConsents !!!")
          //console.log(" makeStub -> nonIabConsent", nonIabConsent)
          //console.log(" makeStub -> nonIabConsent.nonIabVendorConsents", nonIabConsent.nonIabVendorConsents)
          //console.log(" makeStub -> nonIabSuccess", nonIabSuccess)
          var CSconsent = false
          var analyticsConsent = false
          var COconsent = false
          var DYconsent = false
          var FBconsent = false
          var AACconsent = false
          var linkedinConsent = false

          const satelliteSetting = () => {
            //console.log("customVars -> CSconsent", CSconsent)
            //console.log("customVars -> analyticsConsent", analyticsConsent)
            //console.log("customVars -> COconsent", COconsent)
            //console.log("customVars -> DYconsent", DYconsent)
            //console.log("customVars -> FBconsent", FBconsent)
            //console.log("customVars -> AACconsent", AACconsent)
            //console.log("customVars -> linkedinConsent", linkedinConsent)
            window._satellite.setVar("CC-Analyticsconsent", analyticsConsent)
            window._satellite.setVar("CC-CSconsent", CSconsent)
            window._satellite.setVar("CC-COconsent", COconsent)
            window._satellite.setVar("CC-DYconsent", DYconsent)
            window._satellite.setVar("CC-Facebookconsent", FBconsent)
            window._satellite.setVar("CC-AACconsent", AACconsent)
            window._satellite.setVar("CC-linkedinConsent", linkedinConsent)
            if (!!window.dtrum) {
              window.dtrum[DYconsent ? "enable" : "disable"]()
            }

            console.log("satellite track Analytics")
            // var event = new CustomEvent("event-view-end")
            var event = new CustomEvent("quantcast:consent")
            var obj = document.querySelector("body")
            obj.dispatchEvent(event)
          }

          if (nonIabSuccess && nonIabConsent.nonIabVendorConsents) {
            var nonIABVendorsIds = nonIabConsent.nonIabVendorConsents
            console.log("makeStub -> nonIABVendorsIds", nonIABVendorsIds)

            if (nonIABVendorsIds != undefined) {
              CSconsent = nonIABVendorsIds[1]
              analyticsConsent = nonIABVendorsIds[3]
              COconsent = nonIABVendorsIds[7]
              DYconsent = nonIABVendorsIds[5]
              FBconsent = nonIABVendorsIds[8]
              AACconsent = nonIABVendorsIds[9]
              linkedinConsent = nonIABVendorsIds[10]

              if (window._satellite) {
                satelliteSetting()
              } else {
                const interval = window.setInterval(() => {
                  if (window._satellite) {
                    satelliteSetting()

                    clearInterval(interval)
                  }
                }, 300)
              }
            }
          }
        })
      }
    }
  })
}

export default function Quancast() {
  const env = useEnv()

  if (process.browser) {
    quantcast(env)
    quantcast = Function.prototype // make sure that the script only runs once
  }

  return null
}
