export function clamp(x, min, max) {
  return Math.max(min, Math.min(max, x))
}

export function getStoryIndex(slugMap, s) {
  return slugMap.findIndex(arr => arr.includes(s))
}

export function getSlugIndex(slugs, s) {
  return slugs.indexOf(s)
}

export function getNextSlug(slugs, s) {
  return slugs[Math.min(getSlugIndex(slugs, s) + 1, slugs.length - 1)]
}

export function getPrevSlug(slugs, s) {
  return slugs[Math.max(getSlugIndex(slugs, s) - 1, 0)]
}

export function setStorage(v) {
  localStorage.setItem("storySeen", JSON.stringify(v))
}
export function getStorage() {
  return JSON.parse(localStorage.getItem("storySeen"))
}
export function updateStorage(storyId, slugIndex) {
  const map = new Map(getStorage())
  if (!map.has(storyId)) return
  map.get(storyId)[slugIndex] = true
  setStorage(Array.from(map))
}
export function isStorySeen(storyId) {
  const map = new Map(getStorage())
  if (!map.size || !map.has(storyId)) return false
  const bool = !!map.get(storyId)?.every(v => !!v)
  return bool
}

export function buildStorage(stories) {
  const curStorage = getStorage()

  if (!curStorage) {
    const map = new Map()
    stories.forEach(({ cards, story_id }) =>
      map.set(
        story_id,
        Array.from(Array(cards.length)).map(v => false)
      )
    )
    setStorage(Array.from(map))
  } else {
    const map = new Map(curStorage)
    stories.forEach(
      ({ cards, story_id }) =>
        !map.has(story_id) &&
        map.set(
          story_id,
          Array.from(Array(cards.length)).map(v => false)
        )
    )
    setStorage(Array.from(map))
  }
}
