export const mvSubscribe = (mv, changeFunc, run = true) => {
    const unsub = mv.onChange((v) => {
        changeFunc(v)
    })
    if (run) {
        changeFunc(mv.get())
    }
    return unsub
}

export function observeResize(el, onResize) {
    if (!el) return
    const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
            if (entry.contentBoxSize) {
                const contentBoxSize = entry.contentBoxSize[0]
                onResize(contentBoxSize.inlineSize, contentBoxSize.blockSize, entry.target)
            }
        }
    })

    resizeObserver.observe(el)

    return () => {
        resizeObserver.disconnect()
    }
}

export function observeIntersection(el, onObserve, options) {
    if (!el) return
    const intObserver = new IntersectionObserver(onObserve)

    intObserver.observe(el, options)

    return () => {
        intObserver.disconnect()
    }
}

export function getOffset(index, W, w, X, count) {
    //    console.log("getOffset(index, W, w, X, cw)", index, W, w, X, count)
    if (w <= 0 || W <= 0) return 0
    const rem = (w - W) / 2
    const p = (index + .5) * w / count
    if ((X + p) <= -rem) return w
    if ((X + p - W) >= rem) return -w
    return 0
}

export function getPos(index, W, offset, X, w, count) {
    //    console.log("getPos", index, W, offset, X, w, count, W / 2 - ((index + .5) * (w / count) + X + offset * w))
    return W / 2 - ((index + .5) * (w / count) + X + offset)
}

export function getOffsetPos(index, W, w, X, cw) {
    const offset = getOffset(index, W, w, X, cw)
    const pos = getPos(index, W, offset, X, cw)
    return { offset, pos }
}

export function setProperty(el, key, value) {
    el?.style?.setProperty?.(key, value)
}

function detectTouchscreen() {
    var result = false
    if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            result = true
        }
    } else {
        // no Pointer Events...
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
            // check for any-pointer:coarse which mostly means touchscreen
            result = true
        } else if (window.TouchEvent || ('ontouchstart' in window)) {
            // last resort - check for exposed touch events API / event handler
            result = true
        }
    }
    return result
}

export function isTouchEnabled() {
    return detectTouchscreen()
}

export function updateLoadingQ(stack, value) {
    const index = stack.indexOf(value)
    if (index >= 0) stack.splice(index, 1)
    return stack.length <= 1
}

export const LISTSEPARATOR = ","

export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1)
}

export function rafDelay(fn = null) {
    if (!process.browser || !fn) return
    window.requestAnimationFrame(function () {
        window.requestAnimationFrame(function () {
            fn()
        })
    })
}

export function willChange(el, prop) {
    if (!el) return
    el.style.willChange = prop ? prop : 'auto'
}
