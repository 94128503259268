import styled from "@emotion/styled"
import { body50, bold } from "css/text"
import { useDictionary } from "contexts/Dictionary"
import { useSearch } from "./context"

import { useConsole } from "contexts/Console"
import { useActions } from "./hooks/useActions"

const Item = styled.li`
  position: relative;
  display: flex;
  justify-align: center;
  align-items: center;
  gap: 10px 0;
  background: transparent;
  border: none;
  outline: none;
`

const Button = styled.button`
  ${bold}
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  color: rgba(var(--pure-white) / 0.4);
  // color: rgb(var(--light-black));
  border: none;
  outline: none;
  background-color: transparent;
  transition: all 0.3s;
  margin-inline: 20px;

  padding-inline-end: 0.5rem;

  @media (hover: hover) {
    :hover {
      opacity: 1;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-inline-start: 0.5rem;
    position: absolute;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: currentColor;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:disabled {
    cursor: initial;
    color: rgba(var(--pure-white));

    &:after {
      content: "";
      opacity: 1;
      transition: opacity 0.5s;
      background: currentColor;
    }
  }
`

const slugify = val => val?.replace(/\s+/g, "-").toLowerCase()

export const Tab = props => {
  const console = useConsole()
  const dictionary = useDictionary()
  const { activeFilter, setActiveFilter } = useSearch()
  const { activateFilter } = useActions()

  console.verbose("Tab(%o)", { props })

  return (
    <Item>
      <Button
        tabindex='0'
        disabled={activeFilter === props.label}
        onClick={() => {
          setActiveFilter(props.label)
          activateFilter(props.label)
        }}
      >
        {props.children}
      </Button>
    </Item>
  )
}
