import { useLayoutEffect } from "react"
import { useMotionValue, useTransform } from "framer-motion"

import { Provider } from "contexts/Viewport"

export default function Viewport({ children }) {
  const width = useMotionValue(process.browser ? document.documentElement.clientWidth : 0)
  const height = useMotionValue(process.browser ? document.documentElement.clientHeight : 0)
  const orientation = useTransform([width, height], ([w, h]) => (w > h ? "landscape" : "portrait"))
  const isMobile = useTransform(width, w => !!(w < 768))
  const ratio = useTransform([width, height], ([w, h]) => w / h)
  const bp = useTransform(width, w => (w < 768 ? "s" : w >= 768 && w <= 1024 ? "m" : w > 1024 && w < 1280 ? "l" : "xl"))
  const outerMargin = useTransform(width, w => w * 0.08)
  const gap = useTransform(bp, b => (b === "s" ? 6 : 8))
  const colNum = useTransform(bp, b => (b === "s" ? 6 : 12))
  const colUnit = useTransform([width, outerMargin, gap, colNum], ([w, o, g, n]) => (w - o * 2 - g * (n - 1)) / n)

  const onResize = () => {
    width.set(document.documentElement.clientWidth)
    height.set(document.documentElement.clientHeight)
  }

  useLayoutEffect(() => {
    global.addEventListener("resize", onResize, { passive: true })
    return () => global.removeEventListener("resize", onResize)
  }, [])

  return <Provider value={{ width, height, orientation, ratio, bp, colUnit, gap, isMobile, outerMargin }}>{children}</Provider>
}
