import { useLayoutEffect, useState, useRef } from "react"
import { createPortal } from "react-dom"
import { AnimatePresence } from "framer-motion"
import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"

import { buttonReset } from "css/buttons"
import { body50, light } from "css/text"

import usePrice from "hooks/usePrice"

import { Icon } from "components/icon/Icon"
import PriceModal from "./PriceModal"

export const cssPrice = `css-${hash("price")}`

const StyledPrice = styled.p`
  /* ${body50} */
  ${light}
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  font-size: clamp(0.875rem, 0.9375rem + 0.1563vw, 1.25rem); // 14px > 20px
  line-height: 1.2;

  > span {
    direction: ltr;
  }
`

const Button = styled.button`
  ${buttonReset}
  padding: .25rem;

  > span {
    border: 1px solid currentColor;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    height: 15px;
    padding-left: 3px; // *not* using inline-start on purpose
    transition: color 0.3s;
    width: 15px;
  }

  @media (hover: hover) {
    :hover {
      color: rgb(var(--green));

      .dark-theme & {
        color: rgb(var(--grey));
      }
    }
  }
`

export default function Price({ rmc, formattedPrice, pricePopin, onReady = () => true, no_observer = false, ariaDescribedBy }) {
  const console = useConsole()
  const dictionary = useDictionary()
  const countryCode = process.browser && sessionStorage.getItem("override_country_code")
  const fetch = usePrice({ rmc, countryCode })
  const [data, setData] = useState(null)
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false)
  const ref = useRef()
  const rfbtn = useRef(null)

  const bodyElement = process.browser && document.body
  const rmcIsValid = rmc && /^m[a-z0-9]+\-[a-z0-9]+$/.test(rmc)

  const propsData = { formattedPrice, ...pricePopin }

  const onClose = () => {
    setIsPriceModalOpen(false)
  }

  const onOpen = e => {
    e.preventDefault()
    e.stopPropagation()

    setIsPriceModalOpen(true)
  }

  useLayoutEffect(() => {
    const node = ref.current
    let abort

    const start = () => {
      if (!rmcIsValid) {
        setData({ showPrices: false })
        return
      }
      abort = fetch((err, data) => {
        abort = null
        if (err) return console.error(err)
        setData(data)
        onReady(rmc, data)
        if (no_observer) stop()
      })
    }
    const stop = () => {
      abort?.()
      global.removeEventListener("scroll", onscroll)
    }

    if (!data && no_observer) {
      start()
      return
    }

    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) start()
      else stop()
    })

    if (!data && !formattedPrice) {
      observer.observe(node)
    }

    return () => {
      stop()
      observer.unobserve(node)
    }
  })

  useLayoutEffect(() => {
    if (!rfbtn.current) return
    rfbtn.current.addEventListener("focus", onFocus, false)
    rfbtn.current.addEventListener("blur", onBlur, false)

    return () => {
      rfbtn.current.removeEventListener("focus", onFocus, false)
      rfbtn.current.removeEventListener("blur", onBlur, false)
    }

    function onFocus(e) {
      rfbtn.current.setAttribute("aria-label", dictionary.infoPriceButton())
    }

    function onBlur(e) {
      rfbtn.current.removeAttribute("aria-label")
    }
  }, [data])

  console.verbose("Price(%o)", { showPrices: data?.showPrices, data })
  return !!data?.showPrices || formattedPrice ? (
    <>
      <StyledPrice ref={ref} className={cssPrice}>
        <span data-nosnippet className='price'>
          {formattedPrice ?? data?.formattedPrice ?? data?.onDemandTitle}
          {data?.formattedPrice2 && ` | ${data?.formattedPrice2}`}
        </span>
        <Button ref={rfbtn} aria-describedby={ariaDescribedBy} onClick={onOpen}>
          <span>
            <Icon type='info' />
          </span>
        </Button>
      </StyledPrice>
      {process.browser &&
        createPortal(
          <AnimatePresence>{isPriceModalOpen && <PriceModal onClose={onClose} onopen={onOpen} {...(data ?? propsData)} scope />}</AnimatePresence>,
          bodyElement
        )}
    </>
  ) : (
    <p ref={ref} className={cssPrice} />
  )
}

export function FixedPrice({ formattedPrice, pricePopin = {} }) {
  const dictionary = useDictionary()
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false)
  const rfbtn = useRef(null)

  const bodyElement = process.browser && document.body

  const onClose = () => {
    setIsPriceModalOpen(false)
  }

  const onOpen = e => {
    e.preventDefault()
    e.stopPropagation()

    setIsPriceModalOpen(true)
  }

  useLayoutEffect(() => {
    if (!rfbtn.current) return
    rfbtn.current.addEventListener("focus", onFocus, false)
    rfbtn.current.addEventListener("blur", onBlur, false)

    return () => {
      rfbtn.current.removeEventListener("focus", onFocus, false)
      rfbtn.current.removeEventListener("blur", onBlur, false)
    }

    function onFocus(e) {
      rfbtn.current.setAttribute("aria-label", dictionary.infoPriceButton())
    }

    function onBlur(e) {
      rfbtn.current.removeAttribute("aria-label")
    }
  }, [])

  return (
    <>
      <StyledPrice className={cssPrice}>
        <span data-nosnippet className='price'>
          {formattedPrice}
        </span>
        <Button ref={rfbtn} onClick={onOpen}>
          <span>
            <Icon type='info' />
          </span>
        </Button>
      </StyledPrice>
      {process.browser &&
        createPortal(
          <AnimatePresence>{isPriceModalOpen && <PriceModal onClose={onClose} formattedPrice {...pricePopin} scope />}</AnimatePresence>,
          bodyElement
        )}
    </>
  )
}
