import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { body70, headline50 } from "css/text"

import ModalContainer, { a11yModal } from "components/modal/ModalContainer"

const Heading = styled.h2`
  ${headline50};
  margin-block-end: 1ex;
`

const Paragraph = styled.div`
  > p {
    ${body70}

    > a {
      text-decoration: none;
      color: rgb(var(--green));
    }
  }
`

export default function A11yModal({ onCloseA11yModal, heading, text }) {
  const console = useConsole()

  console.verbose("••••• A11yModal(%o", text)
  return (
    <ModalContainer onClose={onCloseA11yModal} className={a11yModal} modalHeading='modal-heading' modalContent='modal-content'>
      <Heading id='modal-heading' dangerouslySetInnerHTML={{ __html: heading }} />
      <Paragraph id='modal-content' dangerouslySetInnerHTML={{ __html: text }} />
    </ModalContainer>
  )
}
