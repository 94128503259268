import { isCacheValid, PRICE_CACHE } from "hooks/usePrice"

export async function getPriceDatas({ rmc, countryCode, locale, env }) {
  const lang = locale?.current?.codes?.www
  const base = process.browser ? env.services : process.env.REVERSE_PROXY_URL
  const pathname = `/api/prices/${rmc}?countryCode=${countryCode}&lang=${lang}`

  const url = new URL(pathname, base)
  const controller = new AbortController()
  const request = new Request(url)

  const cacheKey = `${rmc}_${countryCode}_${lang}`
  const cachedData = sessionStorage.getItem(PRICE_CACHE)

  if (cachedData) {
    const cache = JSON.parse(cachedData)
    const { data, timestamp } = cache[cacheKey] || {}
    if (data && timestamp && isCacheValid(timestamp)) {
      controller.abort()
      return data
    }
  }

  return Promise.resolve().then(async () => {
    if (!controller.aborted) {
      return fetch(request, { signal: controller.signal })
        .then(response => response.json())
        .then(data => {
          const cache = JSON.parse(sessionStorage.getItem(PRICE_CACHE)) || {}
          cache[cacheKey] = { timestamp: Date.now(), data }
          sessionStorage.setItem(PRICE_CACHE, JSON.stringify(cache))
          return data
        })
        .catch(err => {
          if (err.name === "AbortError") return
          console.error(err => console.log(err))
        })
    }
  })
}

export async function getMultiPriceData({ rmcs, countryCode, locale, env }) {
  const body = JSON.stringify({ watchlist: rmcs })
  const lang = locale?.current?.codes?.www
  const base = process.browser ? env.services : process.env.REVERSE_PROXY_URL
  const pathname = `/api/prices/pricelist?countryCode=${countryCode}&lang=${lang}&sort=price:desc`
  const url = new URL(pathname, base)
  const request = new Request(url, { method: "POST", body: body, headers: { "content-type": "application/json" } })
  return fetch(request)
    .then(response => response.json())
    .then(data => data)
    .catch(err => {
      console.error(console.log(err))
    })
}
