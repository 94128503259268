import { startTransition, useLayoutEffect, useRef, useState, useEffect } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useMenu } from "contexts/Menu"
import { useHeader } from "contexts/Header"
import { useDictionary } from "contexts/Dictionary"
import { ctx__ONLY_FOR_MENU__ as ctx } from "contexts/Menu"

import { legend100, normal, visuallyHidden } from "css/text"
import getMediaQuery from "css/breakpoints"

import { statesLabel } from "components/menu/Pane"

const Root = styled.div`
  display: grid;
  pointer-events: unset;
`

const Button = styled.button`
  all: unset;
  cursor: pointer;
  user-select: none;
  grid-area: 1/1;
  z-index: 1;
  justify-self: start;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1ex;
  position: relative;
  padding: var(--touch-padding);
  left: calc(-1 * var(--touch-padding));
  color: rgb(var(--pure-white));

  [dir="rtl"] & {
    left: 0;
    right: calc(-1 * var(--touch-padding));
  }

  @media (hover: hover) {
    &:hover {
      color: rgb(var(--ocean-green));
    }
  }

  & > svg {
    align-self: center;
    height: 18px;
    position: relative;
    width: 18px;
  }

  transition: opacity 300ms, color 300ms;
`

const BtnLabel = styled.span`
  ${legend100}
  ${normal}
    
    ${getMediaQuery("m", { max: true })} {
    ${visuallyHidden}
  }
`

const Line = styled(motion.line)`
  fill: none;
  stroke: currentColor;
`

const Toggle = styled(Button)`
  pointer-events: auto;
`

const lineVariants = {
  l1: {
    "active-1": { x1: 0, y1: 5, x2: 18, y2: 5 },
    active0: { x1: [0, 0, 2.6], y1: [5, 9, 15.4], x2: [18, 18, 15.4], y2: [5, 9, 2.6] },
    active1: { x1: [2.6, 0, 0], y1: [15.4, 9, 5], x2: [15.4, 18, 18], y2: [2.6, 9, 5] },
  },
  l2: {
    "active-1": { x1: 0, y1: 12, x2: 18, y2: 12 },
    active0: { x1: [0, 0, 2.6], y1: [12, 9, 2.6], x2: [18, 18, 15.4], y2: [12, 9, 15.4] },
    active1: { x1: [2.6, 0, 0], y1: [2.6, 9, 12], x2: [15.4, 18, 18], y2: [15.4, 9, 12] },
  },
}

export default function MenuToggle() {
  const console = useConsole()

  const dictionary = useDictionary()
  const menu = useMenu()
  const header = useHeader()

  const [active, setActive] = useState(-1)

  const togg = useRef()
  const focusNextRender = useRef(false)

  Object.assign(ctx, {
    toggle: {
      focus: ({ keyboard }) => {
        if (keyboard) focusNextRender.current = true
      },
    },
  })

  function toggleButton(e) {
    const keyboard = !e.clientX && !e.clientY
    if (active === 1 || active === -1) {
      menu.activate({ keyboard })
    } else if (active === 0) {
      header.forceUnlock({ keyboard })
    }
  }

  useLayoutEffect(() => header.on(header.events.lock, () => startTransition(() => setActive(0))))
  useLayoutEffect(() => header.on(header.events.unlock, () => startTransition(() => setActive(1))))

  function onStateChange(state) {
    setActive(state === statesLabel.closed ? 1 : 0)
  }
  useEffect(() => menu.currentState.onChange(onStateChange))

  console.verbose("Menu:Toggle(%o)", { active })
  return (
    <Root>
      <Toggle ref={togg} onClick={toggleButton} aria-expanded={active !== 1 && active !== -1 ? "true" : "false"}>
        <svg viewBox='0 0 18 18' width='18' height='18'>
          <Line
            strokeWidth='2'
            strokeLinecap='square'
            x1={0}
            y1={5}
            x2={18}
            y2={5}
            variants={lineVariants.l1}
            animate={`active${active}`}
            initial='init'
            transition={{ duration: 0.5 }}
          />
          <Line
            strokeWidth='2'
            strokeLinecap='square'
            x1={0}
            y1={12}
            x2={18}
            y2={12}
            variants={lineVariants.l2}
            animate={`active${active}`}
            initial='init'
            transition={{ duration: 0.5 }}
          />
        </svg>
        <BtnLabel>{active === 0 ? dictionary.close() : dictionary.menu()}</BtnLabel>
      </Toggle>
    </Root>
  )
}
