import { useEffect, useRef, useState, useLayoutEffect } from "react"
import { createPortal } from "react-dom"
import FocusTrap from "focus-trap-react"
import { motion, AnimatePresence } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useMenu } from "contexts/Menu"
import { useDictionary } from "contexts/Dictionary"
import { cssVarHeight } from "contexts/Header"

import { fullGrid } from "css/grid"
import * as button from "css/buttons"
import { normal } from "css/text"

import useScrollLock from "hooks/useScrollLock"

import { Icon } from "components/icon/Icon"
import { statesLabel } from "components/menu/Pane"
import Link from "components/link/Link"
import Card, { LastCard, cardsVariants } from "components/menu/Card"

const Root = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: calc(var(--z-top, 1000));
  ${fullGrid}
  grid-template-rows: min-content auto;

  /* background: linear-gradient(90deg, #0b3e27, #197149); */
`

const RollerHeader = styled(motion.div)`
  position: relative;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-column: main;

  align-items: center;

  height: var(${cssVarHeight});
`

const BackBtn = styled.button`
  ${button.buttonCommon}
  ${normal}
  justify-self: start;

  & svg {
    transform: scaleX(-1);
  }
  [dir="rtl"] & svg.rtl {
    transform: scaleX(1);
  }

  .dark-theme & {
    @media (hover: hover) {
      &:hover {
        color: rgb(var(--ocean-green) / 1);
      }
    }
  }
`

const CTA = styled(Link)`
  justify-self: end;
  height: 30px !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
`

const RollerContainer = styled.div`
  position: relative;
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
  grid-column: main;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`

const Track = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  column-gap: var(--grid-gap);
  row-gap: calc(var(--grid-gap) * 5);

  height: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  padding-block-end: 10rem;
`

function GridContainer({ cards, cta_configure, last_card }) {
  const console = useConsole()

  const menu = useMenu()
  const dictionary = useDictionary()
  const scrollLock = useScrollLock()

  const ref = useRef()

  function onBack() {
    menu.switch(statesLabel.closegrid)
  }

  useLayoutEffect(() => {
    const r = ref.current
    scrollLock.lock(r)
    return () => scrollLock.unlock(r)
  }, [])

  const variants = {
    hide: { opacity: 0, transition: { duration: 0.3 } },
    show: { opacity: 1, transition: { duration: 0.3 } },
  }

  return (
    <>
      {/* <RollerHeader variants={variants}> */}
      <RollerHeader>
        <BackBtn className='inline' icon='chevron' onClick={onBack}>
          <Icon type='chevron' />
          {dictionary.back()}
        </BackBtn>
        <CTA {...cta_configure} className={"filled opaque-white"} icon='none'>
          {cta_configure.label}
        </CTA>
      </RollerHeader>
      <RollerContainer ref={ref}>
        <Track>
          {cards.map((card, i, a) => (
            <Card key={card._metadata.uid} {...card} meta={{ index: i, reverseIndex: a.length - i }} cardsVariants={cardsVariants.mob} />
          ))}
          <LastCard {...last_card} cardsVariants={cardsVariants.mob} />
        </Track>
      </RollerContainer>
    </>
  )
}

export default function Grid({ cards, cta_configure, last_card }) {
  const console = useConsole()

  const menu = useMenu()

  const [isVisible, seIsVisible] = useState(false)

  function onDeactivate(e) {
    if (menu.currentState.get() !== 0) menu.switch(statesLabel.closegrid)
  }

  function onStateChange(state) {
    if (state === statesLabel.rollergrid) {
      seIsVisible(true)
    } else if (state === statesLabel.opened || state === statesLabel.closed || state === statesLabel.closegrid) {
      seIsVisible(false)
    }
  }
  useEffect(() => menu.currentState.onChange(onStateChange))

  const variants = {
    hide: { x: "100%", background: "linear-gradient(90deg, #197149, #197149)", transition: { duration: 0.3, ease: "linear" } },
    show: { x: "0%", background: "linear-gradient(90deg, #0b3e27, #197149)", transition: { duration: 0.3, ease: "linear" } },
  }

  return (
    process.browser &&
    createPortal(
      <AnimatePresence>
        {isVisible && (
          <FocusTrap focusTrapOptions={{ onDeactivate: onDeactivate }}>
            <Root className='dark-theme' variants={variants} initial='hide' animate='show' exit='hide' role='dialog' aria-modal='true'>
              <GridContainer cards={cards} cta_configure={cta_configure} last_card={last_card} />
            </Root>
          </FocusTrap>
        )}
      </AnimatePresence>,
      document.body
    )
  )
}
