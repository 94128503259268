import { useMotionValue, useTransform, animate, easeOut } from "framer-motion"
import { useContext, useEffect, useLayoutEffect, useRef } from "react"
import { mvSubscribe, getOffset, getPos, updateLoadingQ } from "./../utils"

export default function FeatureProvider(props) {
  const { children, Ctx, count, touchenabled, dir, start } = props
  const focus = useMotionValue(0)
  const rmfocus = useMotionValue(start)
  const x = useMotionValue(0)
  const W = useMotionValue(0)
  const frozen = useMotionValue(false)

  function kill() {
    x.set(0)
    W.set(0)
    focus.set(0)
    rmfocus.set(null)
  }
  useEffect(() => kill, [])

  return <Ctx.Provider value={
    {
      count,
      focus,
      rmfocus,
      x,
      W,
      touchenabled,
      dir,
      frozen,
    }
  }>{children}</Ctx.Provider>
}
