import { css } from "@emotion/react"

export const link = css`
  color: rgb(var(--green));
  transition: color 0.3s, opacity 0.3s;

  @media (hover: hover) {
    :hover {
      color: black;
      text-decoration: none;
    }
  }

  .dark-theme & {
    color: inherit;

    @media (hover: hover) {
      :hover {
        opacity: 0.7;
      }
    }
  }
`
