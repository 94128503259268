import { useConsole } from "contexts/Console"

import Button from "../button/Button"
import { PopinContextProvider, usePopin } from "./PopinContextProvider"
import PopinEdito from "./PopinEdito"

function PopBtn({ icon, style, label, aria_label, theme, describedBy, className }) {
  const cl = style || "icon translucent-dark"
  const clName = theme ? `${className} ${theme} ${cl}` : `${className} ${cl}`

  const { onOpen } = usePopin()

  return (
    <Button className={clName} aria_label={aria_label} onPress={onOpen} icon={icon || "plus"} aria-describedby={describedBy}>
      {label}
    </Button>
  )
}

export default function PopinButton({ icon, iconColor, style, label, aria_label, theme, preload, reference, describedBy, className }) {
  const console = useConsole()
  const uid = reference?.[0]?.uid

  console.verbose("PopinButton(%o)", { uid })
  return (
    uid && (
      <PopinContextProvider background={reference[0].background} uid={uid} preload={preload}>
        <PopBtn
          className={className}
          label={label}
          theme={theme}
          style={style}
          icon={icon}
          iconColor={iconColor}
          aria_label={aria_label}
          describedBy={describedBy}
        />
        <PopinEdito label={label} />
      </PopinContextProvider>
    )
  )
}
