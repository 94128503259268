import { SearchBox as SearchInput } from "./SearchBox"
import Tabs from "./Tabs"
import { useSearch } from "./context"
import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"
import { fullGrid } from "css/grid"

import getMediaQuery from "css/breakpoints"

const Root = styled.div`
  ${fullGrid}
`

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  box-sizing: border-box;
  grid-column: col 1 / span 6;

  ${getMediaQuery("m")} {
    grid-column: col 4 / span 6;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  box-sizing: border-box;
  grid-column: col 1 / span 6;

  ${getMediaQuery("m")} {
    grid-column: col 4 / span 6;
  }
`

const TabsContainer = styled.div`
  display: block;
  width: 100%;

  ${getMediaQuery("m")} {
    ${fullGrid}
    grid-column: col 1 / span 6;
  }
`

export function SearchForm({ inputRef }) {
  const console = useConsole()
  const { isOnSearchResultsPage } = useSearch()

  console.verbose("SearchForm")

  return (
    <Container>
      <Root>
        <Wrapper>
          <SearchInput controller={global.coveoSearchBox} inputRef={inputRef} />
        </Wrapper>
      </Root>
      <TabsContainer>{isOnSearchResultsPage && <Tabs />}</TabsContainer>
    </Container>
  )
}
