import hash from "@emotion/hash"

// we must prefix the generated class with "css-" to prevent it starts with a number

export const ComponentHeader = `css-${hash("Header")}`
export const Headline = `css-${hash("headline")}`
export const Menu = `css-${hash("menu")}`
export const Roller = `css-${hash("roller")}`
export const Stacker = `css-${hash("stacker")}`

export const Chapo = `css-${hash("chapo")}`
export const Heading = `css-${hash("heading")}`
export const Date = `css-${hash("heading")}`
export const BigHeading = `css-${hash("heading")}`
export const Paragraph = `css-${hash("paragraph")}`
export const QuoteBlock = `css-${hash("quote-block")}`

export const GradientTitle = `css-${hash("gradient-title")}`
export const GradientChapo = `css-${hash("gradient-chapo")}`
