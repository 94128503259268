import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import { useMotionValue, transform } from "framer-motion"
import { useContext, useEffect, useLayoutEffect, useRef } from "react"
import easing from "./easing"
import { DIAL_SCALE, DIAL_OFFSET_Y, DIAL_BOUNCE, PK20_FIT, PK20_SHADOW_RATIO } from "./FrameAnimation"
import { mvSubscribe } from "./utils"

export const MAX_FRAMES = 250
export const QUALITYSIZE = {
  /* Quality format q[value] */
  Q: {
    hi: [100, 100],
    lo: [20, 20],
    start: [100, 100],
  },
  /* Size format [value]x[value] */
  S: {
    hi: [1440, 1440],
    lo: [960, 960],
    start: [1440, 1440],
  },
}

const FRAMES = [
  {
    i: 58,
    x: "0/-17",
    y: "20/5",
    s: "1.7/3.2",
    r: "82",
    a: "1"
  },
  (ratio) => ({
    i: 249,
    //// ${(DIAL_SCALE.portrait / 100) - (100 - DIAL_SCALE.portrait - DIAL_OFFSET_Y.portrait - PK20_FIT.portrait.y)}
    //    x: `${(DIAL_SCALE.landscape / 100) * PK20_FIT.landscape.x}/${(DIAL_SCALE.portrait / 100) * PK20_FIT.portrait.x}`,
    x: "0",//`${PK20_FIT.landscape.x}/${PK20_FIT.portrait.x}`,
    y: `${DIAL_OFFSET_Y.landscape * PK20_SHADOW_RATIO / PK20_FIT.landscape.s + PK20_FIT.landscape.y}/${DIAL_OFFSET_Y.portrait * PK20_SHADOW_RATIO / PK20_FIT.portrait.s + PK20_FIT.portrait.y}`,
    s: `${(DIAL_SCALE.landscape / 100) * DIAL_BOUNCE.landscape * PK20_FIT.landscape.s}/${(DIAL_SCALE.portrait / 100) * DIAL_BOUNCE.portrait * PK20_FIT.portrait.s * ratio}`,
    r: "0",
    a: "0"
  })
]
// y ${DIAL_OFFSET_Y.portrait / PK20_FIT.portrait.s + PK20_FIT.portrait.y}
// /${(DIAL_SCALE.portrait / 100) * DIAL_BOUNCE.portrait * PK20_FIT.portrait.s}

const EASINGS = {
  i: "linear",
  x: "easeInQuad",
  y: "easeInQuad",
  s: "easeInQuad",
  r: "easeInQuad",
  a: "easeInCubic/249"
}

export function trueindex(v) {
  //    console.log("trueindex", v)
  v = Math.round(v)
  v = v >= 0 ? v : MAX_FRAMES + v
  v %= MAX_FRAMES
  return v
}

function getOutrange(sfrom, sto, v) {
  let from_split = sfrom[v].split("/")
  if (from_split.length < 2) from_split[1] = from_split[0]
  let to_split = sto[v].split("/")
  if (to_split.length < 2) to_split[1] = to_split[0]
  let ret = []
  for (let i = 0; i < 2; i++) {
    let from = +from_split[i]
    let to = +to_split[i]
    if (v !== "r") ret[i] = [from, to]
    else {
      let diff = Math.abs(from - to + 1)
      let dir = diff <= 180 ? 1 : -1
      if (dir < 0) {
        if (to > from) from += 360
        else to += 360
      }
      ret[i] = [from, to]
    }
  }
  return ret
}

function init({ offsetWidth: W, offsetHeight: H }) {
  let loframes = []
  let hiframes = []
  const sfrom = FRAMES[0]
  const sto = FRAMES[1](H / W)
  //  alert(W / H)
  //  sto.y += `/ 0`
  //  sto.s += `/ 1`//${(DIAL_SCALE.portrait / 100) / ratio}`
  //  sto.s += `/${(DIAL_SCALE.portrait / 100) / (PK20_FIT.portrait.ratio * ratio) * DIAL_BOUNCE.portrait * PK20_FIT.portrait.s}`
  let dir = 0
  let ifrom = sfrom.i
  let ito = sto.i
  let diff = Math.abs(ifrom - ito)
  const shortest = diff <= MAX_FRAMES / 2
  if (shortest) {
    dir = 1
    let indexfrom = Math.min(ifrom, ito)
    loframes = Array.from({ length: diff + 1 }).map((v, i) => i + indexfrom)
  } else {
    dir = -1
    diff = MAX_FRAMES - diff
    let from = ifrom < ito ? ifrom + MAX_FRAMES : ifrom
    let to = ifrom < ito ? ito : ito + MAX_FRAMES
    let indexfrom = Math.min(from, to)
    loframes = Array.from({ length: diff + 1 }).map((v, i) => (i + indexfrom) % MAX_FRAMES)
  }
  loframes = loframes.slice(1, -1)
  loframes.push(ito)
  hiframes.push(ifrom)
  if (dir < 0) {
    if (ito > ifrom) ifrom += MAX_FRAMES
    else ito += MAX_FRAMES
  }
  let inrange = [ifrom, ito]
  let range = Math.abs(ifrom - ito)
  let outrange
  //  let trans = "i,x,y,s,r,a".split(",").reduce(
  let trans = "i,x,y,s,r".split(",").reduce(
    (a, v) => {
      let trange = [0, range]
      let [ease, keyframe] = EASINGS[v].split("/")
      if (keyframe) {
        keyframe = +keyframe
        keyframe = keyframe < ito ? keyframe + MAX_FRAMES : keyframe
        keyframe = range - 1 - (keyframe - ito)
        trange = [0, keyframe, range - 1]
        outrange = [+sfrom[v], +sfrom[v], +sto[v]]
        outrange = [outrange, outrange]
      }
      else {
        outrange = v === "i" ? [inrange, inrange] : getOutrange(sfrom, sto, v)
      }
      for (let i = 0; i < 2; i++) a[i][v] = transform(trange, outrange[i], { ease: easing[ease] })
      return a
    },
    [Object.create(null), Object.create(null)]
  )
  loframes = loframes.join(",")
  hiframes = hiframes.join(",")
  return { range, trans, loframes, hiframes }
}

export function InstallAnimationProvider(props) {
  const { children, Ctx, size } = props
  const frames = useRef(new Map())
  const canvasctx = useRef(null)
  const PARAMS = init(size)

  function clearFrames() {
    return new Promise(resolve => {
      ;[...frames.current.entries()].map(([k, { src }]) => {
        if (typeof src === "string" && src.indexOf("blob:") === 0) {
          URL.revokeObjectURL(src)
          //          console.log("clearFrames revoked", src)
        }
        frames.current.delete(k)
      })

      resolve()
    })
  }

  function kill() {
    clearFrames()
  }
  useEffect(() => kill, [])

  return (
    <Ctx.Provider
      value={{
        frames,
        clearFrames,
        canvasctx,
        PARAMS
      }}
    >
      {children}
    </Ctx.Provider>
  )
}

export function useInstallAnimation(ctx) {
  return useContext(ctx)
}
