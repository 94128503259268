import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { cssVarExpansion, cssVarTransitionDuration, cssVarTransitionDelay } from "contexts/Header"

export const SearchRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: 1.2rem;
  padding-block-end: 1.2rem;
  width: 100%;
`

export const SearchButton = styled.button`
  display: block;
  position: absolute;
  line-height: unset;
  border: none;
  background: none;
  padding: 5px 10px;
  inset-inline-end: 7px;

  cursor: pointer;
  opacity: 1;
  svg {
    margin-top: 5px;
    fill: rgb(var(--pure-white));
  }

  ${getMediaQuery("m")} {
    inset-inline-end: 21px;
  }
  ${getMediaQuery("m")} {
    ${({ searchButtonActive }) =>
      searchButtonActive &&
      `
    &:hover {
      svg {
        fill: rgb(var(--ocean-green) / 1) !important;
      }
    }
    `};
  }
`

export const SearchInput = styled.input`
  font-family: var(--primary-font-family);
  height: 40px;
  box-sizing: border-box;
  position: relative;
  transition: opacity 0.2s;
  width: 100%;
  padding: 0;
  padding-inline-start: 35px;
  padding-inline-end: 70px;
  position: relative;
  border-radius: 30px;
  border: none;
  color: rgb(var(--pure-white));
  background: rgb(var(--pure-white) / 0.1);
  &:focus {
    outline-width: 0px;
    outline: none;
  }

  ${getMediaQuery("m", { max: true })} {
    padding-inline-start: 20px;
  }

  ${getMediaQuery("l")} {
    padding-inline-end: 100px;
  }

  ::placeholder {
    color: rgb(var(--pure-white) / 0.8);
    opacity: 0.4;
    font-size: 18px;
  }

  ${getMediaQuery("m", { max: true })} {
    width: 100%;
    border: none;
    :placeholder-shown {
      width: 100%;
      text-overflow: ellipsis;
    }
  }
`
