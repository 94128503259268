import { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { animate, motion, transform, useMotionValue, useTransform } from "framer-motion"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"

import Card from "./cards/Card"
import { usePlayer } from "./context"
import Header from "./Header"
import { ProgressProvider } from "./progressContext"

const Container = styled(motion.section)`
  ${fullGrid}

  grid-row: doc;
  grid-column: doc;

  user-select: none;
  transform-origin: center center;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 30px;
  z-index: 0;

  grid-template-rows: [doc-start] calc(var(--outer-margin)) [head] 0px min-content [doc-end];

  ${getMediaQuery("m")} {
    grid-template-rows: [doc-start] calc(var(--outer-margin) - var(--grid-gap) / 2) [head] 0px auto [doc-end];
  }
  ${getMediaQuery("l")} {
    grid-template-rows: [doc-start] calc((var(--outer-margin) - var(--grid-gap)) / 2) [head] 0px auto [doc-end];
  }
`

function StoryView({ uid, cards, story_id, indexOfStory, num, openId }) {
  const console = useConsole()
  const viewport = useViewport()

  const { dragProg, isHolding, storyIndex, dir, currentStoryIndex, shouldReduceMotion } = usePlayer()

  const [isCurrent, setIsCurrent] = useState(storyIndex.get() === indexOfStory)

  const titleSubtitle = useMotionValue(":")
  const currentTheme = useMotionValue(Object.values(cards[0])[0]?.theme || "dark-theme")

  const x = useMotionValue((indexOfStory - storyIndex.get()) * viewport.width.get())
  const input = useTransform(viewport.width, w => [dir * -w, (dir * -w) / 2, 0, (dir * w) / 2, dir * w])
  const scale = useTransform([x, input], ([x, i]) => transform(x, i, [0.7, 0.8, 1, 0.8, 0.7]))
  const opacity = useMotionValue(1)

  const anim = useRef()

  function onW(w) {
    x.set((indexOfStory - storyIndex.get()) * w)
  }
  useEffect(() => viewport.width.onChange(onW))

  const ondragprog = d => {
    if (!shouldReduceMotion) {
      const ratio = Math.abs(d) / (viewport.width.get() / 3)
      const f = (storyIndex.get() === 0 && d * dir > 0) || (storyIndex.get() === num - 1 && d * dir < 0) ? 1 / (ratio + 1) : 1
      const nextPos = (indexOfStory - storyIndex.get()) * viewport.width.get() * dir
      const pp = nextPos + d * f
      x.set(pp)
    }
  }
  useEffect(() => dragProg.onChange(ondragprog))

  function animateTo(pos) {
    const dur = Math.abs((pos - x.get()) / viewport.width.get()) * 0.4
    anim.current = animate(x, pos, {
      duration: dur,
      ease: "linear",
      onComplete: () => currentStoryIndex.set(storyIndex.get()),
    })
  }

  function reducedAnimateTo(cur, prev) {
    if (cur === indexOfStory) {
      opacity.set(cur > prev ? 0 : 1)
      x.set(0)
      animate(opacity, 1, { duration: 0.3, ease: "linear" })
    } else if (prev === indexOfStory) {
      animate(opacity, cur > prev ? 1 : 0, { duration: 0.3, ease: "linear", onComplete: () => x.set((cur - prev) * viewport.width.get() * dir) })
    }
  }

  function onStoryIndexChange(i) {
    setIsCurrent(indexOfStory === i)
    if (shouldReduceMotion) {
      reducedAnimateTo(i, storyIndex.prev)
    } else {
      animateTo((indexOfStory - i) * viewport.width.get() * dir)
    }
  }
  useEffect(() => storyIndex.onChange(onStoryIndexChange))

  function onHoldingChange(bool) {
    if (!shouldReduceMotion) {
      if (bool) {
        !!anim.current && anim.current.stop()
      } else {
        animateTo((indexOfStory - storyIndex.get()) * viewport.width.get() * dir)
      }
    }
  }
  useEffect(() => isHolding.onChange(onHoldingChange))

  return (
    <Container id={uid} role='group' aria-hidden={!isCurrent} style={{ x, scale, opacity }} aria-live='polite'>
      <Header currentTheme={currentTheme} titleSubtitle={titleSubtitle} />
      {cards.map((c, i) => (
        <Card
          key={`${story_id}-${i}`}
          storyId={story_id}
          slugId={`${story_id}-${i}`}
          indexOfStory={indexOfStory}
          index={i}
          {...Object.values(c)[0]}
          currentTheme={currentTheme}
          openId={openId}
          titleSubtitle={titleSubtitle}
        />
      ))}
    </Container>
  )
}

export default function Story({ ...story }) {
  return (
    <ProgressProvider story_id={story.story_id} cards={story.cards} storyNumber={story.num}>
      <StoryView {...story} />
    </ProgressProvider>
  )
}
