import { useRef } from "react"

import { useConsole } from "contexts/Console"

import VideoPlayerCLD from "components/media/VideoPlayerCLD"
import Button from "components/button/Button"

export default function VideoLauncher(props) {
  const console = useConsole()
  const rvideo = useRef()

  const {
    video_player_cld: { launcher_label, style, ...video_player_cld },
    ...rest
  } = props

  const onPress = () => {
    rvideo?.current?.play()
  }

  console.verbose("VideoLauncher(%o)", props)
  return (
    <>
      <Button style={style} {...rest} onPress={onPress} icon='play'>
        {launcher_label}
      </Button>
      <VideoPlayerCLD ref={rvideo} {...video_player_cld} />
    </>
  )
}
