import { useEffect, useState, forwardRef } from "react"
import { motion, useMotionValue } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import Button from "components/button/Button"

import { usePlayer } from "../context"
import { getNextSlug, getPrevSlug, getSlugIndex, getStoryIndex } from "../utils"

const Root = styled.div`
  ${fullGrid}
  grid-template-rows: 1fr;
  grid-column: doc;
  grid-row: doc;
  align-self: center;
  z-index: 1;
`

const Marrow = styled(motion.div)`
  justify-self: center;
  grid-row: 1;

  &.previous {
    justify-self: end;
    grid-column: doc-start;
    margin-inline-end: calc(var(--btn-height) / -2 - var(--grid-gap));
  }
  &.next {
    justify-self: start;
    grid-column: main-end;
    margin-inline-start: calc(var(--btn-height) / -2 - var(--grid-gap));
  }

  ${getMediaQuery("m")} {
    &.previous {
      margin-inline-end: calc(var(--btn-height) / -2 - var(--grid-gap) / 2);
    }
    &.next {
      margin-inline-start: calc(var(--btn-height) / -2 - var(--grid-gap) / 2);
    }
  }

  ${getMediaQuery("l")} {
    &.previous,
    &.next {
      justify-self: center;
      margin-inline: unset;
    }
  }
`

const Arrow = styled(Button)`
  pointer-events: all;
  [dir="rtl"] & svg {
    transform: scale(-1);
  }
`

function Arrows({}, ref) {
  const console = useConsole()
  const dictionary = useDictionary()

  const alNextStory = dictionary.ariaLabelNextStory()
  const alNextSlide = dictionary.ariaLabelNextSlide()
  const alPreviousStory = dictionary.ariaLabelPreviousStory()
  const alPreviousSlide = dictionary.ariaLabelPreviousSlide()

  const { slug, slugList, slugMap } = usePlayer()

  const [nextAriaLab, setNextAriaLab] = useState(
    slugMap[getStoryIndex(slugMap, slug.get())].indexOf(slug.get()) === slugMap[getStoryIndex(slugMap, slug.get())].length - 1 ? alNextStory : alNextSlide
  )
  const [prevAriaLab, setPrevAriaLab] = useState(slugMap[getStoryIndex(slugMap, slug.get())].indexOf(slug.get()) === 0 ? alPreviousStory : alPreviousSlide)

  const prevVisible = useMotionValue(getSlugIndex(slugList, slug.get()) === 0 ? "hidden" : "visible")
  const nextVisible = useMotionValue(getSlugIndex(slugList, slug.get()) === slugList.length - 1 ? "hidden" : "visible")

  function previous() {
    slug.set(getPrevSlug(slugList, slug.get()))
  }
  function next() {
    slug.set(getNextSlug(slugList, slug.get()))
  }

  function onSlugChange(s) {
    prevVisible.set(getSlugIndex(slugList, s) === 0 ? "hidden" : "visible")
    nextVisible.set(getSlugIndex(slugList, s) === slugList.length - 1 ? "hidden" : "visible")

    const arr = slugMap[getStoryIndex(slugMap, s)]

    setNextAriaLab(arr.indexOf(s) === arr.length - 1 ? alNextStory : alNextSlide)
    setPrevAriaLab(arr.indexOf(s) === 0 ? alPreviousStory : alPreviousSlide)
  }
  useEffect(() => slug.onChange(onSlugChange))

  return (
    <Root className='dark-theme' ref={ref}>
      <Marrow className='previous' style={{ visibility: prevVisible }}>
        <Arrow onPress={previous} icon='movePrevious' className='icon translucent-dark' aria-label={prevAriaLab} />
      </Marrow>
      <Marrow className='next' style={{ visibility: nextVisible }}>
        <Arrow onPress={next} icon='moveNext' className='icon translucent-dark' aria-label={nextAriaLab} />
      </Marrow>
    </Root>
  )
}

export default forwardRef(Arrows)
