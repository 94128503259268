/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext } from "react"

export const ctx__ONLY_FOR_SUBNAV__ = {}
const Context = createContext(ctx__ONLY_FOR_SUBNAV__)
export const Provider = ({ children }) => children

export function useSubNav() {
  return useContext(Context)
}
