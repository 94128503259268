import { css } from "@emotion/react"

export const spacing = css`
  &.margin-top-s-pos {
    margin-block-start: var(--s-h-space);
  }
  &.margin-top-m-pos {
    margin-block-start: var(--m-h-space);
  }
  &.margin-top-l-pos {
    margin-block-start: var(--l-h-space);
  }
  &.margin-top-xl-pos {
    margin-block-start: var(--xl-h-space);
  }
  &.margin-top-s-neg {
    margin-block-start: calc(var(--s-h-space) * -1);
  }
  &.margin-top-m-neg {
    margin-block-start: calc(var(--m-h-space) * -1);
  }
  &.margin-top-l-neg {
    margin-block-start: calc(var(--l-h-space) * -1);
  }
  &.margin-top-xl-neg {
    margin-block-start: calc(var(--xl-h-space) * -1);
  }
  &.margin-bot-s-pos {
    margin-block-end: var(--s-h-space);
  }
  &.margin-bot-m-pos {
    margin-block-end: var(--m-h-space);
  }
  &.margin-bot-l-pos {
    margin-block-end: var(--l-h-space);
  }
  &.margin-bot-xl-pos {
    margin-block-end: var(--xl-h-space);
  }
  &.margin-bot-s-neg {
    margin-block-end: calc(var(--s-h-space) * -1);
  }
  &.margin-bot-m-neg {
    margin-block-end: calc(var(--m-h-space) * -1);
  }
  &.margin-bot-l-neg {
    margin-block-end: calc(var(--l-h-space) * -1);
  }
  &.margin-bot-xl-neg {
    margin-block-end: calc(var(--xl-h-space) * -1);
  }

  &.padding-top-pos-none {
    padding-block-start: 0;
  }
  &.padding-top-s-pos {
    padding-block-start: var(--s-h-space);
  }
  &.padding-top-m-pos {
    padding-block-start: var(--m-h-space);
  }
  &.padding-top-l-pos {
    padding-block-start: var(--l-h-space);
  }
  &.padding-top-xl-pos {
    padding-block-start: var(--xl-h-space);
  }
  &.padding-bot-pos-none {
    padding-block-end: 0;
  }
  &.padding-bot-s-pos {
    padding-block-end: var(--s-h-space);
  }
  &.padding-bot-m-pos {
    padding-block-end: var(--m-h-space);
  }
  &.padding-bot-l-pos {
    padding-block-end: var(--l-h-space);
  }
  &.padding-bot-xl-pos {
    padding-block-end: var(--xl-h-space);
  }
`
