import { useEffect, useLayoutEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import getMediaQuery from "css/breakpoints"
import { useFrameAnimation } from "./FrameAnimation"
import { mvSubscribe } from "./utils"
import { headline70, headline50 } from "css/text"
import { CfgActiveTitle, CfgActiveWishlist } from "./active"
import { fullGrid } from "css/grid"
import { SWAP_IN, SWAP_OUT, INSTALL_ECO_TRANSITION } from "./constants"

const Main = styled.section`
  user-select: none;
  --spacing: 1rem;
  position: relative;
  z-index: 20;
  grid-row: 1;
  grid-column: 1/-1;
  height: fit-content;
  justify-self: start;
  align-self: start;
  padding-block: var(--spacing) 0;
  //    padding-inline: var(--outer-margin) calc(var(--outer-margin) + var(--btn-height));
  ${fullGrid}
  display: none;

  ${SWAP_OUT}

  &.wv_reveal {
    ${SWAP_IN}
  }

  .cfg-install & {
    transition: ${INSTALL_ECO_TRANSITION};
  }

  & > h2 {
    grid-column: 2 / span 6;
    /* margin-inline: calc(var(--grid-gap) * -1) 0; */
    ${headline50}
  }

  ${getMediaQuery("m")} {
    display: grid;
    --spacing: 3.125rem;
    & > h2 {
      ${headline70}
    }
  }
`

export function CfgHeader({ Ctx }) {
  const { settled } = useFrameAnimation(Ctx)
  const rfmain = useRef(null)

  function display(state) {
    rfmain.current?.classList[state ? "add" : "remove"]("wv_reveal")
  }

  function onSettled(v) {
    display(v > 0)
  }
  useLayoutEffect(() => mvSubscribe(settled, onSettled), [])

  return (
    <Main ref={rfmain} className='dark-theme'>
      {/*<CfgActiveWishlist Ctx={Ctx} />*/}
      <CfgActiveTitle Ctx={Ctx} id={"config-modal-heading"} />
    </Main>
  )
}
