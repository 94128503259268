import React, { useRef } from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { fullGrid } from "css/grid"
import { headline100, surtitle100, body100, light } from "css/text"
import { Chapo as ChapoClass } from "css/classnames"
import { Heading as HeadingClass } from "css/classnames"

import Video, { cssVideo, cssContainer, cssPoster, cssFallback } from "components/media/VideoCLD"
import Image from "components/media/ImageCLD"
import Heading from "components/text/Heading"
import Chapo from "components/text/Chapo"
import Link from "components/link/Link"

const Li = styled.li`
  position: relative;
  justify-content: center;

  height: 175vh;
  margin-block-end: -75vh;

  z-index: 0;

  &:last-of-type {
    height: 100vh;
    margin-block-end: 0;
  }
`

export const Wrapper = styled(Link)`
  position: relative;
  width: 100%;
  height: 100%;
  text-decoration: none;

  ${fullGrid}
  grid-template-rows: [top-row] 1fr 1fr [bottom-row];
`

const VideoContainer = styled.div`
  height: 100vh;
  grid-column: doc;
  grid-row: top-row / bottom-row;

  & .${cssContainer} {
    z-index: -1;
    height: 100%;

    & .${cssVideo}, .${cssPoster}, .${cssFallback} {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const ImageContainer = styled.div`
  height: 100vh;
  grid-column: doc;
  grid-row: top-row / bottom-row;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const TextContainer = styled.div`
  grid-column: doc;
  grid-row: 1;
  ${fullGrid}
  height: 100vh;
  position: sticky;
  top: 0;

  li:first-of-type & {
    top: clamp(5rem, 3.4375rem + 3.9063vw, 6.875rem);
  }
  li:first-of-type & > div {
    margin-block-start: calc(clamp(5rem, 3.4375rem + 3.9063vw, 6.875rem) * -1);
  }
`

const TextBlock = styled(motion.div)`
  color: white;
  text-align: center;

  align-self: center;

  grid-column: main;

  a.focus-visible & {
    outline: 2px solid rgb(var(--focus, 0 255 255) / 1) !important;
    outline-offset: 3px !important;
  }

  hgroup {
    justify-items: center;
    & h2 {
      ${headline100}
    }
    & .kicker,
    & .subtitle {
      ${surtitle100}
      margin-block-end: 5px;
    }
  }

  hgroup:not(:last-child) {
    margin-block-end: 10px;
  }

  & .${ChapoClass} {
    ${body100}
    ${light}
  }

  ${getMediaQuery("m")} {
    grid-column: col 3 / span 8;
    hgroup {
      & .kicker,
      & .subtitle {
        margin-block-end: 10px;
      }
    }
  }

  ${getMediaQuery("l")} {
    grid-column: col 3 / span 8;

    hgroup {
      & .kicker,
      & .subtitle {
        margin-block-end: 20px;
      }
    }
    hgroup:not(:last-child) {
      margin-block-end: 30px;
    }
  }

  html.prefers-contrast & {
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 30px);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      background: white;
      z-index: -1;
    }
    color: black;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
  grid-column: doc;
  grid-row: top-row / bottom-row;
  background: linear-gradient(transparent, rgb(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);
`

function VideoItem({ video }) {
  const vidRef = useRef()
  const vidCont = useRef()

  const console = useConsole()

  return (
    <VideoContainer ref={vidCont}>
      <Video ref={vidRef} autoPlay noButton {...video} sizes='100vw' />
      <Overlay />
    </VideoContainer>
  )
}
function ImageItem({ image }) {
  const console = useConsole()

  return (
    <ImageContainer>
      <Image {...image} sizes='100vw' />
      <Overlay />
    </ImageContainer>
  )
}

export default function StackItem({ link, heading, chapo, media }) {
  const console = useConsole()

  const ref = useRef()
  const refCont = useRef()

  function onFocus() {
    refCont.current.scrollIntoView()
  }

  return (
    <Li onFocusCapture={onFocus} ref={refCont}>
      <Wrapper {...link} icon='none'>
        {media[0]?.video ? <VideoItem video={media[0]?.video} /> : media[0]?.image ? <ImageItem image={media[0]?.image} /> : null}
        <TextContainer>
          <TextBlock ref={ref}>
            {heading && <Heading {...heading} className={HeadingClass} />}
            {chapo && <Chapo className={ChapoClass} text={chapo} />}
          </TextBlock>
        </TextContainer>
      </Wrapper>
    </Li>
  )
}
