import styled from "@emotion/styled"
import { motion } from "framer-motion"
import getMediaQuery from "css/breakpoints"
import { cssVarHeight as cssVarHeaderHeight } from "contexts/Header"

export const Root = styled(motion.div)`
  position: relative;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  margin: 0;
  width: 86vw;

  ${getMediaQuery("m")} {
    min-width: 576px;
    width: 56vw;
  }

  ${getMediaQuery("l")} {
    width: 38vw;
  }
  ${getMediaQuery("m", { max: true })} {
    // height: 100vh;
    height: auto;
  }
  grid-column: doc;
  top: 0;
  --fx: 0.4s;
  --delay: calc((1 - var(--t, 0)) * 600ms);
  --t: calc(70px + var(${cssVarHeaderHeight}));
`

export const SuggList = styled.div`
  padding-inline: 35px 32px;
  padding-block-start: 20px;
  display: grid;
  align-items: center;

  ${getMediaQuery("m", { max: true })} {
    padding: 20px 16px 30px 0px;
    padding-inline-start: 0px;
  }
`