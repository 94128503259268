import styled from "@emotion/styled"
import { useConsole } from "contexts/Console"
import { headline50 } from "css/text"

const Para = styled.p`
  ${headline50}
  ${({ noGradient }) =>
    noGradient &&
    `
    background: unset !important;
    background-clip: unset !important;
    -webkit-text-fill-color: unset !important;
  `}
`

const H2 = styled.h2`
  ${headline50}
  ${({ noGradient }) =>
    noGradient &&
    `
    background: unset !important;
    background-clip: unset !important;
    -webkit-text-fill-color: unset !important;
  `}
`

const H3 = styled.h3`
  ${headline50}
  ${({ noGradient }) =>
    noGradient &&
    `
    background: unset !important;
    background-clip: unset !important;
    -webkit-text-fill-color: unset !important;
  `}
`

export default function Chapo({ text, no_gradient: noGradient, className, doubled, isHeading }) {
  const console = useConsole()

  console.verbose("chapo(%o)", { text, className })

  return doubled ? (
    <H3 noGradient={noGradient} className={`chapo ${className}`} dangerouslySetInnerHTML={{ __html: text }} />
  ) : isHeading ? (
    <H2 noGradient={noGradient} className={`chapo ${className}`} dangerouslySetInnerHTML={{ __html: text }} />
  ) : (
    <Para noGradient={noGradient} className={`chapo ${className}`} dangerouslySetInnerHTML={{ __html: text }} />
  )
}
