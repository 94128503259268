import { useRef } from "react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import useParallax from "hooks/useParallax"

import Image from "components/media/ImageCLD"
import Video from "components/media/VideoCLD"
import BlockText from "components/push/BlockText"
import { Container, Frame } from "components/push/commons"

const Root = styled.aside`
  cursor: pointer;
  position: relative;
  * {
    pointer-events: none;
  }

  a,
  button {
    pointer-events: auto;
  }
`

export default function Push(props) {
  const {
    className,
    link,
    heading,
    chapo,
    paragraph,
    image_cld: image, // 'push' on hub filters
    video_cld: video, // 'push' on hub filters
    poster,
    text_width,
    marie_louise,
    parallax,
    ...rest
  } = props
  const console = useConsole()

  const videoProps = video?.sources?.length ? video : poster?.[0]?.video_cld ?? poster?.[1]?.video_cld
  const imageProps = image?.sources?.length ? image : poster?.[0]?.image_cld ?? poster?.[1]?.image_cld

  const ref = useRef()

  const y = useParallax(ref)

  function onClick(e) {
    if (e.target.tagName === "A") return
    const linkEl = e.target.getElementsByTagName("a")
    linkEl[0].click()
  }

  return (
    <Root className={className} onClick={onClick} ref={ref} {...rest}>
      <Container style={{ "--ypos": parallax ? y : null, "--scale": parallax ? "1.2" : "1" }}>
        <BlockText heading={heading} chapo={chapo} paragraph={paragraph} link={link} text_width={text_width} />
        <figure>
          {videoProps && <Video autoPlay noButton {...videoProps} sizes='100vw' />}
          {imageProps && <Image {...imageProps} sizes='100vw' />}
        </figure>
      </Container>
      {marie_louise && <Frame />}
    </Root>
  )
}
