import { css } from "@emotion/react"
import hash from "@emotion/hash"

import getMediaQuery from "css/breakpoints"

export const removeMarksCN = `removem${hash("remove-marks")}`

export const primaryFont = css`
  font-family: var(--primary-font-family);
`
export const secondaryFont = css`
  font-family: var(--secondary-font-family);
  letter-spacing: var(--secondary-letter-spacing);
  text-transform: var(--secondary-text-transform);
`

export const quoteFont = css`
  font-family: var(--quote-font-family);
  font-style: var(--quote-font-style);
  font-weight: var(--quote-font-weight);

  figure:not(.${removeMarksCN}) & {
    ::before,
    ::after {
      display: inline;
    }

    ::before {
      content: var(--quote-opening);
    }

    ::after {
      content: var(--quote-closing);
    }
  }
`

export const bold = css`
  font-weight: 700;
`
export const normal = css`
  font-weight: 400;
`
export const light = css`
  font-weight: 300;
`

/*
  Utility for :
  https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft
  min viewport: 640px
  max viewport: 1920px
  1rem === 16px
*/

// 44px > 100px
export const headline100 = css`
  ${bold}
  font-size: clamp(2.75rem, 1rem + 4.37vw, 6.25rem);
  line-height: 1;
  &:lang(ar),
  &:lang(fa),
  &:lang(he),
  &:lang(hi),
  &:lang(ja),
  &:lang(ko),
  &:lang(vi),
  &:lang(zh-hant),
  &:lang(zh-hans) {
    line-height: 1.143;
  }
  &:lang(th) {
    line-height: 1.25;
  }
  ${getMediaQuery("m")} {
    line-height: 0.95;
    &:lang(ar),
    &:lang(fa),
    &:lang(he),
    &:lang(hi),
    &:lang(ja),
    &:lang(ko),
    &:lang(vi),
    &:lang(zh-hant),
    &:lang(zh-hans) {
      line-height: 1.143;
    }
    &:lang(th) {
      line-height: 1.25;
    }
  }
`

// 36px > 70px
export const headline70 = css`
  ${bold}
  font-size: clamp(2.25rem, 1.1875rem + 2.6563vw, 4.375rem);
  line-height: 1.18;
  &:lang(th) {
    line-height: 1.25;
  }
  ${getMediaQuery("m")} {
    line-height: 1;
    &:lang(ar),
    &:lang(fa),
    &:lang(he),
    &:lang(hi),
    &:lang(ja),
    &:lang(ko),
    &:lang(vi),
    &:lang(zh-hant),
    &:lang(zh-hans) {
      line-height: 1.143;
    }
    &:lang(th) {
      line-height: 1.25;
    }
    padding-bottom: 0.45rem;
  }
`

// 30px > 50px
export const headline50 = css`
  ${bold}
  font-size: clamp(1.875rem, 1.25rem + 1.5625vw, 3.125rem);
  line-height: 1.2;
`

// 22px > 26px
export const headline30 = css`
  ${bold}
  font-size: clamp(1.375rem, 1.25rem + 0.3125vw, 1.625rem);
  line-height: 1.31;
`

// 24px > 40px
export const body250 = css`
  font-size: clamp(1.5rem, 1rem + 1.25vw, 2.5rem);
  line-height: 1.433;
`

// 24px > 40px
export const body200 = css`
  font-size: clamp(1.25rem, 0.8725rem + 1.25vw, 1.5rem);
  line-height: 1.1;
`

// 20px > 30px
export const body100 = css`
  font-size: clamp(1.25rem, 0.9375rem + 0.7813vw, 1.875rem);
  line-height: 1.334;
`

// 18px > 24px
export const body70 = css`
  font-size: clamp(1.125rem, 0.9375rem + 0.4688vw, 1.5rem);
  line-height: 1.585;
`

// 18px > 20px
export const body50 = css`
  font-size: clamp(1.125rem, 1.0625rem + 0.1563vw, 1.25rem);
  line-height: 1.6;
`

// 14px > 34px
export const legend150 = css`
  ${bold}
  font-size: clamp(0.875rem, 0.25rem + 1.5625vw, 2.125rem);
  line-height: 1;
  &:lang(ar),
  &:lang(fa),
  &:lang(he),
  &:lang(hi),
  &:lang(ja),
  &:lang(ko),
  &:lang(th),
  &:lang(vi),
  &:lang(zh-hant),
  &:lang(zh-hans) {
    line-height: 1.143;
  }
`

// 14px > 28px
export const legend120 = css`
  ${light}
  font-size: clamp(0.875rem, 0.4375rem + 1.0938vw, 1.75rem);
  line-height: 1.075;
  &:lang(ar),
  &:lang(fa),
  &:lang(he),
  &:lang(hi),
  &:lang(ja),
  &:lang(ko),
  &:lang(th),
  &:lang(vi),
  &:lang(zh-hant),
  &:lang(zh-hans) {
    line-height: 1.143;
  }
`

// 16px > 18px
export const legend110 = css`
  font-size: clamp(1rem, 0.9375rem + 0.1563vw, 1.125rem);
  line-height: 1.375;
`

// 12px > 16px
export const legend100 = css`
  font-size: clamp(0.75rem, 0.625rem + 0.3125vw, 1rem);
  line-height: 1.375;
`

// 10px > 14px
export const legend80 = css`
  font-size: clamp(0.625rem, 0.5rem + 0.3125vw, 0.875rem);
  line-height: 1.43;
`

// 13px > 20px
export const surtitle100 = css`
  ${secondaryFont}
  ${normal}
  --surtitle100-font-size: clamp(0.8125rem, 0.5938rem + 0.5469vw, 1.25rem);
  font-size: var(--surtitle100-font-size);
  &:lang(ja) {
    font-size: calc(var(--surtitle100-font-size) * 1.4);
  }
  line-height: 1.4;
`

// 11px > 14px
export const surtitle70 = css`
  ${secondaryFont}
  ${normal}
  --surtitle70-font-size: clamp(0.6875rem, 0.5938rem + 0.2344vw, 0.875rem);
  font-size: var(--surtitle70-font-size);
  &:lang(ja) {
    font-size: calc(var(--surtitle70-font-size) * 1.4);
  }
  line-height: 1.43;
`

// 9px > 12px
export const surtitle50 = css`
  ${secondaryFont}
  ${normal}
  --surtitle50-font-size: clamp(0.5625rem, 0.4688rem + 0.2344vw, 0.75rem);
  font-size: var(--surtitle50-font-size);
  &:lang(ja) {
    font-size: calc(var(--surtitle50-font-size) * 1.4);
  }
  line-height: 1.42;
`
// 9px > 11px
export const surtitle45 = css`
  ${secondaryFont}
  ${normal}
  --surtitle45-font-size: clamp(0.5625rem, 0.5000rem + 0.1563vw, 0.6875rem);
  font-size: var(--surtitle45-font-size);
  &:lang(ja) {
    font-size: calc(var(--surtitle45-font-size) * 1.4);
  }
  line-height: 1.42;
`

// 30px > 70px
export const quote100 = css`
  ${quoteFont}
  font-size: clamp(1.875rem, 0.6250rem + 3.1250vw, 4.375rem);
  line-height: 1.3;
  ${getMediaQuery("s", { max: true })} {
    line-height: 1.4;
  }
`

// 24px > 50px
export const quote70 = css`
  ${quoteFont}
  font-size: clamp(1.5rem, 0.6875rem + 2.0313vw, 3.125rem);
  line-height: 1.3;
  ${getMediaQuery("s", { max: true })} {
    line-height: 1.4;
  }
`

// ========================
// Utilities
// ------------------------

// Alternatively import { VisuallyHidden } from "@react-aria/visually-hidden"
export const visuallyHidden = css`
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

// Clamp the text to the number of lines specified
export function clamp(lines = 2) {
  return css`
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
  `
}

export const hideText = css`
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
`
