import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { headline50 } from "css/text"

const H2 = styled.h2`
  ${headline50}
`

export default function Title({ text }) {
  const console = useConsole()

  return <H2>{text}</H2>
}
