import { useRef } from "react"
import { motion, useInView } from "framer-motion"
import styled from "@emotion/styled"

import { slideUp } from "animations/slideUp"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { headline50, surtitle50, body100, light } from "css/text"
import { Heading as HeadingCN } from "css/classnames"
import { Chapo as ChapoClass } from "css/classnames"
import { Paragraph as ParagraphClass } from "css/classnames"

import Heading, { KickerCN, SubtitleCN } from "components/text/Heading"
import Chapo from "components/text/Chapo"
import TextBlock from "components/text/TextBlock"
import Link from "components/link/Link"
import { textWidths } from "components/push/commons"

const Root = styled(motion.div)`
  grid-column: 2 / span 5;
  grid-row: 1 / -1;
  margin-block-end: 16vw;

  html.prefers-contrast & {
    > a {
      position: relative;
      z-index: 1;
    }
  }

  ${getMediaQuery("m")} {
    margin-block-end: 5vw;
    grid-column: 2 / span 6;

    &.${textWidths.small} {
      grid-column: 2 / span 5;
    }
    &.${textWidths.medium} {
      grid-column: 2 / span 6;
    }
    &.${textWidths.large} {
      grid-column: 2 / span 8;
    }

    [dir="rtl"] & {
      grid-column: 10 / span 4;
    }
  }

  ${getMediaQuery("l")} {
    margin-block-end: 8vw;
  }

  .${HeadingCN}, .text-block {
    text-align: start;
  }

  .${KickerCN}, .${SubtitleCN} {
    ${surtitle50}
  }

  hgroup.${HeadingCN} > h2,
  h2.${HeadingCN} {
    ${headline50}
    padding: 0;
    margin-block-end: 0.7rem;
    text-wrap: balance;
    html.prefers-contrast & {
      position: relative;
      z-index: 1;
    }
  }

  .text-block {
    margin-block-end: 0.7rem;
    ${body100}
    html.prefers-contrast & {
      position: relative;
      z-index: 1;
    }
  }

  .${ChapoClass} {
    ${light}
  }

  // A11y
  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: 0;
    }
  }
`

export default function BlockText({ heading, chapo, paragraph, link, text_width, children }) {
  const console = useConsole()

  const textRef = useRef()

  const isTextInView = useInView(textRef, { margin: "100% 0% 0% 0%" })
  const prefersReducedMotion = process.browser && document.documentElement.classList.contains("prefers-reduced-motion")

  return (
    <Root
      className={`text-block ${textWidths[text_width || "medium"]}`}
      ref={textRef}
      variants={slideUp}
      initial='hidden'
      animate={prefersReducedMotion ? "show" : isTextInView ? "show" : "hide"}
    >
      {heading && <Heading className={HeadingCN} {...heading} />}
      {chapo && <Chapo className={ChapoClass} text={chapo} />}
      {paragraph && <TextBlock className={ParagraphClass}>{paragraph}</TextBlock>}
      {link?.label && <Link {...link}>{link.label}</Link>}
      {children}
    </Root>
  )
}
