import { css } from "@emotion/react"

export const webFonts = statics => css`
  /* Helvetica Now Light */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextLight_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextLight_normal_normal.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  /* Helvetica Now Light Italic */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextLightItalic_italic_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextLightItalic_italic_normal.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  /* Helvetica Now Regular */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextRegular_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextRegular_normal_normal.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  /* Helvetica Now Regular Italic */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextItalic_italic_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextItalic_italic_normal.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  /* Helvetica Now Bold */
  @font-face {
    font-family: "Helvetica Now Text";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextBold_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaNow/HelveticaNowTextBold_normal_normal.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }

  /* SangBleuKingdom Light */
  @font-face {
    font-family: "SangBleuKingdom";
    src: url("${statics}/Fonts/Rolex/v7/SangBleuKingdom/SangBleuKingdom-Light-WebXL.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  /* SangBleuKingdom Light Italic */
  @font-face {
    font-family: "SangBleuKingdom";
    src: url("${statics}/Fonts/Rolex/v7/SangBleuKingdom/SangBleuKingdom-LightItalic-WebXL.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  /* RolexFont small version (refers to number of characters in font) */
  /* Languages supported: Bosnian, Czech, Estonian, Indonesian, Irish, Lithuanian, Slovak, Slovenian, Swahili, Zulu */

  /* RolexFont S Light */
  @font-face {
    font-family: "RolexFont-S";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Light-WebS.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  /* RolexFont S Light Italic */
  @font-face {
    font-family: "RolexFont-S";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-LightItalic-WebS.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  /* RolexFont S Regular */
  @font-face {
    font-family: "RolexFont-S";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Regular-WebS.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }

  /* RolexFont S Regular Italic */
  @font-face {
    font-family: "RolexFont-S";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Italic-WebS.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  /* RolexFont S Bold */
  @font-face {
    font-family: "RolexFont-S";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Bold-WebS.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* RolexFont S Bold Italic */
  @font-face {
    font-family: "RolexFont-S";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-BoldItalic-WebS.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  /* RolexFont extra large version (refers to number of characters in font) */
  /* Languages supported: Afrikaans, Albanian, Arabic, Azerbaijani, Basque, Belarusian, Bosnian, Bulgarian, Catalan, Croatian, Czech, Danish, Dutch, English, Estonian, Faroese, Filipino, Finnish, French, Galician, German, Hungarian, Icelandic, Indonesian, Irish, Italian, Kazakh, Latvian, Lithuanian, Macedonian, Malay, Mongolian, Norwegian Bokmål, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Turkish, Urdu, Welsh, Zulu */

  /* RolexFont XL Light */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Light-WebXL.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  /* RolexFont XL Light Italic */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-LightItalic-WebXL.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  /* RolexFont XL Regular */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Regular-WebXL.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* RolexFont XL Regular Italic */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Italic-WebXL.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  /* RolexFont XL Bold */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-Bold-WebXL.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* RolexFont XL Bold Italic */
  @font-face {
    font-family: "RolexFont-XL";
    src: url("${statics}/Fonts/Rolex/v7/RolexFont/2.1/RolexFont-BoldItalic-WebXL.woff2") format("woff2");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  /* Thai */
  @font-face {
    font-family: "Avenir Next Thai Modern";
    src: url("${statics}/Fonts/Rolex/v7/AvenirNextThai/AvenirNextThaiModernRegular_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/AvenirNextThai/AvenirNextThaiModernRegular_normal_normal.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    size-adjust: 125%;
  }

  @font-face {
    font-family: "Helvetica Thai";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaThai/HelveticaThaiLight_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaThai/HelveticaThaiLight_normal_normal.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    ${
      "" /* ascent-override: 110%;
    descent-override: 35%; */
    }
  }

  @font-face {
    font-family: "Helvetica Thai";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaThai/HelveticaThaiRegular_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaThai/HelveticaThaiRegular_normal_normal.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: block;
    ${
      "" /* ascent-override: 110%;
    descent-override: 35%; */
    }
  }

  @font-face {
    font-family: "Helvetica Thai";
    src: url("${statics}/Fonts/Rolex/v7/HelveticaThai/HelveticaThaiBold_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/HelveticaThai/HelveticaThaiBold_normal_normal.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    ${
      "" /* ascent-override: 110%;
    descent-override: 35%; */
    }
  }

  @font-face {
    font-family: "Sukothai LT";
    src: url("${statics}/Fonts/Rolex/v7/Sukothai/SukothaiRegular_normal_normal.woff2") format("woff2"),
      url("${statics}/Fonts/Rolex/v7/Sukothai/SukothaiRegular_normal_normal.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
`
