import { useState, useRef, useLayoutEffect } from "react"

import styled from "@emotion/styled"
import { FocusScope } from "@react-aria/focus"
import { usePreventScroll } from "react-aria"

const Modal = styled.div`
  visibility: hidden;
//  opacity: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: min(100%, 100dvh);
  //  height: -webkit-fill-available;
  overflow: auto;
//  transform: translateZ(0);
  overscroll-behavior: none;

  &.headerized {
    z-index: 999;
  }

  &.wv_straight {
    opacity: 1;
  }

  &.wv_reveal {
//    transition: opacity 400ms cubic-bezier(0.61, 1, 0.88, 1) 0ms, visibility 0ms 0ms;
//    opacity: 1;
    visibility: visible;
  }

  &.wv_hide {
    > button {
      visiblity: hidden;
    }
  }

  &:focus-within {
    nav {
      visibility: visible;
    }
  }
`

export function CfgModal({ children, className }) {
  const rfmodal = useRef(null)
  const [lockit, setLockit] = useState(false)
  usePreventScroll({ isDisabled: lockit })

  useLayoutEffect(() => {
    const el = rfmodal.current
    el?.classList.add("wv_reveal")
    el?.querySelector("button")?.focus()

    return () => {
      setLockit(true)
    }
  }, [])

  return (
    <FocusScope contain restoreFocus>
      <Modal
        role='dialog'
        aria-modal='true'
        aria-labelledby='config-modal-heading'
        ref={rfmodal}
        className={["cfgv7Modal", className].filter(Boolean).join(" ")}
      >
        {children}
      </Modal>
    </FocusScope>
  )
}
