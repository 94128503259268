import { Suspense, useRef, useState } from "react"

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { createPortal } from "react-dom"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { useLocale } from "contexts/Locale"

import { storeCustomEvent, waTriggerEvent } from "components/analytics/DigitalDataLayer"
import Button from "components/button/Button"
import Link from "components/link/Link"

import { useConfigurator } from "./../configurator-v3/context"

import { AnimatePresence, motion } from "framer-motion"
import FocusTrap from "focus-trap-react"
import getMediaQuery from "css/breakpoints"

import Globe from "../globe/globe"
import ArticleSimple from "../articles/ArticleSimple"

const common = css`
  grid-row: 1;
  grid-column: -3;
  align-self: center;
  justify-self: end;

  height: 30px !important;
  font-size: 0.75rem !important;
`

const CTALink = styled(Link)`
  ${common};
  font-weight: 400 !important;
`

const GlobeModal = styled.div`
  background: #000;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
`

const CTAButton = styled(Button)`
  ${common};
  font-weight: 400 !important;
`

const Close = styled(Button)`
  background: #fff !important;
  z-index: 999;
  --spacing: calc(var(--outer-margin) - var(--height) / 2);
  ${getMediaQuery("m")} {
    --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--height) / 2);
  }
  ${getMediaQuery("l")} {
    --spacing: calc((var(--outer-margin) - var(--grid-gap)) / 2 - var(--height) / 2);
  }

  inset-block-start: var(--spacing);
  inset-inline-end: var(--spacing);

  position: fixed;
`

const Underlay = styled(motion.div)`
  inset: 0;
  position: fixed;
  z-index: calc(var(--z-top, 1000));
`

function LinkCTA({ link }) {
  const onCTAClick = () =>
    waTriggerEvent({
      eventName: "ctaSubNav",
      eventType: "Click",
      ctaInfo: {
        name: link.cta_name_tch || link.outlink_name,
        url: link.href || link.external,
      },
    })

  return (
    <CTALink {...link} onClick={onCTAClick} className='filled small opaque-green' icon='none'>
      {link.label}
    </CTALink>
  )
}

function ConfigureCTA({ label, param, cta_name_tch }) {
  const console = useConsole()
  console.verbose(cta_name_tch, "Config CTA")

  const dictionary = useDictionary()

  const { route, cta } = useConfigurator()

  function onClick(e) {
    const currentLink = process.browser ? window.location.href : null

    waTriggerEvent({
      eventName: "ctaSubNav",
      eventType: "Click",
      ctaInfo: {
        name: "configure",
        url: currentLink,
      },
    })

    if (!param) return
    cta.current = e?.target
    route.set(param)
  }

  return (
    <CTAButton className='filled opaque-green small' icon='none' onPress={onClick} aria_label={dictionary.ariaLabelOpenConfigurator()}>
      {label}
    </CTAButton>
  )
}

function GlobeCTA({ label, param, cta_name_tch, article_simple }) {
  const console = useConsole()
  const openButtonRef = useRef()
  const dictionary = useDictionary()
  const locale = useLocale()

  const lang = locale?.current?.codes?.www
  const bodyElement = process.browser && document.body

  const iframeUrl = `https://www.rolex.org/${lang}/environment/perpetual-planet/v7-globe`

  const [isOpen, setIsOpen] = useState(false)

  function onClose() {
    setIsOpen(false)
  }

  function onOpen() {
    const currentLink = process.browser && window ? window.location.href : ""

    waTriggerEvent({
      eventName: "ctaSubNav",
      eventType: "Click",
      ctaInfo: {
        name: "globe",
        url: currentLink,
      },
    })

    setIsOpen(true)
  }

  console.verbose(isOpen, "Globe CTA", article_simple)

  return (
    <>
      <CTAButton ref={openButtonRef} icon='none' aria-label={dictionary.ariaLabelOpenPopinModel()} className='filled opaque-green small' onPress={onOpen}>
        {label}
      </CTAButton>

      {process.browser &&
        createPortal(
          <AnimatePresence>
            {isOpen && (
              <FocusTrap focusTrapOptions={{ onDeactivate: onClose }}>
                <Underlay initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
                  <GlobeModal aria-modal='true' role='dialog' aria-labelledby='beautyshot-modal-heading' aria-describedby='beautyshot-modal-content'>
                    <Close aria-label={dictionary.popinClose()} icon='close' className='icon' onPress={onClose}>
                      <span>{dictionary.popinClose()}</span>
                    </Close>
                    <ArticleSimple
                      texts={article_simple[0].texts}
                      className={[
                        article_simple[0].className,
                        article_simple[0].theme.theme,
                        article_simple[0].box_spacing.margin_top,
                        article_simple[0].box_spacing.margin_bottom,
                        article_simple[0].box_spacing.padding_top,
                        article_simple[0].box_spacing.padding_bottom,
                      ].join(" ")}
                      background={article_simple[0].background}
                      horizontal_align={article_simple[0].horizontal_align}
                      vertical_align={article_simple[0].vertical_align}
                      title_gradient={article_simple[0].title_gradient}
                      chapo_gradient={article_simple[0].chapo_gradient}
                    />
                    <Globe iframe_url={iframeUrl} />
                  </GlobeModal>
                </Underlay>
              </FocusTrap>
            )}
          </AnimatePresence>,
          bodyElement
        )}
    </>
  )
}

export default function Cta({ cta }) {
  return cta[0]?.link ? (
    <LinkCTA link={cta[0].link} />
  ) : cta[0]?.config_launcher ? (
    <ConfigureCTA {...cta[0]?.config_launcher} />
  ) : cta[0]?.globe_launcher ? (
    <GlobeCTA {...cta[0]?.globe_launcher} />
  ) : null
}
