import { motion } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { headline50 } from "css/text"

const Para = styled(motion.p)`
  ${headline50}
`

const transition = {
  type: "tween",
  duration: 0.8,
  ease: "easeOut",
}

const animA = {
  show: { y: "0%", clipPath: "inset(0% 0 -0.2ex 0)", transition },
  hide: { y: "-100%", clipPath: "inset(100% 0 0 0)", transition },
}

export default function Chapo({ text, className }) {
  const console = useConsole()

  console.verbose("chapo(%o)", { text, className })
  return <Para className={className} dangerouslySetInnerHTML={{ __html: text }} variants={animA} />
}
