import { useEffect, useLayoutEffect, useState, useContext, useRef } from "react"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import { VisuallyHidden } from "react-aria"
import { useDictionary } from "contexts/Dictionary"
import { a11yOutline } from "css/a11y"
import getMediaQuery from "css/breakpoints"
import { mvSubscribe, observeResize, setProperty } from "./../utils"

const PageWrapper = styled.div`
  position: relative;
  grid-row: 1/-1;
  grid-column: main;
  width: 100%;
  align-self: end;
  justify-self: center;
  position: relative;
  height: fit-content;
  margin-block-end: 1.75rem;
  display: grid;
  justify-content: center;
//  overflow: hidden;
  --cfgl-page-ratio: 1;

  ${getMediaQuery("m")} {
    margin-block-end: 2.5rem;
  }
`

const PageContainer = styled.ul`
  position: relative;
  display: flex;
  gap: 12px;
  border: none;
  padding: 0;
  margin: 0;
  transform: scaleX(var(--cfgl-page-ratio));

  @media (prefers-contrast: more) {
    background: #fff;
    padding: 20px 10px;
  }
`

const PageGroup = styled.li`
  display: flex;
  & input.focus-visible + label {
    ${a11yOutline}
  }
`

const PageRadio = styled.input`
  width: 0;
  height: 0;
  appearance: none;
`

const Label = styled(motion.label)`
  position: relative;
  width: 2.5rem;
  height: 0.25rem;
  border-radius: 5px;
  background: white;
  opacity: .4;
  border: none;
  padding: 0;
  pointer-events: all;
  text-align: center;
  font-size: clamp(0.875rem, 0.8125rem + 0.1563vw, 1rem); // 14px > 16px
  line-height: 1;

  & > span {
    position: absolute;
    top: 0;
    font-size: 0.8em;
    white-space: nowrap;
    padding-block: 0 .7rem;
    transform: translateX(-50%) translateY(-100%) scaleX(calc(1 / var(--cfgl-page-ratio)));
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }

  @media (prefers-contrast: more) {
    background: black;
  }

  @media (hover: hover) and (pointer: fine) {
    &.unchecked:hover {
      cursor: pointer;
    }
    &:hover {
      & > span {
        opacity: 1;
      }
    }
  }

  &.unchecked::after {
    content: "";
    position: absolute;
    top: -12px;
    bottom: -12px;
    left: -4px;
    right: -4px;
  }
`

function Page(props) {
  const { ctx, index, label } = props
  const { focus, count } = useContext(ctx)
  const dictionary = useDictionary()
  const [checked, setChecked] = useState(focus.get() === index)
  const rfradio = useRef(null)

  function onFocus(v) {
    if (v < 0) return
    setChecked(index === v)
  }
  useLayoutEffect(() => mvSubscribe(focus, onFocus), [])

  useLayoutEffect(() => {
    if (checked)
      rfradio.current.setAttribute("checked", "")
    else
      rfradio.current.removeAttribute("checked")
  }, [checked])

  const variants = {
    checked: { width: "6.25rem", opacity: 1 },
    unchecked: { width: "2.5rem", opacity: .4 },
  }

  return (
    <PageGroup aria-current={checked}>
      <PageRadio
        //        onKeyDown={onKeyDown}
        ref={rfradio}
        type='radio'
        name='cfgf-page-control'
        id={`cfgf-page-control-${index}`}
        value={index}
        checked={checked}
      />
      <Label
        htmlFor={`cfgf-page-control-${index}`}
        variants={variants}
        initial={checked ? "checked" : "unchecked"}
        animate={checked ? "checked" : "unchecked"}
        whileHover={{ opacity: 1 }}
        className={checked ? "checked" : "unchecked"}
        transition={{ type: "tween", ease: "easeOut", duration: 0.2 }}
      >
        <span>{label}</span>
      </Label>
    </PageGroup>
  )
}

export default function Pagination(props) {
  const { ctx, items } = props
  const { rmfocus, focus, count, frozen } = useContext(ctx)
  const rfmain = useRef(null)
  const rffieldset = useRef(null)

  function onResize(isize) {
    const fsw = rffieldset.current.offsetWidth
    const ratio = Math.min(1, isize / fsw)
    setProperty(rfmain.current, "--cfgf-page-ratio", ratio)
  }
  useLayoutEffect(() => observeResize(rfmain.current, onResize), [])

  function onChange(e) {
    const index = Number(e.target.value)
    const active = focus.get()
    if (active === index) return
    frozen.set(true)
    rmfocus.set(focus.get() - active + index)
  }

  return (
    <PageWrapper ref={rfmain} className="dark-theme">
      <PageContainer onChange={onChange} ref={rffieldset}>
        {Array.from({ length: count }).map((v, i) => (
          <Page key={`cfgf-page-${i}`} index={i} ctx={ctx} label={items?.[i]?.title} />
        ))}
      </PageContainer>
    </PageWrapper>
  )
}
