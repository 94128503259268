import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { headline50 } from "css/text"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import Image from "components/media/ImageCLD"
import Link from "components/link/Link"

const Root = styled.aside`
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;

  > * {
    grid-area: 1 / -1;

    // keep for click on parent
    pointer-events: none;

    a {
      pointer-events: auto;
    }
  }

  figure {
    height: 100%;
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

const Text = styled.div`
  ${fullGrid}
  text-align: center;

  > div {
    grid-column: main;
  }
  svg {
    height: 12px !important;
    width: 12px !important;
    ${getMediaQuery("s")} {
      height: 14px !important;
      width: 14px !important;
    }
  }

  h3,
  p {
    ${headline50}
    margin-block-end: 0.625rem;
  }

  html.prefers-contrast & {
    color: black !important;
    z-index: 1;
    a,
    button > span,
    button > em {
      color: black !important;
    }

    ::after {
      z-index: 0;
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 50%;
      z-index: -1;
    }
  }
`

export default function PushOrg(props) {
  const console = useConsole()

  console.verbose("•••••••• PushOrg(%o)", props)
  const {
    heading: { title, subtitle },
    link,
    poster,
  } = props

  const imageProps = poster?.[0]?.image_cld ?? poster?.[1]?.image_cld

  return (
    <Root className='dark-theme' onClick={e => e.target.querySelector("a")?.click()}>
      <figure>
        <Image {...imageProps} sizes='100vw' />
      </figure>
      <Text>
        <div>
          {title && <p>{title}</p>}
          {subtitle && (
            <Link {...link} style='text' outlinkPosition='footer'>
              {subtitle}
            </Link>
          )}
        </div>
      </Text>
    </Root>
  )
}
