import { useRef } from "react"
import { useScroll, motion, useTransform } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { Icon } from "components/icon/Icon"

const Container = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: end;
`

const ArrowInvit = styled(motion.div)`
  justify-self: center;
  color: white;
  margin-block-end: 20vh;

  position: sticky;
  bottom: 5vh;

  @keyframes slidein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    from {
      transform: translateY(-100%);
    }

    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }

    to {
      transform: translateY(100%);
    }
  }

  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: slidein;

  & svg {
    transform: rotate(180deg);
  }
`

export default function ScrollInvit() {
  const console = useConsole()

  const ref = useRef()

  const { scrollYProgress } = useScroll({ target: ref, offset: ["start start", "end start"] })
  const opacity = useTransform(scrollYProgress, [0.05, 0.1], [1, 0])
  const animationPlayState = useTransform(opacity, o => (o === 0 ? "paused" : "running"))

  return (
    <Container ref={ref} style={{ opacity }}>
      <ArrowInvit style={{ animationPlayState }}>
        <Icon type='arrowUp' />
      </ArrowInvit>
    </Container>
  )
}
