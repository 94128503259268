import { createContext, useContext } from "react"
import { useMotionValue, useTransform, useReducedMotion } from "framer-motion"

import { useConsole } from "contexts/Console"
import useConstant from "hooks/useConstant"

import { useStory } from "../context"

import { getStoryIndex } from "./utils"

export const Context = createContext()

export const PlayerProvider = ({ stories, fromKeyDown, children }) => {
  const console = useConsole()

  const { storyOpener } = useStory()

  const shouldReduceMotion = useReducedMotion()

  const slugMap = useConstant(() => stories.reduce((acc, { story_id, cards }) => acc.concat([cards.map((c, i) => `${story_id}-${i}`)]), []))
  const slugList = useConstant(() => stories.reduce((acc, { story_id, cards }) => acc.concat(cards.map((c, i) => `${story_id}-${i}`)), []))

  const focusable = useConstant(() => slugList.reduce((acc, item) => acc.set(item, null), new Map()))

  const dragProg = useMotionValue(0)
  const isHolding = useMotionValue(false)
  const isAnimDisabled = useMotionValue(fromKeyDown === true || shouldReduceMotion)
  const slug = useMotionValue(storyOpener.get().split(":")[1] || "")
  const storyIndex = useTransform(slug, s => getStoryIndex(slugMap, s))
  const currentStoryIndex = useMotionValue(storyIndex.get())

  const isTouchDevice = window.matchMedia("(hover: none) and (pointer: coarse)").matches

  const dir = 1

  return (
    <Context.Provider
      value={{
        dragProg,
        isHolding,
        storyIndex,
        currentStoryIndex,
        slug,
        slugMap,
        slugList,
        dir,
        isTouchDevice,
        isAnimDisabled,
        focusable,
        shouldReduceMotion,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const usePlayer = () => useContext(Context)
