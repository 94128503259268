import { useEffect, useLayoutEffect, useRef, useState, createContext } from "react"
import { useFrameAnimation } from "./FrameAnimation"
import { CanvasAnimationProvider, useCanvasAnimation } from "./CanvasAnimation"
import { mvSubscribe, rafDelay } from "./utils"
import { useMotionValue, transform } from "framer-motion"
import { useEnv } from "contexts/Env"
import { SWAP_IN_DURATION } from "./constants"
import easing from "./easing"

function trueIndex(v) {
    return v.toString().padStart(4, "0")
}

function loadBlob(aborts) {
    return function (path) {
        let controller = new AbortController()
        aborts.push(controller)
        return (
            fetch(new Request(path), {
                signal: controller.signal
            })
                .then(r => r.status === 200 && r.blob())
                .then(URL.createObjectURL)
                .catch(e => null)
        )
    }
}

const CCR = 25
const EASE = "easeOutQuad"
const DURATION = 800

function CfgCanvasAnimationContents(props) {
    //console.log("CfgCanvasAnimationContents", props)
    const { Ctx, children, actx, uid, picture, frameready, canvasready, path } = props
    const { mobile, canvasview, rfcanvasview, newpanel } = useFrameAnimation(Ctx)
    const { canvasctx, frames, clearFrames } = useCanvasAnimation(actx)
    const rfcanvas = useRef(null)
    const rfpreload = useRef(new Image())
    const lastdrawn = useRef(null)
    const rfaborts = useRef([])
    const rfloop = useRef([])
    const animready = useMotionValue(false)
    const env = useEnv()
    const { cloudinary: { secureDistribution } } = env
    let play = useRef(0).current
    let stop = useRef(0).current
    let start = useRef(0).current
    let wait = useRef(0).current
    let ready = useRef(false).current
    let size = useRef(null).current
    let total = useRef(0).current
    let ease = useRef(null).current
    const rfpath = useRef(null)

    async function preload() {
        await kill()
        rfloop.current = Array.from({ length: picture.range }).map((v, i) => ({
            index: i,
            path: `${rfpath.current[0]}${trueIndex(i)}`
        }))
        total = rfloop.current.length - 1
        ease = transform([0, total], [0, total], { ease: easing[EASE] })

        await load()
        paintindex(0)

        for (let i = 0; i < CCR; i++) {
            load()
        }

        async function load(i = -1) {
            if (!rfloop.current.length) return
            const [{ index, path }] = rfloop.current.splice(i < 0 ? 0 : i, 1)
            const iskeyframe = index === 0 || index === total
            const downsize = mobile.get() ? 480 : 960
            let w = iskeyframe ? rfpath.current[1] : downsize
            let h = iskeyframe ? rfpath.current[2] : downsize * rfpath.current[2] / rfpath.current[1]
            const bloburl = await loadBlob(rfaborts.current)(path.replace("@w_", `w_${w}`))
            frames.current.set(index, {
                size: {
                    w: w,
                    h: h,
                },
                src: bloburl
            })
            //console.log("load", index, path)
            if (frames.current.size >= picture.range) {
                play = 0
                stop = picture.range
                //                start = Date.now()
                ready = true
                canvasready.set(true)
                return render()
            }
            load()
        }
    }

    //    useLayoutEffect(() => mvSubscribe(preload, onPreload, false), [])

    function onCanvasview(v) {
        const state = v === uid
        if (!state) {
            if (!ready) kill()
            return
        }
        //        readyPanels.set(false)
        const fixed = newpanel.get()
        canvasready.set(fixed)
        frameready.set(fixed)
        //        console.log("onCanvasview", canvasready.get(), frameready.get())
        if (fixed) return
        start = Date.now()
        play = 0
        wait = 0//SWAP_IN_DURATION
        if (!ready) return preload()
        canvasready.set(true)
        stop = picture.range
        window.requestAnimationFrame(() => paintindex(0))
    }
    useLayoutEffect(() => mvSubscribe(canvasview, onCanvasview, false), [])
    /*
        useLayoutEffect(() => {
            canvasready.set(false)
            if (canvasview.get() === uid) preload(picture.range - 1)
        }, [])
    */
    function sleep(d) {
        return new Promise(resolve => {
            setTimeout(resolve, d)
        })
    }

    function draw(e) {
        const { w, h } = size
        rfcanvas.current.width = w
        rfcanvas.current.height = h
        canvasctx.current.drawImage(e.target, 0, 0, w, h)
        window.requestAnimationFrame(render)
    }

    function paintindex(v) {
        const { src, size: fsize } = frames.current?.get(Math.round(ease(v))) ?? {}
        if (!src || lastdrawn.current === src) return render()
        if (!fsize) return
        size = fsize
        rfpreload.current.src = lastdrawn.current = src
    }

    function onComplete() {
        //        console.log("onCanvasview", canvasready.get(), frameready.get())
    }

    async function render() {
        frameready.set(true)
        if (play >= stop) return onComplete()
        const spent = Date.now() - start
        const delay = (wait > 0 ? wait : (DURATION / stop)) - spent
        await sleep(delay)
        wait = 0
        start = Date.now()
        paintindex(play++)
    }

    useLayoutEffect(() => {
        canvasctx.current = rfcanvas.current.getContext("2d", { alpha: true })
        rfpreload.current.addEventListener("load", draw, false)
        //    preload.addEventListener("error", draw, false)

        return () => {
            rfpreload.current.removeEventListener("load", draw, false)
            //      preload.removeEventListener("error", draw, false)
        }
    }, [])

    async function kill() {
        await clearFrames()
        return new Promise(resolve => {
            //    console.log("Func", "CfgPreload", "kill")
            rfaborts.current.forEach(controller => controller.abort())
            rfloop.current = []
            resolve()
        })
    }
    useEffect(() => kill, [])

    function onPath(v) {
        ready = false
        if (!v) {
            //            ready = false
            rfpath.current = null
            return
        }
        //console.log("pathpath", v)
        rfpath.current = v.split(";")
        rfcanvas.current.width = rfpath.current[1]
        rfcanvas.current.height = rfpath.current[2]
        onCanvasview(canvasview.get())
    }
    useLayoutEffect(() => mvSubscribe(path, onPath), [])

    return (
        <>
            <canvas ref={rfcanvas} width={0} height={0} />
            {children}
        </>
    )
}

export function CfgCanvasAnimation(props) {
    const actx = createContext()

    return (
        <CanvasAnimationProvider Ctx={actx}>
            <CfgCanvasAnimationContents actx={actx} {...props} />
        </CanvasAnimationProvider>
    )
}
