import { useEffect, useState } from "react"
import styled from "@emotion/styled"

import generateComponents, { parsePersoModularBlock } from "utils/generateComponents"

import { useConsole } from "contexts/Console"
import { useUser } from "contexts/User"

export default function Perso({ components, tags, ...props }) {
  const console = useConsole()

  const [comp, setComp] = useState(null)

  const { countryCode } = useUser()

  const queryString = process.browser ? window.location.search : ""

  const urlParams = new URLSearchParams(queryString)

  useEffect(() => {
    const comps = parsePersoModularBlock(components, { console })
    if (tags?.length) {
      if (tags[0] === "time") {
        const min = new Date().getMinutes()
        const numNat = min % 2 === 0 ? "even" : "odd"
        setComp(comps.filter(o => o.tags[0] === numNat))
      } else if (tags.includes("sport") && urlParams.has("sport")) {
        if (tags.includes("geo")) {
          const c = comps.filter(o => o.tags.includes(countryCode.toLowerCase()) && o.tags.includes(urlParams.get("sport")))
          setComp(c[0])
        } else {
          const c = comps.filter(o => o.tags.includes(urlParams.get("sport")))
          setComp(c[0])
        }
      } else {
        const c = comps.filter(o => o.tags.includes("default"))
        setComp(c[0])
      }
    } else {
      setComp(comps[0])
    }
  }, [])

  return <>{comp && generateComponents(comp, { console })}</>
}
