import { useConsole } from "contexts/Console"
import styled from "@emotion/styled"

import { quote70, quote100, body50, removeMarksCN } from "css/text"

const FigureQuote = styled.figure`
  html.prefers-contrast & {
    color: black !important;
    position: relative;

    ::after {
      background: white;
      content: "";
      height: calc(100% + 30px);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 20px);
      z-index: -1;
    }
  }
`
const Blockquote = styled.blockquote`
  margin: 0;
  margin-block-end: 20px;
`
const Paragraph = styled.p`
  ${quote70}

  .short-quote & {
    ${quote100}
  }
`
const Figcaption = styled.figcaption`
  ${body50}
`

export default function QuoteBlock({ quote, caption, short_quote, remove_marks }) {
  const console = useConsole()

  return (
    <FigureQuote className={`${remove_marks ? `${removeMarksCN} ` : ""}${!!short_quote ? "short-quote" : ""}`}>
      <Blockquote>
        <Paragraph dangerouslySetInnerHTML={{ __html: quote }} />
      </Blockquote>
      <Figcaption>{caption}</Figcaption>
    </FigureQuote>
  )
}
