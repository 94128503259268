import { createContext, useContext, useState, useEffect } from "react"
import { useNavigation } from "contexts/Navigation"
import useSSR from "hooks/useSSR"

export const SEARCH_RESULTS_PAGE_SLUG = "search"

export const Context = createContext()

export const SearchProvider = ({ children }) => {
  const navigation = useNavigation()
  const ssr = useSSR()

  const isSearch = () => (navigation?.slug.includes(SEARCH_RESULTS_PAGE_SLUG) ? true : false)
  const [isLoading, setIsloading] = useState(ssr)
  const [activeFilter, setActiveFilter] = useState("all")
  const [queryValue, setQueryValue] = useState("")
  const [results, setResults] = useState([])
  const [groupedResults, setGroupedResults] = useState()
  const [initialGroupedResults, setInitialGroupedResults] = useState(null)
  const [lastSearchValue, setLastSearchValue] = useState("")
  const [valueWasCleared, setValueWasCleared] = useState(false)
  const [isOnSearchResultsPage, setIsOnSearchResultsPage] = useState(isSearch())

  useEffect(() => {
    setIsOnSearchResultsPage(isSearch())
  }, [navigation.slug, isSearch])

  return (
    <Context.Provider
      value={{
        isLoading,
        setIsloading,
        activeFilter,
        setActiveFilter,
        queryValue,
        setQueryValue,
        results,
        setResults,
        groupedResults,
        setGroupedResults,
        initialGroupedResults,
        setInitialGroupedResults,
        lastSearchValue,
        setLastSearchValue,
        valueWasCleared,
        setValueWasCleared,
        isOnSearchResultsPage,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useSearch = () => useContext(Context)
