import styled from "@emotion/styled"
import hash from "@emotion/hash"

import { useConsole } from "contexts/Console"

import { Heading as HeadingCN } from "css/classnames"

import { headline70, surtitle70 } from "css/text"

export const KickerCN = `css-${hash("kicker")}`
export const SubtitleCN = `css-${hash("subtitle")}`

const Root = styled.hgroup`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  height: fit-content;

  & > h2 {
    grid-row-start: 2;
  }

  & > p {
    ${surtitle70}
    grid-row-start: 1;
    margin-block-end: 10px;
  }
`
const Title = styled.h2`
  ${headline70}

  em {
    color: rgb(var(--green));
    font-style: normal;
  }
`

export default function Heading({ tag, title, subtitle, kicker, id }) {
  const console = useConsole()

  //*******
  // TO DELETE
  // subtitle = null
  // kicker = null
  //*******

  return title && (kicker || subtitle) ? (
    <Root className={HeadingCN}>
      {kicker && <p className={KickerCN}>{kicker}</p>}
      <Title dangerouslySetInnerHTML={{ __html: title }} id={id} />
      {subtitle && <p className={KickerCN}>{subtitle}</p>}
    </Root>
  ) : title ? (
    <Title as={tag} className={HeadingCN} dangerouslySetInnerHTML={{ __html: title }} id={id} />
  ) : null
}
