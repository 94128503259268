import styled from "@emotion/styled"
import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"
import { cssVarHeight as cssVarHeaderHeight } from "contexts/Header"
import { motion } from "framer-motion"

export const Root = styled(motion.div)`
  position: relative;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  margin: 0;
  width: 86vw;

  ${getMediaQuery("m")} {
    min-width: 576px;
    width: 56vw;
  }

  ${getMediaQuery("l")} {
    width: 38vw;
  }
  ${getMediaQuery("m", { max: true })} {
    height: 100vh;
  }
  grid-column: doc;
  top: 0;
  padding-top: 20px;
  --fx: 0.4s;
  --delay: calc((1 - var(--t, 0)) * 600ms);
  --t: calc(70px + var(${cssVarHeaderHeight}));
`

export const SuggList = styled.div`
  padding: 0 0 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-inline-start: 35px;
  grid-column: main;
  flex-direction: column;

  ${getMediaQuery("m", { max: true })} {
    padding: 6px 0px 30px 0px;
    padding-inline-start: 0px;
  }
`

export const InnerList = styled(motion.ul)`
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  width: 100%;
`

export const FlexLi = styled(motion.li)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: 100%;
  pointer-events: ${({ noResult }) => (noResult ? "none" : "auto")};
`

export const ResultLine = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  text-align: unset;
  color: rgb(var(--pure-white));
  padding: 0 0 10px;
  margin: 0;
  width: 100%;

  ${getMediaQuery("m")} {
    &:hover {
      font-weight: bold;
    }
  }

  svg {
    width: 12px;
    height: 12px;
    margin-inline-end: 5px;
    opacity: .5;
  }

  span {
    font-weight: bold;
  }
`

export const TitleLine = styled(motion.span)`
  padding: 0 0 10px;
  font-weight: bold;
  color: rgb(var(--ocean-green) / 1);
  justify-self: start;
  grid-area: 1/-1;
`
export const Clear = styled.button`
  cursor: pointer;
  border: none;
  color: rgb(var(--pure-white));
  padding: 0 0 10px;
  font-weight: bold;
  background: none;
  grid-area: 1/-1;
  justify-self: end;
  display: flex;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(var(--green));
    margin-inline-start: 10px;
  }

  svg {
    width: 8px;
    height: 8px;
  }
`
export const ScreenReaderDiv = styled.div`
  position: absolute;
  left: -9999px;
`
