import { useEffect, useState } from "react"

export default function useReducedMotion() {
  const [reduced, setReduced] = useState(process.browser && document.documentElement.classList.contains("prefers-reduced-motion") || false)

  useEffect(() => {
    const html = document.documentElement
    const observer = new MutationObserver(([{ attributeName }]) => {
      if (attributeName === "class") {
        if (html.classList.contains("prefers-reduced-motion"))
          setReduced(true)
        else
          setReduced(false)
      }
    })

    observer.observe(html, {
      subtree: false,
      attributes: true
    })

    return () => observer.disconnect()
  })

  return reduced
}