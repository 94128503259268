import { useEffect } from "react"
import { animate } from "framer-motion"

import { useConsole } from "contexts/Console"

import useConstant from "hooks/useConstant"

import { useStory } from "../../context"
import { getNextSlug, getSlugIndex, getStoryIndex } from "../utils"
import { usePlayer } from "../context"
import { useProgress } from "../progressContext"

export default function useSlugChange({ slugId, indexOfStory, play, stop }) {
  const console = useConsole()

  const { storyOpener } = useStory()
  const { slug, slugList, slugMap, isAnimDisabled } = usePlayer()
  const { progressMap, showProgress } = useProgress()

  const animProg = useConstant(() => progressMap.get(slugId))

  function nextSlug() {
    const nextslug = getNextSlug(slugList, slugId)
    if (nextslug === slugId && showProgress) {
      storyOpener.set("")
    } else {
      slug.set(nextslug)
    }
  }

  function onSlugChange(s) {
    if (s === slugId) {
      if (!isAnimDisabled.get()) {
        animProg.set(0)
        play()
      } else {
        animProg.set(1)
      }
    } else {
      stop()
      if (indexOfStory === getStoryIndex(slugMap, s)) {
        if (getSlugIndex(slugList, slugId) < getSlugIndex(slugList, s)) {
          animate(animProg, 1, { duration: 0.1, ease: "linear" })
        } else {
          animate(animProg, 0, { duration: 0.1, ease: "linear" })
        }
      }
    }
  }
  useEffect(() => slug.onChange(onSlugChange))

  return { nextSlug }
}
