import { css } from "@emotion/react"

import { Chapo, Heading, Paragraph, GradientTitle, GradientChapo } from "css/classnames"

const commonGradient = css`
  background-clip: text;
  -webkit-text-fill-color: transparent;
  &:lang(th) {
    padding-bottom: 0.6ex;
  }
`

export function getGradient(colors) {
  return colors.length ? `${colors.map(({ color, opacity }) => `rgb(${color} / ${opacity})`)}` : ""
}

export const titleGradient = css`
  background: linear-gradient(var(--title-grad-angle, 0.45turn), var(--title-gradient));
  ${commonGradient}
`
export const chapoGradient = css`
  background: linear-gradient(var(--chapo-grad-angle, 0.45turn), var(--chapo-gradient));
  ${commonGradient}
`

export const modularGradient = css`
  html:not(.prefers-contrast) .${GradientTitle} & .${Heading}, html:not(.prefers-contrast) &.${GradientTitle} .${Heading} {
    & h1,
    h2,
    h3,
    h4,
    p {
      ${titleGradient}
    }
  }

  html:not(.prefers-contrast) .${GradientTitle} & h2.${Heading}, html:not(.prefers-contrast) &.${GradientTitle} h2.${Heading} {
    ${titleGradient}
  }

  html:not(.prefers-contrast) .${GradientChapo} & .${Chapo}, html:not(.prefers-contrast) &.${GradientChapo} .${Chapo} {
    ${chapoGradient}
  }
`

export const modularTexts = css`
  & > .${Heading}:not(:last-child) {
    margin-block-end: 20px;
  }

  & > .${Heading}:not(:last-child) + button {
    margin-block-start: 10px;
  }

  & > .${Chapo}:not(:last-child) {
    margin-block-end: 20px;
  }

  & > .${Paragraph}:not(:last-child) {
    margin-block-end: 30px;
  }

  & .${Heading} em,
  & .${Chapo} em {
    font-style: normal;
    color: rgb(var(--green));
  }
  .dark-theme & .${Heading} em,
  .dark-theme & .${Chapo} em {
    color: rgb(var(--light-green));
  }

  ${modularGradient}
`
