import { createContext, useContext } from "react"

const Context = createContext()
export const Provider = Context.Provider

export function useLocale() {
  return useContext(Context)
}

export function useDir() {
  return useContext(Context).dir
}

export function useSchema() {
  return useContext(Context).schema
}
