import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import { legend100, headline30 } from "css/text"
import { FocusScope } from "@react-aria/focus"

import ModalContainer, { priceModal } from "components/modal/ModalContainer"

const Heading = styled.h2`
  ${headline30}
  &:not(:first-of-type) {
    margin-block-start: 3.75rem;
  }
  margin-block-end: 1ex;
`

const Paragraph = styled.div`
  ${legend100}

  p:not(:last-of-type) {
    margin-block-end: 2ex;
  }
`

function PriceModalScope({ children, scope }) {
  return scope ? (
    <FocusScope contain restoreFocus autoFocus>
      {children}
    </FocusScope>
  ) : children
}

export default function PriceModal({ onClose, formattedPrice, priceTitle, legalNotice, onDemandTitle, onDemandNotice, scarcityTitle, scarcityText, scope }) {
  const console = useConsole()

  console.verbose("PriceModal(%o)")

  return (
    <PriceModalScope scope>
      <ModalContainer onClose={onClose} className={priceModal} modalHeading='modal-heading'>
        <Heading id='modal-heading'>{formattedPrice ? priceTitle : onDemandTitle}</Heading>
        <Paragraph dangerouslySetInnerHTML={{ __html: formattedPrice ? legalNotice : onDemandNotice }} />
        <Heading>{scarcityTitle}</Heading>
        <Paragraph dangerouslySetInnerHTML={{ __html: scarcityText }} />
      </ModalContainer>
    </PriceModalScope>
  )
}
