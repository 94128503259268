import { useConsole } from "contexts/Console"
import { Personalize as UFPersonalize } from "@uniformdev/context-react"
import generateComponents from "utils/generateComponents"

function Variation(props) {
  const console = useConsole()

  console.verbose("P13n:Variation(%o)", { props })
  return generateComponents(props, { console })
}

export default function P13N(props) {
  const console = useConsole()

  const variations = props.variations.reduce((variations, variation, i) => {
    for (const {
      criteria: { name: pz = null } = {},
      component: [component],
    } of Object.values(variation)) {
      !!component && variations.push(Object.assign(component, pz && { pz }, props?.index && { index: props.index }, { key: `${props.uid}-${i}` }))
    }

    return variations.sort((a, b) => +!!b?.pz - +!!a?.pz) /** move default variant at the end of the array */
  }, [])

  console.verbose("P13n(%o)", { props, variations })
  return <UFPersonalize variations={variations} component={Variation} />
}
