import { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useMotionValueEvent, motion, useAnimationControls } from "framer-motion"

import getMediaQuery from "css/breakpoints"
import { buttonIcon, translucentDark } from "css/buttons"

import { Icon } from "components/icon/Icon"
import { useRoller } from "./context"

const Arrow = styled(motion.button)`
  position: relative;
  grid-row: 1/-1;
  grid-column: 1/-1;
  align-self: center;
  ${buttonIcon}
  ${translucentDark}

  --spacing: calc(var(--outer-margin) - var(--grid-gap) / 2 - var(--height) / 2);
  ${getMediaQuery("m")} {
    --spacing: calc(var(--outer-margin) / 2 - var(--height) / 2);
  }

  pointer-events: auto;
  display: none;
  border: 0;
  border-radius: 50%;

  width: var(--height);
  height: var(--height);

  transition: color 0.3s;

  &.previous {
    justify-self: start;
    inset-inline-start: var(--spacing);
  }
  &.next {
    justify-self: end;
    inset-inline-end: var(--spacing);
  }
  ${getMediaQuery("m")} {
    display: flex;
  }

  &.previous svg {
    transform: scale(-1);
  }
  [dir="rtl"] &.previous svg {
    transform: scale(1);
  }
  [dir="rtl"] &.next svg {
    transform: scale(-1);
  }
`

const variants = {
  visible: { opacity: 1, visibility: "visible", transition: { duration: 0.3, type: "tween" } },
  hidden: { opacity: 0, transitionEnd: { visibility: "hidden" }, transition: { duration: 0.3, type: "tween" } },
}

export default function Arrows() {
  const { currentIndex, targetIndex, count } = useRoller()

  const rfprev = useRef(null)
  const rfnext = useRef(null)

  const animPrev = useAnimationControls()
  const animNext = useAnimationControls()

  function onPrevious(e) {
    targetIndex.set(currentIndex.get(), false)
    targetIndex.set(currentIndex.get() - 1)
  }
  function onNext(e) {
    targetIndex.set(currentIndex.get(), false)
    targetIndex.set(currentIndex.get() + 1)
  }

  function update(v) {
    if (v >= 1) {
      animPrev.start("visible")
    } else {
      animPrev.start("hidden")
    }
    if (v < count - 1) {
      animNext.start("visible")
    } else {
      animNext.start("hidden")
    }

    // rfprev.current.classList[v >= 1 ? "add" : "remove"]("cfgf-arrow-active")
    // rfnext.current.classList[v < count - 1 ? "add" : "remove"]("cfgf-arrow-active")
  }

  function onIndexChange(i) {
    update(i)
  }
  useMotionValueEvent(currentIndex, "change", onIndexChange)

  useEffect(() => {
    update(currentIndex.get())
  }, [])

  return (
    <>
      <Arrow ref={rfprev} animate={animPrev} variants={variants} className='previous' onClick={onPrevious} aria-hidden='true' tabIndex='-1'>
        <Icon type={"moveNext"} />
      </Arrow>
      <Arrow ref={rfnext} animate={animNext} variants={variants} className='next' onClick={onNext} aria-hidden='true' tabIndex='-1'>
        <Icon type={"moveNext"} />
      </Arrow>
    </>
  )
}
