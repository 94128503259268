import { useState } from "react"
import { createPortal } from "react-dom"
import { useMotionValueEvent, AnimatePresence } from "framer-motion"

import { useQuickViews } from "contexts/QuickViews"

import Launcher from "./Launcher"

export default function QuickViewsPlayer() {
  const { qvContent } = useQuickViews()

  const [active, setActive] = useState(null)

  function onQuickViewContent(c) {
    setActive(c)
  }
  useMotionValueEvent(qvContent, "change", onQuickViewContent)

  return process.browser ? createPortal(<AnimatePresence>{active && <Launcher data={active} />}</AnimatePresence>, document.body) : null
}
