import { useEffect, useRef, useState } from "react"
import { motion, useMotionValue, useMotionValueEvent } from "framer-motion"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"

import getMediaQuery from "css/breakpoints"
import { a11yOutline } from "css/a11y"

import { useValue } from "./context"

const Root = styled(motion.div)`
  position: sticky;
  top: 0;
  margin-block-start: calc(clamp(5rem, 3.4375rem + 3.9063vw, 6.875rem) * -1);

  pointer-events: none;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 5;
`

const PageContainer = styled.fieldset`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 12px;
  top: 0;
  align-items: center;
  height: min-content;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 10px;

  &:has(.focus-visible) {
    ${a11yOutline}
    outline-offset: 8px !important;
  }
  html.prefers-contrast & {
    background: #fff;
    padding: 20px 10px;
  }
`

const PageGroup = styled.div`
  display: flex;
`

const PageRadio = styled.input`
  width: 0;
  height: 0;
  appearance: none;
`

const Label = styled(motion.label)`
  position: relative;
  width: 3px;
  border-radius: 5px;
  background: white;
  border: none;
  padding: 0;
  pointer-events: all;
  background: var(--bgcolor, #d4d4d4);

  font-size: 0;

  transition: background 0.3s ease-out;

  @media (hover: hover) and (pointer: fine) {
    &.unchecked:hover {
      cursor: pointer;
    }
  }

  &.unchecked::after {
    content: "";
    position: absolute;
    display: block;
    height: 30px;
    width: 20px;
    background: transparent;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${getMediaQuery("m")} {
    &.unchecked::after {
      width: 30px;
      transform: translate(-50%, -50%);
    }
  }
  html.prefers-contrast & {
    background: black;
  }
`

const variants = {
  checked: { height: 50 },
  unchecked: { height: 20 },
}

function Page({ index, label }) {
  const console = useConsole()

  const ref = useRef()

  const { currentIndex } = useValue()

  const [checked, setChecked] = useState(currentIndex.get() === index)

  function onSlideChange(i) {
    setChecked(index === i)
  }
  useEffect(() => currentIndex.onChange(onSlideChange), [])

  return (
    <PageGroup>
      <PageRadio id={`page-${index}`} type='radio' name='features-control' value={index} checked={checked} aria-checked={checked} readOnly tabIndex={-1} />
      <Label
        ref={ref}
        htmlFor={`page-${index}`}
        variants={variants}
        initial={checked ? "checked" : "unchecked"}
        animate={checked ? "checked" : "unchecked"}
        className={checked ? "checked" : "unchecked"}
        transition={{ type: "tween", ease: "easeOut", duration: 0.2 }}
      >
        {`slide-${index + 1}`}
      </Label>
    </PageGroup>
  )
}

export default function Pagination({ pagesCount }) {
  const console = useConsole()

  const { targetSlide, currentIndex, isReturnVisitor } = useValue()
  const bgcolor = useMotionValue("#fff")

  const onChange = e => targetSlide.set(Number(e.target.value))

  function onCurrentChange(i) {
    if (i === -1) {
      targetSlide.set(-1)
    }

    if (i === 1 && isReturnVisitor.get()) {
      bgcolor.set("#212121")
    } else {
      bgcolor.set("#fff")
    }
  }
  useMotionValueEvent(currentIndex, "change", onCurrentChange)

  return (
    <Root style={{ "--bgcolor": bgcolor }}>
      <PageContainer onChange={onChange}>
        {Array.from(Array(pagesCount)).map((a, i) => (
          <Page key={`ashlar-section-${i}`} index={i} label={""} />
        ))}
      </PageContainer>
    </Root>
  )
}
