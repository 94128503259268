import { useMemo } from "react"
import styled from "@emotion/styled"

import { useConsole } from "contexts/Console"
import { useDictionary } from "contexts/Dictionary"
import { cssVarExpansion, cssVarHeight, cssVarTransitionDuration, cssVarTransitionDelay } from "contexts/Header"

import { fullGrid } from "css/grid"
import getMediaQuery from "css/breakpoints"

import { useSearchController } from "./hooks/useSearchController"
import { useSearch } from "./context"
import { Tab } from "./Tab"

const Root = styled.div`
  width: 100%;

  ${getMediaQuery("m")} {
    ${fullGrid}
  }
`

const Wrapper = styled.ul`
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
  max-width: 100vw;
  grid-column: col 4 / span 6;
  padding-inline: 4%;
  overflow-x: hidden;

  padding-block-start: 1.5rem;

  transform: translateY(calc(1.4rem * (var(${cssVarExpansion}) - 1)));
  transition: transform var(${cssVarTransitionDuration}) var(${cssVarTransitionDelay});

  ${getMediaQuery("m")} {
    padding-inline: initial;
    justify-content: center;
  }
`

export default function Tabs() {
  const console = useConsole()
  const dictionary = useDictionary()
  const { tabs } = useSearchController()
  const { groupedResults } = useSearch()

  const allTab = useMemo(() => tabs.find(tab => tab.label === "all"), [tabs])

  if (!groupedResults || Object.keys(groupedResults).length < 2) {
    return null
  }

  return (
    <Root>
      <Wrapper>
        {Object.keys(groupedResults).length >= 2 && <Tab label={allTab.label}>{dictionary?.searchAll()}</Tab>}

        {tabs.map(
          tab =>
            groupedResults.hasOwnProperty(tab.label) && (
              <Tab key={tab.label} label={tab.label}>
                {dictionary?.[tab.label]()}
              </Tab>
            )
        )}
      </Wrapper>
    </Root>
  )
}
