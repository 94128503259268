import { useLayoutEffect, useRef } from "react"

import { useStory } from "../context"

import { usePlayer } from "./context"
import { getStoryIndex, getNextSlug, getPrevSlug } from "./utils"

export default function KeyCtrl() {
  const { storyOpener } = useStory()

  const { slug, slugMap, slugList, isHolding, isAnimDisabled, shouldReduceMotion } = usePlayer()

  const canUseKayRef = useRef(true)
  const rafId = useRef()
  const startTime = useRef()

  const step = () => {
    const now = Date.now()
    if (now - startTime.current >= 500) {
      cancelAnimationFrame(step)
      canUseKayRef.current = true
    } else {
      rafId.current = requestAnimationFrame(step)
    }
  }

  const keyHandler = () => {
    canUseKayRef.current = false
    startTime.current = Date.now()
    rafId.current = requestAnimationFrame(step)
  }

  const onKeyDown = e => {
    const keyName = e.key
    const keyCode = e.keyCode

    if (!canUseKayRef.current) return
    keyHandler()

    isAnimDisabled.set(true)

    if (keyName !== "ArrowLeft" && keyName !== "ArrowRight" && keyName !== "Escape" && keyCode !== 32) {
      return
    }

    if (keyName === "Escape") {
      storyOpener.set("")
      return
    }

    if (keyCode === 32) {
      isHolding.set(!isHolding.get())
      return
    }

    if (keyName === "ArrowLeft") {
      const curStoryIndex = getStoryIndex(slugMap, slug.get())
      if (slugMap[curStoryIndex].indexOf(slug.get()) === 0) {
        const nextindex = Math.max(curStoryIndex - 1, 0)
        const nextSlug = slugMap[nextindex][0]
        slug.set(nextSlug)
      } else {
        slug.set(getPrevSlug(slugList, slug.get()))
      }
    }

    if (keyName === "ArrowRight") {
      slug.set(getNextSlug(slugList, slug.get()))
    }
  }

  const onMouseDown = () => {
    if (!shouldReduceMotion) isAnimDisabled.set(false)
  }

  useLayoutEffect(() => {
    document.addEventListener("keydown", onKeyDown, false)
    document.addEventListener("mousedown", onMouseDown, false)
    return () => {
      document.removeEventListener("keydown", onKeyDown, false)
      document.removeEventListener("mousedown", onMouseDown, false)
    }
  }, [])

  return null
}
