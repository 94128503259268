import { useLayoutEffect, useRef } from "react"
import styled from "@emotion/styled"
import sync, { cancelSync } from "framesync"

import { useConsole } from "contexts/Console"

const Root = styled.div`
  grid-column: span 12;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    padding-bottom: calc(675 / 2880 * 100vw);
  }

  @media (max-width: 767px) {
    padding-bottom: calc(900 / 780 * 100vw);
  }
`

const Container = styled.div`
  bottom: 0;
  position: absolute;
  width: 100vw;

  @media (min-width: 768px) {
    height: calc(675 / 2880 * 100vw);
  }

  @media (max-width: 767px) {
    height: calc(900 / 780 * 100vw);
  }

  aside,
  aside > a {
    height: 100%;
  }
`

export default function Underfooter({ children }) {
  const console = useConsole()

  const rroot = useRef()
  const rcontainer = useRef()

  useLayoutEffect(() => {
    let loop
    const node = rroot.current

    const start = () =>
      (loop = sync.update(
        () => {
          const { top, height } = rroot.current?.getBoundingClientRect?.()
          const { clientHeight: innerHeight } = document.documentElement
          const visibleHeight = top > innerHeight || top < -height ? 0 : Math.min(height, innerHeight, innerHeight - top, height + top)
          const coverageY = height === 0 ? 0 : visibleHeight / height

          if (coverageY <= 0 || coverageY >= 1) {
            rcontainer.current.style.setProperty("position", "absolute")
          } else {
            rcontainer.current.style.setProperty("position", "fixed")
          }
        },
        true,
        true
      ))

    const stop = () => loop && cancelSync.update(loop)

    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) start()
        else stop()
      },
      { rootMargin: "100px" }
    )

    observer.observe(node)
    return () => {
      observer.unobserve(node)
      stop()
    }
  })

  console.verbose("Underfooter", { children })
  return (
    <Root ref={rroot}>
      <Container ref={rcontainer}>{children}</Container>
    </Root>
  )
}
