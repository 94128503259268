/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext } from "react"
import hash from "@emotion/hash"

export const ctx__ONLY_FOR_MENU__ = {}
const Context = createContext(ctx__ONLY_FOR_MENU__)
export const Provider = ({ children }) => children

export function useMenu() {
  return useContext(Context)
}

export const cssVarFocusIn = `--${hash("menu:focus:in")}`
export const cssVarExpansion = `--${hash("menu:expansion")}`
export const sectionsRadioGroupName = hash("menu:section:radios")
