/**
 * don't copy this implementation
 * we use a single source of state without provider
 * to make this context "global"
 */

import { createContext, useContext } from "react"

import hash from "@emotion/hash"

export const ctx__ONLY_FOR_SEARCH__ = {}
const Context = createContext(ctx__ONLY_FOR_SEARCH__)
export const Provider = ({ children }) => children

export function useSearch() {
  return useContext(Context)
}

export const cssVarFocusIn = `--${hash("search:focus:in")}`
export const cssVarExpansion = `--${hash("search:expansion")}`
