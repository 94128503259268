import styled from "@emotion/styled"
import { motion, useTransform, transform, MotionValue } from "framer-motion"

import { buttonFilled } from "css/buttons"
import { contrastMode } from "css/a11y"

import { useConsole } from "contexts/Console"
import { useViewport } from "contexts/Viewport"

import VideoLauncher from "components/video-launcher/VideoLauncher"
import { pageHeaderCSS, kickerCN, subtitleCN } from "components/page-heading/style"
import useSSR from "hooks/useSSR"

const Header = styled(motion.header)`
  ${pageHeaderCSS}
  margin-top: 0;
  grid-template-rows: [kicker subtitle] min-content [mid-line] min-content [title video-cta];

  position: sticky;
  top: 40vh;

  height: 0;

  > button {
    ${buttonFilled}

    grid-row: video-cta;
    grid-column: main;
    justify-self: center;
    margin-block-start: 50px;
  }

  & h1,
  & p {
    position: relative;
    ${contrastMode}
  }
`

export default function PageTitleBlock({ title, subtitle, kicker, videoCTA, prog }) {
  const console = useConsole()
  const ssr = useSSR()

  const { height } = useViewport()
  const _height = ssr ? new MotionValue(0) : height /** first render as SERVER ( height=0 ) */

  const opacity = useTransform([prog, _height], ([p, h]) => transform(p, [0, h * 0.1], [1, 0]))
  const visibility = useTransform(opacity, o => (o === 0 ? "hidden" : "visible"))

  return (
    <Header className='dark-theme' style={{ opacity, visibility }}>
      {kicker && <p className={kickerCN}>{kicker}</p>}
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      {subtitle && <p className={subtitleCN}>{subtitle}</p>}
      {!!videoCTA && <VideoLauncher {...videoCTA} />}
    </Header>
  )
}
