import { motion, MotionConfig } from "framer-motion"
import styled from "@emotion/styled"

import { bold, surtitle50, body250, body100 } from "css/text"
import getMediaQuery from "css/breakpoints"

import { useConsole } from "contexts/Console"

import { waTriggerEvent } from "components/analytics/DigitalDataLayer"
import Link from "components/link/Link"

import { useStory } from "../../context"

const Root = styled(motion.div)`
  grid-column: col 1 / span 5;
  grid-row: txt-block;
  align-self: end;
  /* color: rgb(var(--pure-white)); */
  z-index: 1;

  ${getMediaQuery("m")} {
    grid-column: col 1 / span 8;

    & a:not(:only-child) {
      margin-block-start: 30px;
    }
  }

  & a {
    pointer-events: all;
  }

  & a:not(:only-child) {
    margin-block-start: 15px;
  }
  h3,
  p {
    html.prefers-contrast & {
      color: black !important;
      position: relative;

      ::after {
        background: white;
        content: "";
        height: calc(100% + 30px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 20px);
        z-index: -1;
      }
    }
  }
`

const Title = styled.h3`
  ${surtitle50}
`

const Paragraph = styled.p`
  ${body100}
  ${bold}
  
  ${getMediaQuery("m")} {
    ${body250}
  }
`

export default function TextBlock({ content_title, content_text, link, text_anim, contentItems }) {
  const console = useConsole()

  const { initiator } = useStory()

  function onClick() {
    waTriggerEvent({
      eventName: "storyCTAClicked",
      storyCTAName: link?.labelname,
      contentItems,
      component: initiator.get(),
    })
  }

  return (
    <MotionConfig reducedMotion='user'>
      <Root variants={TextBlock.variants[text_anim]}>
        {content_title ? <Title>{content_title}</Title> : null}
        {content_text ? <Paragraph>{content_text}</Paragraph> : null}
        {link?.label ? (
          <Link {...link} style='inline ' onClick={onClick}>
            {link.label}
          </Link>
        ) : null}
      </Root>
    </MotionConfig>
  )
}

TextBlock.typingDuration = 1.2

TextBlock.transition = {
  type: "tween",
  duration: 0.9,
}

TextBlock.staggerTransition = {
  type: "tween",
  ease: "linear",
  staggerChildren: TextBlock.typingDuration,
  delayChildren: 0.2,
}

TextBlock.variants = {
  translate: {
    visible: { opacity: 1, y: 0, visibility: "visible", transition: TextBlock.transition },
    hidden: { opacity: 0, y: 80, transitionEnd: { visibility: "hidden" }, transition: TextBlock.transition },
    exit: { opacity: 0, y: 80, transitionEnd: { visibility: "hidden" }, transition: { type: "tween", duration: 0.2 } },
  },
  typing: {
    visible: { opacity: 1, transition: TextBlock.staggerTransition },
    hidden: { opacity: 1, transition: TextBlock.staggerTransition },
    exit: { opacity: 0, y: 80, transitionEnd: { visibility: "hidden" }, transition: { type: "tween", duration: 0.2 } },
  },
}
